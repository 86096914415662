import React from "react";
import { NotFoundIcon } from "../components/Icon";

/**
 * エラー(404)画面
 * @returns
 */
const Error = () => {
  return (
    <>
      <div className="flex items-center pt-24">
        <div className="flex-auto text-center">
          <div className="flex items-center justify-center">
            <NotFoundIcon />
            <h2 className="ml-2 text-4xl font-semibold leading-6 py-4">
              404 Not Found
            </h2>
          </div>
          <div className="text-xl leading-6 py-4">ページが見つかりません。</div>
        </div>
      </div>
    </>
  );
};

export default Error;
