import React, { FC } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import { NewsData } from "../../common/type";
import LoadingSpinner from "../common/LoadingSpinner";

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedNewsData: NewsData;
  isModalLoading: boolean;
};

/**
 * お知らせ閲覧モーダル
 * @param props
 * @returns
 */
const NewsBrowseModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  selectedNewsData,
  isModalLoading,
}) => {
  return (
    <Modal isOpen={isModalOpen} width="min-w-[300px] max-w-[95%]">
      <div className="px-4">
        <h3 className="text-2xl font-semibold leading-6 border-b border-gray-300 text-text pb-2">
          {selectedNewsData.subject}
        </h3>
      </div>
      {isModalLoading ? (
        <div className="my-20">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="mt-5 text-textSub px-4">
            <span className="whitespace-pre-wrap">
              {selectedNewsData.content}
            </span>
          </div>
          <div className="mt-10 grid grid-flow-row-dense grid-cols-3">
            <Button
              onClick={() => setIsModalOpen(!isModalOpen)}
              disabled={!isModalOpen}
              buttonType={"normal"}
            >
              閉じる
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default NewsBrowseModal;
