import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const getInvoiceChangeLog = async (
  limit: number = 50,
  offset: number = 0,
  searchSelect?: string,
  keyword?: string
) => {
  let path = `/invoice_change_log?limit=${limit}&offset=${offset}`;
  if (searchSelect === "selectName") {
    path += `&agency_name=${keyword}`;
  } else if (searchSelect === "selectCode") {
    path += `&agency_code=${keyword}`;
  } else if (searchSelect === "selectYearMonth") {
    path += `&year_month=${keyword?.replaceAll("/", "")}`;
  } else if (searchSelect === "selectDocsNo") {
    path += `&docs_no=${keyword}`;
  } else if (searchSelect === "selectModificationDate") {
    if (keyword !== undefined) {
      if (keyword.split(":")[0])
        path += `&modification_date_start=${keyword.split(":")[0]}`;
      if (keyword.split(":")[1])
        path += `&modification_date_end=${keyword.split(":")[1]}`;
    }
  } else if (searchSelect === "selectModifier") {
    path += `&modifier=${keyword}`;
  }
  const params = {};
  let result: any;
  await API.get(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
