import { FC, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import Button from "../common/Button";
import Alert from "../common/Alert";
import ErrorListModal from "./ErrorListModal";
import { postProductDetailsValidation } from "../../api/postProductDetailsValidation";

type Props = {
  beforeCheckFiles: string[];
  selectedBeforeCheckFile: string;
  setSelectedBeforeCheckFile: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * インポート前ファイルチェック
 * @param props
 * @returns
 */
const FileCheck: FC<Props> = ({
  beforeCheckFiles,
  selectedBeforeCheckFile,
  setSelectedBeforeCheckFile,
}) => {
  const [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClick = async () => {
    try {
      const res = await postProductDetailsValidation(selectedBeforeCheckFile);
      if (res.statusCode === 200) {
        setMessage(
          "ファイルチェックを開始しました。\n完了するまでしばらく時間がかかります。\n完了したらメールでお知らせします。"
        );
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      setMessage("ファイルチェックが開始できませんでした。" + e);
    }
  };
  return (
    <>
      <h3 className="mt-5 text-left text-gray-500 text-base font-semibold">
        インポート前ファイルチェック
      </h3>
      <div className="flex flex-col gap-5 m-5">
        <FormControl>
          <Select
            value={selectedBeforeCheckFile}
            onChange={(e) =>
              setSelectedBeforeCheckFile(e.target.value as string)
            }
            displayEmpty
            className="text-left"
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              <em className="text-textSub">
                チェックするファイルを選択してください
              </em>
            </MenuItem>
            {beforeCheckFiles.map((beforeCheckFile: string, i: number) => (
              <MenuItem key={i} value={beforeCheckFile}>
                {beforeCheckFile}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="flex">
        <div className="ml-auto flex gap-10">
          <Button
            onClick={() => setIsModalOpen(true)}
            buttonType="normal"
            disabled={isModalOpen === true}
          >
            エラーリスト
          </Button>
          <Button
            disabled={selectedBeforeCheckFile === "" || isAlert === true}
            onClick={() => setIsAlert(true)}
          >
            チェック開始
          </Button>
        </div>
      </div>
      <Alert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        onClick={handleClick}
        title={"インポート前ファイルチェック"}
        submitText={"開始"}
        cancelText={"閉じる"}
        message={message}
        setMessage={setMessage}
      >
        選択されたファイルの形式が正しいかチェックします。
        <br />
        チェックには数分ほど時間がかかる可能性があります。
        <br />
        チェックを開始する場合は「開始」を押してください。
      </Alert>
      <ErrorListModal
        modalTitle="チェック"
        errorFilePath="product-Import-error/checkerror/"
        description="ファイルチェックエラーとなったシリアルNoのリストをダウンロードできます。<br>
        対象のシリアルNoのレコードを修正してから、改めてすべてのシリアルNoのレコードが記載されたファイルを作成し、<br>
        アップロード後にインポート前チェックをしてください。<br>
        （エラーファイルは１週間が経過すると自動で削除されます。）"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};
export default FileCheck;
