import { FC } from "react";
import { InvoiceChangeLogData } from "../../common/type";
import * as luxon from "luxon";

type Props = {
  invoiceChangeLogDataList: InvoiceChangeLogData[];
  modalOpen: (data: InvoiceChangeLogData) => void;
};

/**
 * 支払請求変更履歴テーブル
 * @param props
 * @returns
 */
const InvoiceChangeLogTable: FC<Props> = ({
  invoiceChangeLogDataList,
  modalOpen,
}) => {
  const detailModalOpen = (data: any) => {
    modalOpen(data);
  };
  return (
    <div className="flow-root">
      <div className="-my-2 -mx-8">
        <div className="inline-block min-w-full py-2 align-middle px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="text-gray-500 text-xs font-semibold">
                <th scope="col" className="w-[10%] px-3 py-3.5 text-left">
                  変更日
                </th>
                <th scope="col" className="w-[12%] px-3 py-3.5 text-left">
                  通知書No
                </th>
                <th scope="col" className="w-[10%] px-3 py-3.5 text-left">
                  対象年月
                </th>
                <th scope="col" className="w-[12%] px-3 py-3.5 text-left">
                  代理店コード
                </th>
                <th scope="col" className="w-[31%] px-3 py-3.5 text-left">
                  代理店名
                </th>
                <th scope="col" className="w-[25%] px-3 py-3.5 text-left">
                  変更者
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {invoiceChangeLogDataList.map((data, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => detailModalOpen(data)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {luxon.DateTime.fromSQL(data.created_at).toFormat(
                        "yyyy/MM/dd"
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {data.docs_no?.length > 10
                        ? `${data.docs_no.slice(0, 10)}...`
                        : data.docs_no}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {data.year_month.slice(0, 4)}/{data.year_month.slice(4)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {data.agency_code?.length > 8
                        ? `${data.agency_code.slice(0, 8)}...`
                        : data.agency_code}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1 text-sm text-left">
                      {data.agency_name?.length > 16
                        ? `${data.agency_name.slice(0, 16)}...`
                        : data.agency_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1 text-sm text-left">
                      {data.modifier?.length > 20
                        ? `${data.modifier.slice(0, 20)}...`
                        : data.modifier}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InvoiceChangeLogTable;
