import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

/**
 * お知らせ取得APIコール
 * @param data
 * @returns
 */
export const getNews = async (
  limit: number = 50,
  offset: number = 0,
  agencyCode?: string | null
) => {
  let path = "";
  if (typeof agencyCode === "string" || agencyCode === null) {
    path = `/news?limit=${limit}&offset=${offset}&agency_code=${agencyCode}`;
  } else {
    path = `/news?limit=${limit}&offset=${offset}`;
  }

  const params = {};
  let result: any;
  await API.get(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
