import { ReactNode } from "react";
import Error from "../containers/Error";
import Top from "../containers/Top";
import News from "../containers/News";
import Agencies from "../containers/Agencies";
import NotificationDocs from "../containers/NotificationDocs";
import NotificationDocsImport from "../containers/NotificationDocsImport";
import TermsDocs from "../containers/TermsDocs";
import ReleaseFiles from "../containers/ReleaseFiles";
import Payment from "../containers/Payment";
import InvoiceChangeLog from "../containers/InvoiceChangeLog";
import {
  AgencyIcon,
  NewsIcon,
  NotificationDocsIcon,
  NotificationDocsImportIcon,
  PaymentsIcon,
  ReleaseFilesIcon,
  TermsDocsIcon,
  TopIcon,
  InoviceChangeLogIcon,
} from "../components/Icon";

export type PageType = {
  NAME: string;
  ICON: ReactNode | null;
  PATH: string;
  CONTAINER: ReactNode;
  IS_DISPLAY: boolean;
  PERMISSIONS: number[];
};
export type UserPagesType = {
  TOP: PageType;
  AGENCIES: PageType;
  NOTIFICATION_DOCS: PageType;
  TERMS_DOCS: PageType;
  RELEASE_FILES: PageType;
  ERROR: PageType;
};
export type AdminPagesType = {
  TOP: PageType;
  NEWS: PageType;
  AGENCIES: PageType;
  NOTIFICATION_DOCS: PageType;
  NOTIFICATION_DOCS_IMPORT: PageType;
  TERMS_DOCS: PageType;
  RELEASE_FILES: PageType;
  COMPANY: PageType;
  INVOICE: PageType;
  ERROR: PageType;
};

/**
 * 代理店ユーザー用画面ページ定義
 * @returns
 */
export const UserPages: UserPagesType = {
  TOP: {
    NAME: "トップ",
    ICON: <TopIcon />,
    PATH: "",
    CONTAINER: <Top />,
    IS_DISPLAY: true,
    PERMISSIONS: [],
  },
  AGENCIES: {
    NAME: "代理店情報",
    ICON: <AgencyIcon />,
    PATH: "agencies",
    CONTAINER: <Agencies />,
    IS_DISPLAY: true,
    PERMISSIONS: [],
  },
  NOTIFICATION_DOCS: {
    NAME: "通知書管理",
    ICON: <NotificationDocsIcon />,
    PATH: "notificationDocs",
    CONTAINER: <NotificationDocs />,
    IS_DISPLAY: true,
    PERMISSIONS: [],
  },
  TERMS_DOCS: {
    NAME: "条件通知書管理",
    ICON: <TermsDocsIcon />,
    PATH: "termsDocs",
    CONTAINER: <TermsDocs />,
    IS_DISPLAY: true,
    PERMISSIONS: [],
  },
  RELEASE_FILES: {
    NAME: "公開ファイル管理",
    ICON: <ReleaseFilesIcon />,
    PATH: "releaseFiles",
    CONTAINER: <ReleaseFiles />,
    IS_DISPLAY: true,
    PERMISSIONS: [],
  },
  ERROR: {
    NAME: "エラー",
    ICON: null,
    PATH: "*",
    CONTAINER: <Error />,
    IS_DISPLAY: false,
    PERMISSIONS: [],
  },
};

/**
 * 管理者画面ページ定義
 * @returns
 */
export const AdminPages: AdminPagesType = {
  TOP: {
    NAME: "トップ",
    ICON: <TopIcon />,
    PATH: "",
    CONTAINER: <Top />,
    IS_DISPLAY: true,
    PERMISSIONS: [0, 1, 2],
  },
  NEWS: {
    NAME: "お知らせ",
    ICON: <NewsIcon />,
    PATH: "news",
    CONTAINER: <News />,
    IS_DISPLAY: true,
    PERMISSIONS: [0, 1, 2],
  },
  AGENCIES: {
    NAME: "代理店情報",
    ICON: <AgencyIcon />,
    PATH: "agencies",
    CONTAINER: <Agencies />,
    IS_DISPLAY: true,
    PERMISSIONS: [0, 1, 2],
  },
  NOTIFICATION_DOCS: {
    NAME: "通知書管理",
    ICON: <NotificationDocsIcon />,
    PATH: "notificationDocs",
    CONTAINER: <NotificationDocs />,
    IS_DISPLAY: true,
    PERMISSIONS: [0, 1, 2],
  },
  NOTIFICATION_DOCS_IMPORT: {
    NAME: "通知書情報インポート",
    ICON: <NotificationDocsImportIcon />,
    PATH: "notificationDocsImport",
    CONTAINER: <NotificationDocsImport />,
    IS_DISPLAY: true,
    PERMISSIONS: [0, 1],
  },
  TERMS_DOCS: {
    NAME: "条件通知書管理",
    ICON: <TermsDocsIcon />,
    PATH: "termsDocs",
    CONTAINER: <TermsDocs />,
    IS_DISPLAY: true,
    PERMISSIONS: [0, 1, 2],
  },
  RELEASE_FILES: {
    NAME: "公開ファイル管理",
    ICON: <ReleaseFilesIcon />,
    PATH: "releaseFiles",
    CONTAINER: <ReleaseFiles />,
    IS_DISPLAY: true,
    PERMISSIONS: [0, 1, 2],
  },
  COMPANY: {
    NAME: "会社情報",
    ICON: <PaymentsIcon />,
    PATH: "company",
    CONTAINER: <Payment />,
    IS_DISPLAY: true,
    PERMISSIONS: [0, 1, 2],
  },
  INVOICE: {
    NAME: "支払請求変更履歴",
    ICON: <InoviceChangeLogIcon />,
    PATH: "invoiceChangeLog",
    CONTAINER: <InvoiceChangeLog />,
    IS_DISPLAY: true,
    PERMISSIONS: [0, 1],
  },
  ERROR: {
    NAME: "エラー",
    ICON: null,
    PATH: "*",
    CONTAINER: <Error />,
    IS_DISPLAY: false,
    PERMISSIONS: [],
  },
};
