import { notificationDocsDetailNoData } from "../common/initialData";
import { useState, useEffect, useCallback, useContext } from "react";
import { PAGE_SIZE } from "../common/constants";
import { getNotificationDocs } from "../api/getNotificationDocs";
import { useDBUserContext } from "../components/contexts/DBUserContext";
import { getNotificationDocsNo } from "../api/getNotificationDocsNo";

import { IsAdminContext } from "../components/contexts/IsAdminContext";
import { putNotificationDocsNo } from "../api/putNotificationDocsNo";
import {
  NotificationDocsData,
  SearchItemsType,
  SearchSelect,
} from "../common/type";
import { putNotificationDocs } from "../api/putNotificationDocs";

/**
 * 通知書フック
 * @returns
 */
export const useNotificationDocs = () => {
  const { isAdmin } = useContext(IsAdminContext);

  const { dbUser } = useDBUserContext();

  const [nowOffset, setNowOffset] = useState(0);
  const [nextOffset, setNextOffset] = useState(0);
  const [previousOffset, setPreviousOffset] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [notificationDocsDataList, setNotificationDocsDataList] = useState([]);

  // 検索機能
  const [searchItems] = useState<SearchItemsType[]>([
    {
      value: "selectName",
      label: "代理店名",
      method: "word",
      type: "string",
    },
    {
      value: "selectCode",
      label: "代理店コード",
      method: "word",
      type: "string",
    },
    {
      value: "selectYearMonth",
      label: "対象年月",
      method: "word",
      type: "number",
    },
    {
      value: "selectFixedAmount",
      label: "確定額(合計)",
      method: "range",
      type: "number",
    },
  ]);
  const [selectedItem, setSelectedItem] = useState<SearchSelect>(
    searchItems[0].value
  );
  const [inputKeyword, setInputKeyword] = useState("");

  /* 通知書一覧取得 */
  const getNotificationDocsList = useCallback(
    async (offset: number = 0, searchSelect?: string, keyword?: string) => {
      try {
        setIsLoading(true);
        let payment_code: string | undefined;
        if (isAdmin) {
          payment_code = dbUser.payment_code;
        } else {
          searchSelect = "selectCode";
          keyword = dbUser.agency_code;
        }
        const notificationDocs = await getNotificationDocs(
          PAGE_SIZE,
          offset,
          payment_code,
          searchSelect,
          keyword
        );
        const notificationDocsBody = await JSON.parse(notificationDocs?.body);
        setNotificationDocsDataList(
          notificationDocsBody.data.map((data: any) => {
            return {
              notification_doc_no: data.notification_doc_no,
              year_month: data.year_month,
              confirmed_date: data.confirmed_date,
              agency_code: data.agency_code,
              agency_name: data.agency_name,
              payment_date: data.payment_date,
              amount_paid_tax_included: data.amount_paid_tax_included,
              tax_amount: data.tax_amount,
              issue_date: "",
              csv_issue_date: "",
              createdAt: data.created_at,
              updatedAt: "",
            };
          })
        );
        setNowOffset(offset);
        setPreviousOffset(offset - PAGE_SIZE);
        setNextOffset(notificationDocsBody.next_offset);
      } catch (e) {
        console.error(e);
        setNotificationDocsDataList([]);
        setPreviousOffset(-1);
        setNextOffset(-1);
      } finally {
        setIsLoading(false);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [dbUser]
  );

  useEffect(() => {
    if (dbUser.user_id) {
      getNotificationDocsList();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(true);

  /* 確定処理、確定取消、更新メッセージ */
  const [message, setMessage] = useState("");

  /* 通知書詳細モーダルオープン時、通知書情報取得 */
  const modalOpen = async (data: NotificationDocsData | null = null) => {
    if (data) {
      getNotificationDocsNoData(data.notification_doc_no, data.year_month);
    } else {
      setNotificationDocsNoData(notificationDocsDetailNoData);
      setIsModalLoading(false);
    }
    setIsModalOpen(!isModalOpen);
  };

  // 更新成功メッセージを閉じたとき、再リロード
  const [isSuccess, setIsSuccess] = useState(false);
  // 通知書詳細データ
  const [notificationDocsNoData, setNotificationDocsNoData] = useState(
    notificationDocsDetailNoData
  );

  const getNotificationDocsNoData = useCallback(
    async (notificationDocsNo: string, yearMonth: string) => {
      try {
        setNotificationDocsNoData(notificationDocsDetailNoData);
        setIsModalLoading(true);
        const notificationDocsNoData = await getNotificationDocsNo(
          notificationDocsNo,
          yearMonth
        );
        const notificationDocsNoBody = await JSON.parse(
          notificationDocsNoData?.body
        );
        setNotificationDocsNoData({
          notification_doc_no: notificationDocsNoBody.data.notification_doc_no,
          year_month: notificationDocsNoBody.data.year_month,
          confirmed_date: notificationDocsNoBody.data.confirmed_date,
          agency_code: notificationDocsNoBody.data.agency_code,
          agency_name: notificationDocsNoBody.data.agency_name,
          payment_date: notificationDocsNoBody.data.payment_date,
          amount_paid_tax_included:
            notificationDocsNoBody.data.amount_paid_tax_included,
          tax_amount: notificationDocsNoBody.data.tax_amount,
          products: notificationDocsNoBody.data.products,
          issue_date: notificationDocsNoBody.data.issue_date,
          csv_issue_date: notificationDocsNoBody.data.csv_issue_date,
          created_at: notificationDocsNoBody.data.created_at,
          updated_at: "",
        });
      } catch (e) {
        console.error(e);
      } finally {
        setIsSuccess(false);
        setIsModalLoading(false);
      }
    },
    []
  );

  // 通知書一覧リロード判定
  const [isReload, setIsReload] = useState(false);
  // 通知書の画面で確定処理、確定取消のための変数
  const [selectedData, setSelectedData] = useState<{
    notification_doc_no: string;
    year_month: string;
    confirmedDate: string | null;
  }>({
    notification_doc_no: "",
    year_month: "",
    confirmedDate: "",
  });

  // 通知書確定処理、確定取消
  const onUpdateNotificationDocs = async () => {
    try {
      const res = await putNotificationDocsNo(selectedData);
      if (res.statusCode === 200) {
        if (selectedData.confirmedDate != null) {
          setMessage("確定処理が完了しました。");
        } else {
          setMessage("確定取消が完了しました。");
        }
        setIsReload(true);
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  // 詳細モーダルで更新して画面を閉じた時、通知書一覧リロード
  useEffect(() => {
    if (isReload && !isModalOpen && isAdmin) {
      setIsReload(false);
      if (inputKeyword === "") {
        getNotificationDocsList(nowOffset);
      } else {
        //検索されている場合は絞り込みを保持
        getNotificationDocsList(nowOffset, selectedItem, inputKeyword);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  // 通知書確定処理、確定取消、ボタン押下後、リロード
  useEffect(() => {
    if (isSuccess) {
      getNotificationDocsNoData(
        notificationDocsNoData.notification_doc_no,
        notificationDocsNoData.year_month
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // 一括処理
  const [isAllDLModalOpen, setIsAllDLModalOpen] = useState(false);

  const [confirmedCancel, setConfirmedCancel] = useState(false);

  const lastMonth = (isStr: boolean) => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    if (isStr) {
      return `${year}年${month < 10 ? `0${month}` : month}月`;
    } else {
      return `${year}${month < 10 ? `0${month}` : month}`;
    }
  };

  // 通知書一括確定処理、確定取消
  const onUpdateAllConfirmed = async () => {
    try {
      const lastMonthData = lastMonth(false);
      let confirmedDate;
      if (confirmedCancel) {
        confirmedDate = null;
      } else {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        confirmedDate = `${year}/${month < 10 ? `0${month}` : month}/${day}`;
      }
      const res = await putNotificationDocs(
        lastMonthData,
        dbUser.payment_code,
        confirmedDate
      );
      if (res.statusCode === 200) {
        if (confirmedCancel) {
          setMessage("一括確定取消が完了しました。");
        } else {
          setMessage("一括確定処理が完了しました。");
        }
        if (inputKeyword === "") {
          getNotificationDocsList(nowOffset);
        } else {
          //検索されている場合は絞り込みを保持
          getNotificationDocsList(nowOffset, selectedItem, inputKeyword);
        }
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  return {
    notificationDocsDataList,
    nextOffset,
    previousOffset,
    isLoading,
    getNotificationDocsList,
    selectedItem,
    setSelectedItem,
    inputKeyword,
    setInputKeyword,
    searchItems,
    // 詳細モーダル
    modalOpen,
    isModalOpen,
    setIsModalOpen,
    isModalLoading,
    notificationDocsNoData,
    onUpdateNotificationDocs,
    setSelectedData,
    setIsSuccess,
    message,
    setMessage,
    // 一括モーダル
    isAllDLModalOpen,
    setIsAllDLModalOpen,
    onUpdateAllConfirmed,
    setConfirmedCancel,
    lastMonth,
  };
};
