import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const putUserResetPassword = async (
  userId: string,
  code: string,
  userName?: string
) => {
  const path = `/users/reset_password/${encodeURIComponent(userId)}`;
  let putData;
  if (userName) {
    putData = { payment_code: code };
  } else {
    putData = { agency_code: code };
  }

  const params = { body: putData };
  let result: any;
  await API.put(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
