import { FC, useEffect } from "react";

type Props = {
  id: string;
  type: string;
  autoComplete?: string;
  isRequired: boolean;
  defaultValue: string;
  inputValue: string;
  placeholder: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  maxLength?: number;
};

/**
 * 汎用インプット
 * @param props
 * @returns
 */
const Input: FC<Props> = ({
  id,
  type,
  autoComplete = "off",
  isRequired,
  defaultValue,
  inputValue,
  placeholder,
  setInputValue,
  maxLength,
}) => {
  useEffect(() => {
    setInputValue(defaultValue);
  }, [setInputValue, defaultValue]);

  return (
    <input
      id={id}
      name={autoComplete}
      type={type}
      autoComplete={autoComplete}
      required={isRequired}
      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 text-text placeholder:text-gray-400 focus:ring-2 focus:ring-inset leading-6 px-2"
      value={inputValue}
      placeholder={placeholder}
      onChange={(e) => setInputValue(e.target.value)}
      maxLength={maxLength}
    />
  );
};

export default Input;
