import { FC, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import Button from "../common/Button";
import Alert from "../common/Alert";
import { useNotificationDocsImport } from "../../hooks/useNotificationDocsImport";
import { postNotificationDocsGenerate } from "../../api/postNotificationDocsGenerate";
import { useDBUserContext } from "../contexts/DBUserContext";

type Props = {
  selectedYearMonth: string;
  setSelectedYearMonth: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * PDF / CSV生成
 * @param props
 * @returns
 */
const GenerateDocs: FC<Props> = ({
  selectedYearMonth,
  setSelectedYearMonth,
}) => {
  //APIリクエストパラメータ系
  const { dbUser } = useDBUserContext();
  const { yearMonthLabels, yearMonthValues } = useNotificationDocsImport();
  const [docType, setDocType] = useState<"pdf" | "csv">("csv");
  const [isdifferentialCreation, setIsdifferentialCreation] = useState(false);

  //アラート
  const [isAlert, setIsAlert] = useState(false);
  const [closeAlertMessage, setCloseAlertMessage] = useState("");

  const handleClick = async () => {
    try {
      const res = await postNotificationDocsGenerate(
        dbUser.payment_code,
        selectedYearMonth,
        docType,
        isdifferentialCreation
      );
      if (res.statusCode === 200) {
        setCloseAlertMessage(
          "通知書生成を開始しました。\n完了するまでしばらく時間がかかります。\n完了したらメールでお知らせします。"
        );
      } else {
        setCloseAlertMessage(res?.message);
      }
    } catch {
      setCloseAlertMessage("通知書生成が開始できませんでした。");
    }
  };
  return (
    <>
      <h3 className="mt-5 text-left text-gray-500 text-base font-semibold">
        通知書生成
      </h3>
      <div className="flex flex-col gap-5 m-5">
        <FormControl>
          <Select
            value={selectedYearMonth}
            onChange={(e) => setSelectedYearMonth(e.target.value as string)}
            displayEmpty
            className="text-left"
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              <em className="text-textSub">
                通知書の対象年月を選択してください
              </em>
            </MenuItem>
            {yearMonthLabels.map((yearMonth: string, i: number) => (
              <MenuItem key={i} value={yearMonthValues[i]}>
                {yearMonth}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="flex">
        <div className="ml-auto flex gap-10">
          <Button
            disabled={selectedYearMonth === "" || isAlert === true}
            onClick={() => {
              setIsAlert(true);
              setDocType("pdf");
              setIsdifferentialCreation(false);
            }}
          >
            PDF生成⓵
          </Button>
          <Button
            disabled={selectedYearMonth === "" || isAlert === true}
            onClick={() => {
              setIsAlert(true);
              setDocType("pdf");
              setIsdifferentialCreation(true);
            }}
          >
            PDF差分生成
          </Button>
          <Button
            disabled={selectedYearMonth === "" || isAlert === true}
            onClick={() => {
              setIsAlert(true);
              setDocType("csv");
              setIsdifferentialCreation(false);
            }}
          >
            CSV生成⓶
          </Button>
        </div>
      </div>
      <Alert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        onClick={handleClick}
        title={`通知書${docType.toUpperCase()}${
          isdifferentialCreation ? "差分" : ""
        }生成`}
        submitText={"開始"}
        cancelText={"閉じる"}
        message={closeAlertMessage}
        setMessage={setCloseAlertMessage}
      >
        {isdifferentialCreation
          ? "選択された対象年月のPDF生成でエラーになった通知書の差分を生成します。"
          : `選択された対象年月の${docType.toUpperCase()}を生成します。`}
        <br />
        生成には数分ほど時間がかかる可能性があります。
        <br />
        生成を開始する場合は「開始」を押してください。
      </Alert>
    </>
  );
};
export default GenerateDocs;
