import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const postProductDetailsValidation = async (fileName: string) => {
  const path = "/product_details/validation";
  const postData = {
    file_name: fileName,
  };
  const params = { body: postData };
  let result: any;
  await API.post(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
