import { FC, useEffect } from "react";

type Props = {
  row: number;
  isRequired: boolean;
  defaultValue: string;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  maxLength?: number;
};

/**
 * 汎用テキストエリア
 * @param props
 * @returns
 */
const Textarea: FC<Props> = ({
  row,
  isRequired,
  defaultValue,
  inputValue,
  setInputValue,
  placeholder,
  maxLength,
}) => {
  useEffect(() => {
    setInputValue(defaultValue);
  }, [setInputValue, defaultValue]);

  return (
    <textarea
      rows={row}
      name="comment"
      id="comment"
      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 text-text placeholder:text-gray-400 focus:ring-2 focus:ring-inset leading-6 px-2 mt-4 resize"
      required={isRequired}
      value={inputValue}
      placeholder={placeholder}
      onChange={(e) => setInputValue(e.target.value)}
      maxLength={maxLength}
    />
  );
};

export default Textarea;
