type Props = {
  size?: "small" | "medium" | "large";
  color?: "white" | "black";
};

/**
 * ローディングスピナー
 * @param props
 * @returns
 */
const LoadingSpinner = ({ size, color }: Props) => {
  return (
    <div className="flex justify-center">
      <div
        className={`animate-spin rounded-full border-t-transparent ${
          size === "small"
            ? "h-5 w-5 border-2"
            : size === "medium"
            ? "h-8 w-8 border-4"
            : size === "large"
            ? "h-12 w-12 border-4"
            : "h-10 w-10 border-4"
        }  ${color === "white" ? "border-gray-100" : "border-gray-500"}`}
      ></div>
    </div>
  );
};

export default LoadingSpinner;
