import { useCallback, useLayoutEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { FileWithPath, useDropzone } from "react-dropzone";
import { Storage } from "aws-amplify";
import { useDBUserContext } from "../components/contexts/DBUserContext";
import Button from "../components/common/Button";
import Alert from "../components/common/Alert";
import FileCheck from "../components/notificationDocsImport/FileCheck";
import FileImport from "../components/notificationDocsImport/FileImport";
import GenerateDocs from "../components/notificationDocsImport/GenerateDocs";
import { getProductDetailsStatus } from "../api/getProductDetailsStatus";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { NotificationDocsImportIcon, ReloadIcon } from "../components/Icon";
/**
 * 通知書情報インポート画面
 * @returns
 */
const NotificationDocsImport = () => {
  const { dbUser } = useDBUserContext();
  const [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // ファイルアップロード
  const [file, setFile] = useState<FileWithPath>();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  // インポート前チェック
  const [beforeCheckFiles, setBeforeCheckFiles] = useState<string[]>([]);
  const [selectedBeforeCheckFile, setSelectedBeforeCheckFile] = useState("");
  // インポート前チェック
  const [importFiles, setImportFiles] = useState<string[]>([]);
  const [selectedImportFile, setSelectedImportFile] = useState("");
  // PDF / CSV生成
  const [selectedYearMonth, setSelectedYearMonth] = useState("");
  const handleRetrieve = async () => {
    try {
      setIsLoading(true);
      const results = await Storage.list(`product-details/`);
      const files = results.results;
      if (files && files.length > 0) {
        // ファイルを日付の降順にソートする
        const sortedFiles = files.sort((a, b) => {
          const dateA = new Date(a.lastModified || "");
          const dateB = new Date(b.lastModified || "");
          if (dateA > dateB) {
            return -1; // dateAがdateBよりも新しい場合は降順にソート
          } else if (dateA < dateB) {
            return 1; // dateAがdateBよりも古い場合は昇順にソート
          } else {
            return 0; // 同じ日付の場合は順序を変更しない
          }
        });
        // 最新の5つのファイルを取得する
        const latestFiles = sortedFiles.slice(0, 5);

        // ファイル名だけ切り出し
        const fileNames = latestFiles.map((sortedFile) => {
          const filePath = sortedFile.key || "";
          const fileName = filePath.split("/").pop() || "";
          return decodeURI(fileName);
        });

        // ファイルのステータスを取得
        const productDetailsStatus = await getProductDetailsStatus(fileNames);
        const body = JSON.parse(productDetailsStatus.body);

        setBeforeCheckFiles(fileNames.map((fileName: string) => fileName));
        setImportFiles([]);
        if (body.hasOwnProperty("1")) {
          setImportFiles(body["1"].map((fileName: string) => fileName));
        }
      }

      setIsLoading(false);
    } catch {
      console.error("ファイルの取得に失敗しました。");
    }
  };
  useLayoutEffect(() => {
    handleRetrieve();
  }, []);
  const handleUpload = async () => {
    setIsUploading(true);
    const encodeFileName = encodeURI(file?.name || "unknown.csv");
    try {
      await Storage.put(`product-details/${encodeFileName}`, file, {
        contentType: "text/csv",
        metadata: {
          post_user_id: dbUser.user_id || "",
          file_name: encodeFileName,
        },
        progressCallback(progress) {
          setUploadProgress(
            Math.round((progress.loaded / progress.total) * 100)
          );
        },
      });
      setMessage("アップロードしました。");
      handleRetrieve();
      setSelectedBeforeCheckFile("");
      setSelectedImportFile("");
    } catch {
      setMessage("アップロードが失敗しました。");
    } finally {
      setUploadProgress(0);
      setIsAlert(true);
      setIsUploading(false);
    }
  };
  const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    maxFiles: 1,
  });
  const removeFile = (file: FileWithPath) => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    setFile(undefined);
  };

  const uploadedFile = acceptedFiles.map((file: FileWithPath) => (
    <div key={file.name} className="flex justify-center items-center">
      <p>
        {file.path} - {file.size} bytes
      </p>
      <div
        className="whitespace-nowrap px-3 py-4 text-sm text-left"
        onClick={() => removeFile(file)}
      >
        <XCircleIcon className="w-5 h-5 cursor-pointer" />
      </div>
    </div>
  ));
  return (
    <>
      <div className="flex items-center pt-24">
        <NotificationDocsImportIcon />
        <div className="flex-auto ml-2">
          <h2 className="text-2xl font-semibold leading-6 py-4">
            通知書情報インポート
          </h2>
        </div>
        <div className="ml-auto">
          <Button
            buttonType="normal"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => {
              handleRetrieve();
              acceptedFiles.map((file: FileWithPath) => removeFile(file));
              setSelectedBeforeCheckFile("");
              setSelectedImportFile("");
              setSelectedYearMonth("");
            }}
          >
            <ReloadIcon />
          </Button>
        </div>
      </div>
      <div className="p-3 shadow-md">
        <h3 className="mt-5 text-left text-gray-500 text-base font-semibold">
          ファイルアップロード
        </h3>
        <div className="flex flex-col gap-5 m-5">
          <div className="border rounded cursor-pointer">
            <div
              {...getRootProps()}
              className="flex flex-col justify-center items-center p-5 "
            >
              <input {...getInputProps()} />
              <p>
                ファイルをここにドラッグアンドドロップするか、
                クリックしてファイルを選択してください
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5 justify-center items-center">
          <div>{uploadedFile}</div>
        </div>
        <div className="flex">
          <div className="ml-auto">
            <Button
              disabled={file === undefined || isAlert === true}
              onClick={() => setIsAlert(true)}
            >
              アップロード
            </Button>
          </div>
        </div>
      </div>

      <Alert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        onClick={handleUpload}
        title={"取り込みファイルアップロード"}
        submitText={"はい"}
        cancelText={"いいえ"}
        message={message}
        setMessage={setMessage}
      >
        PAC3に取り込むファイルをアップロードします。
        <br />
        よろしいですか？
        {isUploading && (
          <div className="mt-4">
            <LinearProgress variant="determinate" value={uploadProgress} />
          </div>
        )}
      </Alert>

      {isLoading ? (
        <div className="mt-20">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="p-3 shadow-md mt-4">
            <FileCheck
              beforeCheckFiles={beforeCheckFiles}
              selectedBeforeCheckFile={selectedBeforeCheckFile}
              setSelectedBeforeCheckFile={setSelectedBeforeCheckFile}
            />
          </div>
          <div className="p-3 shadow-md mt-4">
            <FileImport
              importFiles={importFiles}
              selectedImportFile={selectedImportFile}
              setSelectedImportFile={setSelectedImportFile}
            />
          </div>
          <div className="p-3 shadow-md mt-4">
            <GenerateDocs
              selectedYearMonth={selectedYearMonth}
              setSelectedYearMonth={setSelectedYearMonth}
            />
          </div>
        </>
      )}

      <div className="my-10" />
    </>
  );
};

export default NotificationDocsImport;
