import React, { FC, useState, useLayoutEffect, useCallback } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import { Storage } from "aws-amplify";
import LoadingSpinner from "../common/LoadingSpinner";
import ErrorListTable from "./ErrorListTable";

type Props = {
  modalTitle: string;
  errorFilePath: string;
  description: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * エラーリストモーダル
 * @param props
 * @returns
 */
const ErrorListModal: FC<Props> = ({
  modalTitle,
  errorFilePath,
  description,
  isModalOpen,
  setIsModalOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorFileNames, setErrorFileNames] = useState([""]);
  const [downloadingFileName, setDownloadingFileName] = useState("");

  const texts = description.split("<br>").map((text, i) => {
    return (
      <React.Fragment key={i}>
        {text}
        <br />
      </React.Fragment>
    );
  });
  const handleRetrieve = useCallback(async () => {
    try {
      setIsLoading(true);
      const results = await Storage.list(errorFilePath);
      const files = results.results;

      // ファイルを日付の降順にソートする
      const sortedFiles = files.sort((a, b) => {
        const dateA = new Date(a.lastModified || "");
        const dateB = new Date(b.lastModified || "");
        if (dateA > dateB) {
          return -1; // dateAがdateBよりも新しい場合は降順にソート
        } else if (dateA < dateB) {
          return 1; // dateAがdateBよりも古い場合は昇順にソート
        } else {
          return 0; // 同じ日付の場合は順序を変更しない
        }
      });

      // 7日前の日時を取得
      const displayDeadline = new Date();
      displayDeadline.setDate(displayDeadline.getDate() - 7);
      // 最新の5つのファイルを取得する
      const latestFiles = sortedFiles
        .filter((file) => {
          const fileDate = new Date(file.lastModified || "");
          return fileDate >= displayDeadline;
        })
        .slice(0, 5);

      // ファイル名だけ切り出し
      const fileNames = latestFiles.map((sortedFile) => {
        const filePath = sortedFile.key || "";
        const fileName = filePath.split("/").pop() || "";
        return fileName;
      });

      setErrorFileNames(fileNames.map((fileName: string) => fileName));
      setIsLoading(false);
    } catch {
      console.log("ファイルの取得に失敗しました。");
    }
  }, [errorFilePath]);
  useLayoutEffect(() => {
    handleRetrieve();
  }, [handleRetrieve]);
  return (
    <Modal isOpen={isModalOpen}>
      <div className="px-4">
        <h3 className="text-2xl font-semibold leading-6 border-b border-gray-300 text-text pb-2">
          {modalTitle}エラーリスト
        </h3>
      </div>
      {isLoading ? (
        <>
          <div className="my-20">
            <LoadingSpinner />
          </div>
        </>
      ) : (
        <>
          <div className="mt-5  min-h-[60vh] px-4">
            <span className="text-textSub">{texts}</span>

            {errorFileNames[0] ? (
              <ErrorListTable
                errorFileNames={errorFileNames}
                errorFilePath={errorFilePath}
                downloadingFileName={downloadingFileName}
                setDownloadingFileName={setDownloadingFileName}
              />
            ) : (
              <div className="mt-20 text-center text-text font-bold">
                エラーになったファイルがありません
              </div>
            )}
          </div>
          <div className="mt-10 grid grid-flow-row-dense grid-cols-3 gap-60">
            <Button
              onClick={() => setIsModalOpen(!isModalOpen)}
              disabled={!isModalOpen || downloadingFileName !== ""}
              buttonType={"normal"}
            >
              閉じる
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ErrorListModal;
