import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

export const CognitoUserContext = createContext(
  {} as {
    cognitoUser: any | null;
    setCognitoUser: Dispatch<SetStateAction<any | null>>;
  }
);

export const useCognitoUserContext = () => {
  return useContext(CognitoUserContext);
};

type Props = {
  children: ReactNode;
};

/**
 * Cognitoのログインユーザー情報
 * @param props
 * @returns
 */
export const CognitoUserProvider: FC<Props> = ({ children }) => {
  const [cognitoUser, setCognitoUser] = useState(null);
  return (
    <CognitoUserContext.Provider value={{ cognitoUser, setCognitoUser }}>
      {children}
    </CognitoUserContext.Provider>
  );
};
