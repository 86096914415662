import { invoiceChangeLogDetailData } from "../common/initialData";
import { useState, useEffect, useCallback } from "react";
import { PAGE_SIZE } from "../common/constants";
import { useDBUserContext } from "../components/contexts/DBUserContext";
import { getInvoiceChangeLogId } from "../api/getInvoiceChangeLogId";
import {
  InvoiceChangeLogData,
  SearchItemsType,
  SearchSelect,
} from "../common/type";
import { getInvoiceChangeLog } from "../api/getInvoiceChangeLog";
import { putInvoiceChangeLogId } from "../api/putInvoiceChangeLogId";

/**
 * 支払請求変更履歴フック
 * @returns
 */
export const useInoviceChangeLog = () => {
  const { dbUser } = useDBUserContext();
  const [nextOffset, setNextOffset] = useState(0);
  const [previousOffset, setPreviousOffset] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [invoiceChangeLogDataList, setInvoiceChangeLogDataList] = useState([]);

  // 検索機能
  const [searchItems] = useState<SearchItemsType[]>([
    {
      value: "selectName",
      label: "代理店名",
      method: "word",
      type: "string",
    },
    {
      value: "selectCode",
      label: "代理店コード",
      method: "word",
      type: "string",
    },
    {
      value: "selectYearMonth",
      label: "対象年月",
      method: "word",
      type: "number",
    },
    {
      value: "selectDocsNo",
      label: "通知書No",
      method: "word",
      type: "string",
    },
    {
      value: "selectModificationDate",
      label: "変更日",
      method: "date",
      type: "string",
    },
    {
      value: "selectModifier",
      label: "変更者",
      method: "word",
      type: "string",
    },
  ]);
  const [selectedItem, setSelectedItem] = useState<SearchSelect>(
    searchItems[0].value
  );
  const [inputKeyword, setInputKeyword] = useState("");

  /* 支払請求変更履歴一覧取得 */
  const getInvoiceChangeLogList = useCallback(
    async (offset: number = 0, searchSelect?: string, keyword?: string) => {
      try {
        setIsLoading(true);
        const invoiceChangeLog = await getInvoiceChangeLog(
          PAGE_SIZE,
          offset,
          searchSelect,
          keyword
        );
        const invoiceChangeLogBody = await JSON.parse(invoiceChangeLog?.body);
        setInvoiceChangeLogDataList(
          invoiceChangeLogBody.data.map((data: any) => {
            return {
              id: data.id,
              docs_no: data.docs_no,
              year_month: data.year_month,
              agency_code: data.agency_code,
              agency_name: data.agency_name,
              modifier: data.modifier,
              created_at: data.created_at,
              updated_at: data.updated_at,
            };
          })
        );
        setPreviousOffset(offset - PAGE_SIZE);
        setNextOffset(invoiceChangeLogBody.next_offset);
      } catch (e) {
        console.error(e);
        setInvoiceChangeLogDataList([]);
        setPreviousOffset(-1);
        setNextOffset(-1);
      } finally {
        setIsLoading(false);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [dbUser]
  );

  useEffect(() => {
    if (dbUser.user_id) {
      getInvoiceChangeLogList();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(true);

  /* 更新メッセージ */
  const [message, setMessage] = useState("");

  /* 支払請求変更履歴詳細モーダルオープン時、支払請求変更履歴情報取得 */
  const modalOpen = async (data: InvoiceChangeLogData | null = null) => {
    if (data) {
      getInvoiceChangeLogIdData(data.id);
    } else {
      setInvoiceChangeLogIdData(invoiceChangeLogDetailData);
      setIsModalLoading(false);
    }
    setIsModalOpen(!isModalOpen);
  };

  // 支払請求変更履歴詳細
  const [invoiceChangeLogIdData, setInvoiceChangeLogIdData] = useState(
    invoiceChangeLogDetailData
  );

  const [remarks, setRemarks] = useState("");

  const getInvoiceChangeLogIdData = useCallback(
    async (invoiceChangeLogId: number) => {
      try {
        setInvoiceChangeLogIdData(invoiceChangeLogDetailData);
        setIsModalLoading(true);
        const invoiceChangeLogIdData = await getInvoiceChangeLogId(
          invoiceChangeLogId
        );
        const invoiceChangeLogIdBody = await JSON.parse(
          invoiceChangeLogIdData?.body
        );
        setInvoiceChangeLogIdData({
          id: invoiceChangeLogIdBody.id,
          docs_no: invoiceChangeLogIdBody.docs_no,
          year_month: invoiceChangeLogIdBody.year_month,
          agency_code: invoiceChangeLogIdBody.agency_code,
          agency_name: invoiceChangeLogIdBody.agency_name,
          change_details: invoiceChangeLogIdBody.change_details,
          modifier: invoiceChangeLogIdBody.modifier,
          remarks: invoiceChangeLogIdBody.remarks,
          file_name: invoiceChangeLogIdBody.file_name,
          details_file_name: invoiceChangeLogIdBody.details_file_name,
          created_at: invoiceChangeLogIdBody.created_at,
          updated_at: invoiceChangeLogIdBody.updated_at,
        });
      } catch (e) {
        console.error(e);
      } finally {
        setIsModalLoading(false);
      }
    },
    []
  );

  /* 支払請求変更履歴の更新ボタン押下 */
  const onUpdateInvoiceClick = async () => {
    try {
      let res;
      res = await putInvoiceChangeLogId(invoiceChangeLogIdData.id, remarks);
      if (res.statusCode === 200) {
        setMessage("支払請求変更履歴の備考を更新しました。");
        setInvoiceChangeLogIdData({
          ...invoiceChangeLogIdData,
          remarks: remarks,
        });
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  return {
    invoiceChangeLogDataList,
    nextOffset,
    previousOffset,
    isLoading,
    getInvoiceChangeLogList,
    selectedItem,
    setSelectedItem,
    inputKeyword,
    setInputKeyword,
    searchItems,
    // 詳細モーダル
    modalOpen,
    isModalOpen,
    setIsModalOpen,
    isModalLoading,
    invoiceChangeLogIdData,
    onUpdateInvoiceClick,
    remarks,
    setRemarks,
    message,
    setMessage,
  };
};
