import { FC, ReactNode, useState, useEffect, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./Button";

type Props = {
  children: ReactNode;
  isAlert: boolean;
  setIsAlert: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => Promise<void>;
  title: string;
  submitText: string;
  cancelText: string;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  onClose?: () => void;
};

const Alert: FC<Props> = ({
  children,
  isAlert,
  setIsAlert,
  onClick,
  title,
  submitText,
  cancelText,
  message,
  setMessage,
  onClose,
}) => {
  const cancelButtonRef = useRef(null);
  const [isButtonClick, setIsButtonClick] = useState(false);

  const submitButtonHandler = async () => {
    setIsButtonClick(true);
    await onClick();
    setIsButtonClick(false);
  };

  const cancelButtonHandler = () => {
    setIsButtonClick(true);
    setIsAlert(false);
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (isAlert) {
      setIsButtonClick(false);
      setMessage("");
    }
  }, [isAlert, setMessage]);

  const convertedMessage = message?.split("\n").map((item, index) => {
    return (
      <Fragment key={index}>
        {item}
        <br />
      </Fragment>
    );
  });

  return (
    <Transition.Root show={isAlert} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-20"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="ml-4 text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-text border-b border-gray-300 pb-2"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="my-6">
                      <p className="text-textSub">
                        {message ? convertedMessage : children}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 grid grid-flow-row-dense grid-cols-2 gap-6">
                  <Button
                    onClick={() => cancelButtonHandler()}
                    disabled={isButtonClick}
                    buttonType={"normal"}
                  >
                    {message ? "閉じる" : cancelText}
                  </Button>
                  {submitText && !message && (
                    <Button
                      onClick={() => submitButtonHandler()}
                      disabled={isButtonClick}
                      isLoading={isButtonClick}
                      buttonType={"accent"}
                    >
                      {submitText}
                    </Button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Alert;
