import { Storage } from "aws-amplify";
import { useState } from "react";
import { ReleaseFilesData } from "../../common/type";
import Alert from "../common/Alert";

type Props = {
  isAlert: boolean;
  setIsAlert: React.Dispatch<React.SetStateAction<boolean>>;
  releaseFilesDataList: ReleaseFilesData[];
  setReleaseFilesDataList: React.Dispatch<
    React.SetStateAction<ReleaseFilesData[]>
  >;
  selectedRemoveReleaseFiles: ReleaseFilesData;
};

const RemoveModal = (props: Props) => {
  const [message, setMessage] = useState("");

  const handleRemove = async () => {
    try {
      const extension = props.selectedRemoveReleaseFiles.file_name
        .split(".")
        .pop();
      const { results } = await Storage.list(
        `release-files/${props.selectedRemoveReleaseFiles.release_file_id}.${extension}`
      );
      if (results.length === 0) {
        setMessage("ファイルが存在しないため、削除できませんでした。");
        return;
      }
      await Storage.remove(
        `release-files/${props.selectedRemoveReleaseFiles.release_file_id}.${extension}`
      );
      props.setReleaseFilesDataList(
        props.releaseFilesDataList.filter(
          (releaseFiles) =>
            releaseFiles.release_file_id !==
            props.selectedRemoveReleaseFiles.release_file_id
        )
      );
      setMessage("削除しました。");
    } catch (e) {
      console.error(e);
      setMessage("削除が失敗しました。");
    }
  };

  return (
    <div>
      <Alert
        isAlert={props.isAlert}
        setIsAlert={props.setIsAlert}
        onClick={handleRemove}
        title="公開ファイル削除"
        submitText="はい"
        cancelText="いいえ"
        message={message}
        setMessage={setMessage}
      >
        {`${props.selectedRemoveReleaseFiles.file_name}を削除しますか？`}
      </Alert>
    </div>
  );
};

export default RemoveModal;
