import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

interface SelectedData {
  notification_doc_no: string;
  year_month: string;
  confirmedDate: string | null;
}
export const putNotificationDocsNo = async (selectedData: SelectedData) => {
  const path = `/notification_docs/${encodeURIComponent(
    selectedData.notification_doc_no
  )}`;
  const putData = {
    year_month: selectedData.year_month,
    confirmed_date: selectedData.confirmedDate,
  };
  const params = { body: putData };
  let result: any;
  await API.put(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
