import { FC, useEffect, useState } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { AuthenticatorComponents } from "../components/AuthenticatorComponents";
import Root from "../routes/Root";
import { useTranslation } from "../hooks/useTranslation";
import { useAmplifyConfigure } from "../hooks/useAmplifyConfigure";
import { Hub } from "aws-amplify";

type Props = {
  isAdmin: boolean;
};

/**
 * ログイン画面
 * @returns
 */
const Login: FC<Props> = ({ isAdmin }) => {
  /* Amplify定義読み込み */
  const { setAdminConfigure, setUserConfigure } = useAmplifyConfigure();
  if (isAdmin) {
    setAdminConfigure();
  } else {
    setUserConfigure();
  }

  /* Amplify翻訳 */
  const { setJapanese } = useTranslation();
  setJapanese();

  /* パスワード変更検知 */
  const [submitElement, setSubmitElement] = useState<JSX.Element | null>(null);
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data, message } }) => {
      switch (event) {
        case "forgotPasswordSubmit":
          if (message && message.lastIndexOf("successful") > 0) {
            setSubmitElement(
              <div>
                パスワード変更に成功しました。
                <br />
                新しいパスワードでログインしてください。
              </div>
            );
          }
          break;
        case "forgotPassword":
        case "signIn":
        case "signOut":
          setSubmitElement(null);
          break;
      }
    });
  }, []);

  return (
    <Authenticator
      components={{
        ...AuthenticatorComponents,
        Header: () => (
          <AuthenticatorComponents.Header submitElement={submitElement} />
        ),
        Footer: () => <AuthenticatorComponents.Footer />,
        SignIn: {
          Header: () => (
            <AuthenticatorComponents.SignIn.Header isAdmin={isAdmin} />
          ),
          Footer: () => <AuthenticatorComponents.SignIn.Footer />,
        },
        ResetPassword: {
          Header: () => <AuthenticatorComponents.ResetPassword.Header />,
        },
        ConfirmResetPassword: {
          Header: () => <AuthenticatorComponents.ConfirmResetPassword.Header />,
        },
      }}
      hideSignUp={true}
      loginMechanisms={["email"]}
    >
      {() => <Root isAdmin={isAdmin} />}
    </Authenticator>
  );
};

export default Login;
