import React, { FC, useState } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import Input from "../common/Input";
import Alert from "../common/Alert";
import { PaymentData } from "../../common/type";
import LoadingSpinner from "../common/LoadingSpinner";

type Props = {
  modalTitle: string;
  payment: PaymentData;
  isModalLoading: boolean;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  paymentName: string;
  setPaymentName: React.Dispatch<React.SetStateAction<string>>;
  paymentNameKana: string;
  setPaymentNameKana: React.Dispatch<React.SetStateAction<string>>;
  paymentPostalCode: string;
  setPaymentPostalCode: React.Dispatch<React.SetStateAction<string>>;
  paymentAddress: string;
  setPaymentAddress: React.Dispatch<React.SetStateAction<string>>;
  paymentPhoneNumber: string;
  setPaymentPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  paymentFinancialFacilityName: string;
  setPaymentFinancialFacilityName: React.Dispatch<React.SetStateAction<string>>;
  paymentBranchName: string;
  setPaymentBranchName: React.Dispatch<React.SetStateAction<string>>;
  paymentAccountType: string;
  setPaymentAccountType: React.Dispatch<React.SetStateAction<string>>;
  paymentBankAccountNumber: string;
  setPaymentBankAccountNumber: React.Dispatch<React.SetStateAction<string>>;
  paymentRegistrationNumber: string;
  setPaymentRegistrationNumber: React.Dispatch<React.SetStateAction<string>>;
  onUpdatePayment: () => Promise<void>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * 会社情報編集モーダル
 * @param props
 * @returns
 */
const PaymentDetailModal: FC<Props> = ({
  modalTitle,
  payment,
  isModalLoading,
  isModalOpen,
  setIsModalOpen,
  paymentName,
  setPaymentName,
  paymentNameKana,
  setPaymentNameKana,
  paymentPostalCode,
  setPaymentPostalCode,
  paymentAddress,
  setPaymentAddress,
  paymentPhoneNumber,
  setPaymentPhoneNumber,
  paymentFinancialFacilityName,
  setPaymentFinancialFacilityName,
  paymentBranchName,
  setPaymentBranchName,
  paymentAccountType,
  setPaymentAccountType,
  paymentBankAccountNumber,
  setPaymentBankAccountNumber,
  paymentRegistrationNumber,
  setPaymentRegistrationNumber,
  onUpdatePayment,
  message,
  setMessage,
}) => {
  const [isAlert, setIsAlert] = useState(false);
  const [isValidationAlert, setIsValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const validation = () => {
    const checkInputData = [
      { value: paymentName, name: "会社名" },
      { value: paymentNameKana, name: "会社名カナ" },
      { value: paymentPostalCode, name: "郵便番号", pattern: /^\d{3}-\d{4}$/ },
      { value: paymentAddress, name: "住所" },
      { value: paymentPhoneNumber, name: "電話番号", pattern: /^\d+-\d+-\d+$/ },
      { value: paymentFinancialFacilityName, name: "金融機関名" },
      { value: paymentBranchName, name: "金融機関支店名" },
      { value: paymentAccountType, name: "口座種別" },
      { value: paymentBankAccountNumber, name: "口座番号" },
    ];
    for (let i = 0; i < checkInputData.length; i++) {
      const { value, name, pattern } = checkInputData[i];
      if (value.length <= 0) {
        setValidationMessage(`${name}が入力されていません。`);
        setIsValidationAlert(true);
        return;
      }
      if (pattern && !pattern.test(value)) {
        setValidationMessage(`${name}の形式が正しくありません。`);
        setIsValidationAlert(true);
        return;
      }
    }
    if (paymentName.length > 255) {
      setValidationMessage("会社名が255文字を超えています。");
      setIsValidationAlert(true);
    } else if (paymentNameKana.length > 255) {
      setValidationMessage("会社名カナが255文字を超えています。");
      setIsValidationAlert(true);
    } else if (paymentAddress.length > 255) {
      setValidationMessage("住所が255文字を超えています。");
      setIsValidationAlert(true);
    } else if (paymentFinancialFacilityName.length > 255) {
      setValidationMessage("金融機関名が255文字を超えています。");
      setIsValidationAlert(true);
    } else if (paymentBranchName.length > 255) {
      setValidationMessage("金融機関支店名が255文字を超えています。");
      setIsValidationAlert(true);
    } else if (paymentAccountType.length > 255) {
      setValidationMessage("口座種別が255文字を超えています。");
      setIsValidationAlert(true);
    } else if (paymentBankAccountNumber.length > 255) {
      setValidationMessage("口座番号が255文字を超えています。");
      setIsValidationAlert(true);
    } else if (paymentRegistrationNumber.length > 14) {
      setValidationMessage("登録番号の文字数は14文字で入力してください。");
      setIsValidationAlert(true);
    } else {
      setValidationMessage("");
      setIsAlert(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Modal isOpen={isModalOpen}>
      <h3 className="text-2xl font-semibold leading-6 border-b border-gray-300 text-text pb-2">
        {modalTitle}
      </h3>
      {isModalLoading ? (
        <div className="my-20">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="mt-4">
            <dl>
              <div className="px-4 py-2 grid grid-cols-7 items-center">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  会社名
                </dt>
                <dd className="ml-2 leading-6 col-span-6">
                  <Input
                    id="name"
                    type="organization"
                    autoComplete="organization"
                    isRequired={true}
                    defaultValue={payment.name}
                    inputValue={paymentName}
                    placeholder="会社名を入力してください"
                    setInputValue={setPaymentName}
                    maxLength={255}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  会社名カナ
                </dt>
                <dd className="ml-2 leading-6 col-span-6">
                  <Input
                    id="nameKana"
                    type="organization"
                    autoComplete="organization"
                    isRequired={true}
                    defaultValue={payment.name_kana}
                    inputValue={paymentNameKana}
                    placeholder="会社名カナを入力してください"
                    setInputValue={setPaymentNameKana}
                    maxLength={255}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  郵便番号
                </dt>
                <dd className="ml-2 leading-6 col-span-6">
                  <Input
                    id="postalCode"
                    type="postal-code"
                    autoComplete="postal-code"
                    isRequired={true}
                    defaultValue={payment.postal_code}
                    inputValue={paymentPostalCode}
                    placeholder="郵便番号を入力してください"
                    setInputValue={setPaymentPostalCode}
                    maxLength={10}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  住所
                </dt>
                <dd className="ml-2 leading-6 col-span-6">
                  <Input
                    id="address"
                    type="street-address"
                    autoComplete="street-address"
                    isRequired={true}
                    defaultValue={payment.address}
                    inputValue={paymentAddress}
                    placeholder="住所を入力してください"
                    setInputValue={setPaymentAddress}
                    maxLength={255}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  電話番号
                </dt>
                <dd className="ml-2 leading-6 col-span-6">
                  <Input
                    id="phoneNumber"
                    type="tel"
                    autoComplete="tel"
                    isRequired={true}
                    defaultValue={payment.phone_number}
                    inputValue={paymentPhoneNumber}
                    placeholder="電話番号を入力してください"
                    setInputValue={setPaymentPhoneNumber}
                    maxLength={15}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  金融機関名
                </dt>
                <dd className="ml-2 leading-6 col-span-6">
                  <Input
                    id="financialFacilityName"
                    type="text"
                    autoComplete="text"
                    isRequired={true}
                    defaultValue={payment.financial_facility_name}
                    inputValue={paymentFinancialFacilityName}
                    placeholder="金融機関名を入力してください"
                    setInputValue={setPaymentFinancialFacilityName}
                    maxLength={255}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  金融機関支店名
                </dt>
                <dd className="ml-2 leading-6 col-span-6">
                  <Input
                    id="branchName"
                    type="text"
                    autoComplete="text"
                    isRequired={true}
                    defaultValue={payment.branch_name}
                    inputValue={paymentBranchName}
                    placeholder="金融機関支店名を入力してください"
                    setInputValue={setPaymentBranchName}
                    maxLength={255}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  口座種別
                </dt>
                <dd className="ml-2 leading-6 col-span-6">
                  <Input
                    id="accountType"
                    type="text"
                    autoComplete="text"
                    isRequired={true}
                    defaultValue={payment.account_type}
                    inputValue={paymentAccountType}
                    placeholder="口座種別を入力してください"
                    setInputValue={setPaymentAccountType}
                    maxLength={255}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  口座番号
                </dt>
                <dd className="ml-2 leading-6 col-span-6">
                  <Input
                    id="accountNumber"
                    type="text"
                    autoComplete="text"
                    isRequired={true}
                    defaultValue={payment.bank_account_number}
                    inputValue={paymentBankAccountNumber}
                    placeholder="口座番号を入力してください"
                    setInputValue={setPaymentBankAccountNumber}
                    maxLength={255}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  登録番号
                </dt>
                <dd className="ml-2 leading-6 col-span-6">
                  <Input
                    id="registrationNumber"
                    type="text"
                    autoComplete="text"
                    isRequired={true}
                    defaultValue={payment.registration_number}
                    inputValue={paymentRegistrationNumber}
                    placeholder="登録番号を入力してください"
                    setInputValue={setPaymentRegistrationNumber}
                    maxLength={14}
                  />
                </dd>
              </div>
            </dl>
          </div>

          <div className="mt-4 grid grid-flow-row-dense grid-cols-3 gap-60">
            <Button
              onClick={() => closeModal()}
              disabled={!isModalOpen}
              buttonType={"normal"}
            >
              閉じる
            </Button>
            <Button onClick={() => validation()} disabled={isAlert}>
              更新
            </Button>
            <Alert
              isAlert={isAlert}
              setIsAlert={setIsAlert}
              onClick={onUpdatePayment}
              title={"会社情報編集"}
              submitText={"はい"}
              cancelText={"いいえ"}
              message={message}
              setMessage={setMessage}
            >
              会社情報を更新します。
              <br />
              よろしいですか？
            </Alert>
            {isValidationAlert && (
              <>
                <Alert
                  isAlert={isValidationAlert}
                  setIsAlert={setIsValidationAlert}
                  onClick={async () => {}}
                  title={"入力チェック"}
                  submitText={""}
                  cancelText={"閉じる"}
                  message={""}
                  setMessage={setMessage}
                >
                  {validationMessage}
                </Alert>
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default PaymentDetailModal;
