import LoadingSpinner from "../components/common/LoadingSpinner";
import PaymentTable from "../components/payment/PaymentTable";
import Button from "../components/common/Button";
import { usePayment } from "../hooks/usePayment";
import PaymentDetailModal from "../components/payment/PaymentModal";
import PaymentUserModal from "../components/payment/PaymentUserModal";
import { PaymentsIcon } from "../components/Icon";
import { useDBUserContext } from "../components/contexts/DBUserContext";

/**
 * 会社情報画面
 * @returns
 */
const Payment = () => {
  const {
    // 会社情報データ
    paymentDataInfo,
    // 会社情報編集
    isLoading,
    isModalLoading,
    isModalOpen,
    setIsModalOpen,
    paymentName,
    setPaymentName,
    paymentNameKana,
    setPaymentNameKana,
    paymentPostalCode,
    setPaymentPostalCode,
    paymentAddress,
    setPaymentAddress,
    paymentPhoneNumber,
    setPaymentPhoneNumber,
    paymentFinancialFacilityName,
    setPaymentFinancialFacilityName,
    paymentBranchName,
    setPaymentBranchName,
    paymentAccountType,
    setPaymentAccountType,
    paymentBankAccountNumber,
    setPaymentBankAccountNumber,
    paymentRegistrationNumber,
    setPaymentRegistrationNumber,
    onUpdatePayment,
    message,
    setMessage,
    // 管理ユーザー登録画面
    usersListData,
    isUserModalLoading,
    userModalOpen,
    isUserModalOpen,
    setIsUserModalOpen,
    userEmailValue,
    setUserEmailValue,
    userNameValue,
    setUserNameValue,
    userPermissionsValue,
    setUserPermissionsValue,
    setUpdateUserId,
    setUpdateUserName,
    setUpdateUserStatus,
    setUpdateUserPermissions,
    resetUserEditingList,
    setResetUserEditingList,
    onRegisterUserClick,
    onUpdateUserClick,
    resetPassword,
  } = usePayment();

  const { dbUser } = useDBUserContext();

  const updateModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="flex flex-col pt-24">
        <div className="flex items-center pb-10">
          <PaymentsIcon />
          <h2 className="text-2xl font-semibold leading-6 py-4 ml-2">
            会社情報
          </h2>
          <div className="flex flex-grow justify-end items-center">
            {dbUser.user_id && dbUser.permissions !== 2 && (
              <>
                <div className="mr-4">
                  <Button
                    onClick={() => userModalOpen()}
                    disabled={isUserModalOpen === true}
                  >
                    ユーザー追加
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => updateModalOpen()}
                    disabled={isModalOpen === true}
                  >
                    編集
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="mt-20">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <PaymentTable payment={paymentDataInfo} />
          <PaymentDetailModal
            modalTitle="会社情報編集"
            payment={paymentDataInfo}
            isModalLoading={isModalLoading}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            paymentName={paymentName}
            setPaymentName={setPaymentName}
            paymentNameKana={paymentNameKana}
            setPaymentNameKana={setPaymentNameKana}
            paymentPostalCode={paymentPostalCode}
            setPaymentPostalCode={setPaymentPostalCode}
            paymentAddress={paymentAddress}
            setPaymentAddress={setPaymentAddress}
            paymentPhoneNumber={paymentPhoneNumber}
            setPaymentPhoneNumber={setPaymentPhoneNumber}
            paymentFinancialFacilityName={paymentFinancialFacilityName}
            setPaymentFinancialFacilityName={setPaymentFinancialFacilityName}
            paymentBranchName={paymentBranchName}
            setPaymentBranchName={setPaymentBranchName}
            paymentAccountType={paymentAccountType}
            setPaymentAccountType={setPaymentAccountType}
            paymentBankAccountNumber={paymentBankAccountNumber}
            setPaymentBankAccountNumber={setPaymentBankAccountNumber}
            paymentRegistrationNumber={paymentRegistrationNumber}
            setPaymentRegistrationNumber={setPaymentRegistrationNumber}
            onUpdatePayment={onUpdatePayment}
            message={message}
            setMessage={setMessage}
          />
          <PaymentUserModal
            modalTitle={"管理ユーザー登録"}
            usersListData={usersListData}
            isUserModalOpen={isUserModalOpen}
            setIsUserModalOpen={setIsUserModalOpen}
            isUserModalLoading={isUserModalLoading}
            userEmailValue={userEmailValue}
            setUserEmailValue={setUserEmailValue}
            userNameValue={userNameValue}
            setUserNameValue={setUserNameValue}
            userPermissionsValue={userPermissionsValue}
            setUserPermissionsValue={setUserPermissionsValue}
            setUpdateUserId={setUpdateUserId}
            setUpdateUserName={setUpdateUserName}
            setUpdateUserStatus={setUpdateUserStatus}
            setUpdateUserPermissions={setUpdateUserPermissions}
            resetUserEditingList={resetUserEditingList}
            setResetUserEditingList={setResetUserEditingList}
            onRegisterUserClick={onRegisterUserClick}
            onUpdateUserClick={onUpdateUserClick}
            resetPassword={resetPassword}
            message={message}
            setMessage={setMessage}
          />
        </>
      )}
    </>
  );
};

export default Payment;
