import React, { FC, useState } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import LoadingSpinner from "../common/LoadingSpinner";
import Alert from "../common/Alert";
import * as luxon from "luxon";
import Input from "../common/Input";
import { UserData } from "../../common/type";

type Props = {
  modalTitle: string;
  isUserModalOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isUserModalLoading: boolean;
  usersListData: UserData[];
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  userEmailValue: string;
  setUserEmailValue: React.Dispatch<React.SetStateAction<string>>;
  onRegisterUserClick: () => Promise<void>;
  onUpdateUserClick: (selectedEmail: string, enabled: boolean) => Promise<void>;
  resetPassword: (selectedEmail: string) => Promise<void>;
};

/**
 * 代理店モーダル
 * @param props
 * @returns
 */
const AgencyUserModal: FC<Props> = ({
  modalTitle,
  isUserModalOpen,
  setIsOpen,
  isUserModalLoading,
  usersListData,
  message,
  setMessage,
  userEmailValue,
  setUserEmailValue,
  onRegisterUserClick,
  onUpdateUserClick,
  resetPassword,
}) => {
  const [isAlert, setIsAlert] = useState(false);
  const [isUpdateAlert, setIsUpdateAlert] = useState(false);
  const [isValidationAlert, setIsValidationAlert] = useState(false);
  const [isResetPasswordAlert, setIsResetPasswordAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const [selectedEmail, setSelectedEmail] = useState("");
  const [enabled, setEnabled] = useState(false);

  const validation = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (userEmailValue.length <= 0) {
      setValidationMessage("ユーザーIDが入力されていません。");
      setIsValidationAlert(true);
    } else if (!emailRegex.test(userEmailValue)) {
      setValidationMessage(
        "ユーザーID(メールアドレス)の形式が正しくありません。"
      );
      setIsValidationAlert(true);
    } else if (userEmailValue.length > 255) {
      setValidationMessage("ユーザーIDの文字数が255文字を超えています。");
      setIsValidationAlert(true);
    } else {
      setValidationMessage("");
      setIsAlert(true);
    }
  };

  return (
    <Modal isOpen={isUserModalOpen}>
      <h3 className="text-2xl font-semibold leading-6 border-b border-gray-300 text-text pb-2">
        {modalTitle}
      </h3>
      <div className="flex mt-8">
        <dl>
          <div className="px-4 py-2 grid grid-cols-6 items-center">
            <dt className="font-medium leading-6 col-span-1 text-gray-500 text-sm">
              ユーザーID(メールアドレス)
            </dt>
            <dd className="leading-6 col-span-4 ml-4">
              <Input
                id="userEmail"
                type="email"
                autoComplete="email"
                isRequired={true}
                defaultValue={""}
                inputValue={userEmailValue}
                setInputValue={setUserEmailValue}
                placeholder="ユーザーID(メールアドレス)を入力してください"
                maxLength={255}
              />
            </dd>
            <dd className="leading-6 col-span-1 ml-8">
              <Button
                onClick={() => validation()}
                disabled={!isUserModalOpen || isAlert}
                buttonType={"accent"}
              >
                登録
              </Button>
            </dd>
          </div>
        </dl>
        <div className="flex items-center justify-center"></div>
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          onClick={onRegisterUserClick}
          title={"代理店ユーザー登録"}
          submitText={"はい"}
          cancelText={"いいえ"}
          message={message}
          setMessage={setMessage}
        >
          代理店ユーザーを登録します。
          <br />
          よろしいですか？
        </Alert>
      </div>
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-8">
          <div className="inline-block min-w-full py-2 align-middle px-8">
            {isUserModalLoading ? (
              <div className="flex justify-center items-center my-10">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="overflow-auto min-h-[8vh] max-h-[40vh]">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="sticky top-0 bg-white z-50 shadow-sm">
                    <tr className="text-gray-500 text-xs font-semibold">
                      <th scope="col" className="w-[50%] px-3 py-3.5 text-left">
                        ユーザーID(メールアドレス)
                      </th>
                      <th scope="col" className="w-[14%] px-3 py-3.5 text-left">
                        ステータス
                      </th>
                      <th scope="col" className="w-[20%] px-3 py-3.5 text-left">
                        無効日時
                      </th>
                      <th scope="col" className="w-[20%] px-3 py-3.5 text-left">
                        仮パスワード再通知
                      </th>
                      <th
                        scope="col"
                        className="w-[16%] px-3 py-3.5 text-left"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {usersListData.map((data) => {
                      return (
                        <tr key={data.user_id}>
                          <td className="whitespace-wrap px-3 py-2 text-sm text-left break-all">
                            {data.user_id}
                          </td>
                          <td className="whitespace-wrap px-3 py-2 text-sm text-left">
                            {data.enabled ? "有効" : "無効"}
                          </td>
                          <td className="whitespace-wrap px-3 py-2 text-sm text-left">
                            {data.invalid_datetime &&
                              luxon.DateTime.fromSQL(
                                data.invalid_datetime
                              ).toFormat("yyyy/MM/dd HH:mm:ss")}
                          </td>
                          <td className="whitespace-wrap px-3 py-2 text-sm text-left">
                            {data.status === "FORCE_CHANGE_PASSWORD" ? (
                              <Button
                                onClick={() => {
                                  setIsResetPasswordAlert(true);
                                  setSelectedEmail(data.user_id);
                                }}
                                disabled={!isUserModalOpen}
                                buttonType={"normal"}
                              >
                                送信
                              </Button>
                            ) : null}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-left relative">
                            <Button
                              onClick={() => {
                                setIsUpdateAlert(true);
                                setSelectedEmail(data.user_id);
                                setEnabled(!data.enabled);
                              }}
                              disabled={isUpdateAlert}
                              buttonType="normal"
                            >
                              {!data.enabled ? "有効" : "無効"}
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            <Alert
              isAlert={isUpdateAlert}
              setIsAlert={setIsUpdateAlert}
              onClick={() => onUpdateUserClick(selectedEmail, enabled)}
              title={"代理店ユーザー状態更新"}
              submitText={"はい"}
              cancelText={"いいえ"}
              message={message}
              setMessage={setMessage}
            >
              代理店ユーザーを
              {enabled ? "有効" : "無効"}にします。
              <br />
              よろしいですか？
              <br />
              {selectedEmail}
            </Alert>
            <Alert
              isAlert={isResetPasswordAlert}
              setIsAlert={setIsResetPasswordAlert}
              onClick={() => resetPassword(selectedEmail)}
              title={"仮パスワードメール再送信"}
              submitText={"はい"}
              cancelText={"いいえ"}
              message={message}
              setMessage={setMessage}
            >
              仮パスワードのメールを再送信します。
              <br />
              よろしいですか？
              <br />
              {selectedEmail}
            </Alert>
          </div>
        </div>
      </div>
      <div className="mt-10 grid grid-flow-row-dense grid-cols-3 gap-60">
        <Button
          onClick={() => setIsOpen(!isUserModalOpen)}
          disabled={!isUserModalOpen}
          buttonType={"normal"}
        >
          閉じる
        </Button>
      </div>
      {isValidationAlert && (
        <>
          <Alert
            isAlert={isValidationAlert}
            setIsAlert={setIsValidationAlert}
            onClick={async () => {}}
            title={"入力チェック"}
            submitText={""}
            cancelText={"閉じる"}
            message={""}
            setMessage={setMessage}
          >
            {validationMessage}
          </Alert>
        </>
      )}
    </Modal>
  );
};

export default AgencyUserModal;
