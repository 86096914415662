import React, { FC, useState, useEffect } from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import Button from "../common/Button";
import Modal from "../common/Modal";
import { AgencyMenuItem, NewsData } from "../../common/type";
import LoadingSpinner from "../common/LoadingSpinner";
import Textarea from "../common/Textarea";
import Input from "../common/Input";
import Alert from "../common/Alert";
import { useDBUserContext } from "../contexts/DBUserContext";

type Props = {
  modalTitle: string;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedNewsData: NewsData;
  isModalLoading: boolean;
  selectedAgencyCode: string;
  setSelectedAgencyCode: React.Dispatch<React.SetStateAction<string>>;
  agenciesList: AgencyMenuItem[];
  onRegisterNewsClick: () => Promise<void>;
  onUpdateNewsClick: () => Promise<void>;
  onDeleteNewsClick: () => Promise<void>;
  isDeleteComplete: boolean;
  subjectValue: string;
  setSubjectValue: React.Dispatch<React.SetStateAction<string>>;
  contentValue: string;
  setContentValue: React.Dispatch<React.SetStateAction<string>>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * お知らせ編集モーダル
 * @param props
 * @returns
 */
const NewsEditModal: FC<Props> = ({
  modalTitle,
  isModalOpen,
  setIsModalOpen,
  selectedNewsData,
  isModalLoading,
  selectedAgencyCode,
  setSelectedAgencyCode,
  agenciesList,
  onRegisterNewsClick,
  onUpdateNewsClick,
  onDeleteNewsClick,
  isDeleteComplete,
  subjectValue,
  setSubjectValue,
  contentValue,
  setContentValue,
  message,
  setMessage,
}) => {
  const { dbUser } = useDBUserContext();
  const [isAlert, setIsAlert] = useState(false);
  const [isDeleteAlert, setIsDeleteAlert] = useState(false);
  const [isValidationAlert, setIsValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const validation = () => {
    if (subjectValue.length <= 0) {
      setValidationMessage("件名が入力されていません。");
      setIsValidationAlert(true);
    } else if (contentValue.length <= 0) {
      setValidationMessage("内容が入力されていません。");
      setIsValidationAlert(true);
    } else if (subjectValue.length > 100) {
      setValidationMessage("件名の文字数が100文字を超えています。");
      setIsValidationAlert(true);
    } else if (contentValue.length > 2000) {
      setValidationMessage("件名の文字数が2000文字を超えています。");
      setIsValidationAlert(true);
    } else {
      setValidationMessage("");
      setIsAlert(true);
    }
  };

  useEffect(() => {
    // 削除した場合にアラートが閉じられたら、同時にモーダルも閉じる
    if (isDeleteComplete && !isDeleteAlert) {
      setIsModalOpen(false);
    }
  }, [isDeleteComplete, isDeleteAlert, setIsModalOpen]);

  return (
    <Modal isOpen={isModalOpen}>
      <h3 className="text-2xl font-semibold leading-6 border-b border-gray-300 text-text pb-2">
        {modalTitle}
      </h3>
      {isModalLoading ? (
        <div className="my-20">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="mt-5 text-center text-textSub">
            <Input
              id="subject"
              type="text"
              isRequired={true}
              defaultValue={selectedNewsData.subject}
              inputValue={subjectValue}
              placeholder="お知らせの件名を入力してください"
              setInputValue={setSubjectValue}
              maxLength={100}
            />
            <Textarea
              row={8}
              isRequired={true}
              defaultValue={selectedNewsData.content}
              inputValue={contentValue}
              setInputValue={setContentValue}
              placeholder="お知らせの本文を入力してください"
              maxLength={2000}
            />
          </div>
          <div className="mt-5 flex justify-start items-center text-gray-500">
            <Typography sx={{ mr: 5, width: 80, fontSize: 16 }}>
              代理店
            </Typography>
            <FormControl sx={{ minWidth: 200 }}>
              <Select
                defaultValue={selectedAgencyCode}
                value={selectedAgencyCode}
                onChange={(e) =>
                  setSelectedAgencyCode(e.target.value as string)
                }
                displayEmpty
                className="text-left"
                inputProps={{ "aria-label": "Without label" }}
              >
                {agenciesList.map((agency: AgencyMenuItem) => (
                  <MenuItem key={agency.code} value={agency.code}>
                    {agency.code !== "" && "[" + agency.code + "] "}
                    {agency.name?.length > 20
                      ? `${agency.name.slice(0, 20)}...`
                      : agency.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="mt-5 grid grid-flow-row-dense grid-cols-3 gap-60">
            <Button
              onClick={() => setIsModalOpen(!isModalOpen)}
              disabled={!isModalOpen}
              buttonType={"normal"}
            >
              閉じる
            </Button>
            {selectedNewsData.subject ? (
              <>
                <Button onClick={() => validation()} disabled={isAlert}>
                  更新
                </Button>
                <Alert
                  isAlert={isAlert}
                  setIsAlert={setIsAlert}
                  onClick={onUpdateNewsClick}
                  title={"お知らせ更新"}
                  submitText={"はい"}
                  cancelText={"いいえ"}
                  message={message}
                  setMessage={setMessage}
                >
                  お知らせを更新します。
                  <br />
                  よろしいですか？
                </Alert>
              </>
            ) : (
              <>
                <Button onClick={() => validation()} disabled={isAlert}>
                  登録
                </Button>
                <Alert
                  isAlert={isAlert}
                  setIsAlert={setIsAlert}
                  onClick={onRegisterNewsClick}
                  title={"お知らせ登録"}
                  submitText={"はい"}
                  cancelText={"いいえ"}
                  message={message}
                  setMessage={setMessage}
                >
                  お知らせを登録します。
                  <br />
                  よろしいですか？
                </Alert>
              </>
            )}

            {selectedNewsData.subject && (
              <>
                <Button
                  onClick={() => setIsDeleteAlert(true)}
                  buttonType={"delete"}
                  disabled={isDeleteAlert}
                >
                  削除
                </Button>
                <Alert
                  isAlert={isDeleteAlert}
                  setIsAlert={setIsDeleteAlert}
                  onClick={onDeleteNewsClick}
                  title={"お知らせ削除"}
                  submitText={"はい"}
                  cancelText={"いいえ"}
                  message={message}
                  setMessage={setMessage}
                >
                  お知らせを削除します。
                  <br />
                  よろしいですか？
                </Alert>
              </>
            )}

            {isValidationAlert && (
              <>
                <Alert
                  isAlert={isValidationAlert}
                  setIsAlert={setIsValidationAlert}
                  onClick={async () => {}}
                  title={"入力チェック"}
                  submitText={""}
                  cancelText={"閉じる"}
                  message={""}
                  setMessage={setMessage}
                >
                  {validationMessage}
                </Alert>
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default NewsEditModal;
