import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const postNotificationDocsGenerate = async (
  paymentCode: string,
  yearMonth: string,
  docType: "pdf" | "csv",
  isdifferentialCreation: boolean = false
) => {
  const path = "/notification_docs/generate";
  const postData = {
    payment_code: paymentCode,
    year_month: yearMonth,
    doc_type: docType,
    is_differential_creation: isdifferentialCreation,
  };
  const params = { body: postData };
  let result: any;
  await API.post(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
