import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const postAgency = async (
  agencyCode: string,
  paymentCode: string,
  agencyName: string,
  registrationNumber: string
) => {
  const path = `/agencies`;
  const postData = {
    agency_code: agencyCode,
    payment_code: paymentCode,
    agency_name: agencyName,
    registration_number: registrationNumber,
  };
  const params = { body: postData };
  let result: any;
  await API.post(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
