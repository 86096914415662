import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const putNotificationDocs = async (
  yearMonth: string,
  paymentCode: string,
  confirmedDate: string | null
) => {
  const path = `/notification_docs`;
  const putData = {
    year_month: yearMonth,
    payment_code: paymentCode,
    confirmed_date: confirmedDate,
  };
  const params = { body: putData };
  let result: any;
  await API.put(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
