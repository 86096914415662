import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const postUser = async (
  userId: string,
  code: string,
  userName?: string,
  userPermissions?: string
) => {
  const path = `/users`;
  let postData;
  if (userName) {
    postData = {
      user_id: userId,
      payment_code: code,
      user_name: userName,
      permissions: userPermissions,
    };
  } else {
    postData = { user_id: userId, agency_code: code };
  }
  const params = { body: postData };
  let result: any;
  await API.post(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
