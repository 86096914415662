import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const putInvoiceChangeLogId = async (
  invoiceChangeLogId: number,
  remarks: string
) => {
  const path = `/invoice_change_log/${encodeURIComponent(invoiceChangeLogId)}`;
  const putData = {
    remarks: remarks,
  };
  const params = { body: putData };
  let result: any;
  await API.put(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
