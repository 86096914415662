import NewsTable from "../components/news/NewsTable";
import LoadingSpinner from "../components/common/LoadingSpinner";
import Page from "../components/common/Pagenation";
import Button from "../components/common/Button";
import NewsEditModal from "../components/news/NewsEditModal";
import { useNews } from "../hooks/useNews";
import { NewsIcon } from "../components/Icon";
import { useDBUserContext } from "../components/contexts/DBUserContext";
import NewsBrowseModal from "../components/news/NewsBrowseModal";

/**
 * お知らせ画面
 * @returns
 */
const News = () => {
  const {
    nextOffset,
    previousOffset,
    isLoading,
    getNewsList,
    newsDataList,
    isModalOpen,
    setIsModalOpen,
    isModalLoading,
    modalOpen,
    selectedNewsData,
    selectedAgencyCode,
    setSelectedAgencyCode,
    agenciesList,
    onRegisterNewsClick,
    onUpdateNewsClick,
    onDeleteNewsClick,
    isDeleteComplete,
    subjectValue,
    setSubjectValue,
    contentValue,
    setContentValue,
    message,
    setMessage,
  } = useNews();

  const { dbUser } = useDBUserContext();

  return (
    <>
      <div className="flex items-center pt-24">
        <NewsIcon />
        <div className="flex-auto ml-2">
          <h2 className="text-2xl font-semibold leading-6 py-4">お知らせ</h2>
        </div>
        <div className="ml-auto">
          {dbUser.user_id && dbUser.permissions !== 2 && (
            <Button onClick={() => modalOpen()} disabled={isModalOpen}>
              新規登録
            </Button>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="mt-20">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {dbUser.permissions === 2 ? (
            <NewsBrowseModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              selectedNewsData={selectedNewsData}
              isModalLoading={isModalLoading}
            />
          ) : (
            <NewsEditModal
              modalTitle="お知らせ詳細"
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              selectedNewsData={selectedNewsData}
              isModalLoading={isModalLoading}
              selectedAgencyCode={selectedAgencyCode}
              setSelectedAgencyCode={setSelectedAgencyCode}
              agenciesList={agenciesList}
              onRegisterNewsClick={onRegisterNewsClick}
              onUpdateNewsClick={onUpdateNewsClick}
              onDeleteNewsClick={onDeleteNewsClick}
              isDeleteComplete={isDeleteComplete}
              subjectValue={subjectValue}
              setSubjectValue={setSubjectValue}
              contentValue={contentValue}
              setContentValue={setContentValue}
              message={message}
              setMessage={setMessage}
            />
          )}
          <NewsTable
            newsDataList={newsDataList}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            modalOpen={modalOpen}
            selectedNewsData={selectedNewsData}
          />
          <Page
            onClick={getNewsList}
            previousOffset={previousOffset}
            nextOffset={nextOffset}
          />
        </>
      )}
    </>
  );
};

export default News;
