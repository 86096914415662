import { FC, useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import Button from "../common/Button";
import Alert from "../common/Alert";
import ErrorListModal from "./ErrorListModal";
import { postProductDetailsImport } from "../../api/postProductDetailsImport";

type Props = {
  importFiles: string[];
  selectedImportFile: string;
  setSelectedImportFile: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * ファイルインポート
 * @param props
 * @returns
 */
const FileImport: FC<Props> = ({
  importFiles,
  selectedImportFile,
  setSelectedImportFile,
}) => {
  const [isAlert, setIsAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleImportClick = async () => {
    try {
      const res = await postProductDetailsImport(selectedImportFile);
      if (res.statusCode === 200) {
        setMessage(
          "ファイルインポートを開始しました。\n完了するまでしばらく時間がかかります。\n完了したらメールでお知らせします。"
        );
      } else {
        setMessage(res?.message);
      }
    } catch {
      setMessage("ファイルインポートが開始できませんでした。");
    }
  };
  return (
    <>
      <h3 className="mt-5 text-left text-gray-500 text-base font-semibold">
        ファイルインポート
      </h3>
      <div className="flex flex-col gap-5 m-5">
        <FormControl>
          <Select
            value={selectedImportFile}
            onChange={(e) => setSelectedImportFile(e.target.value as string)}
            displayEmpty
            className="text-left"
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              <em className="text-textSub">
                インポートするファイルを選択してください
              </em>
            </MenuItem>
            {importFiles.map((importFile: string, i: number) => (
              <MenuItem key={i} value={importFile}>
                {importFile}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="flex">
        <div className="ml-auto flex gap-10">
          <Button
            onClick={() => setIsModalOpen(true)}
            buttonType="normal"
            disabled={isModalOpen === true}
          >
            エラーリスト
          </Button>
          <Button
            disabled={selectedImportFile === "" || isAlert === true}
            onClick={() => setIsAlert(true)}
          >
            インポート開始
          </Button>
        </div>
      </div>
      <Alert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        onClick={handleImportClick}
        title={"ファイルインポート"}
        submitText={"開始"}
        cancelText={"閉じる"}
        message={message}
        setMessage={setMessage}
      >
        選択されたファイルをインポートします。
        <br />
        インポートには数分ほど時間がかかる可能性があります。
        <br />
        インポートを開始する場合は「開始」を押してください。
      </Alert>
      <ErrorListModal
        modalTitle="インポート"
        errorFilePath="product-Import-error/importerror/"
        description="インポートエラーで取り込まれなかったシリアルNoのリストをダウンロードできます。<br>
        記載されたシリアルNoのレコードをインポートする場合は、対象のシリアルNoのレコードのみ抽出されたファイルを作成し、<br>
        アップロード後にインポートしてください。<br>
        （エラーファイルは１週間が経過すると自動で削除されます。）"
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};
export default FileImport;
