import { useContext } from "react";
import LoadingSpinner from "../components/common/LoadingSpinner";
import SearchModule from "../components/common/SearchModule";
import InvoiceTable from "../components/invoiceChangeLog/InvoiceChangeLogTable";
import Page from "../components/common/Pagenation";
import InvoiceDetailModal from "../components/invoiceChangeLog/InvoiceChangeLogModal";
import { useInoviceChangeLog } from "../hooks/useInoviceChangeLog";
import { IsAdminContext } from "../components/contexts/IsAdminContext";
import { InoviceChangeLogIcon } from "../components/Icon";

/**
 * 支払請求変更履歴画面
 * @returns
 */
const InoviceChangeLog = () => {
  const {
    invoiceChangeLogDataList,
    nextOffset,
    previousOffset,
    isLoading,
    getInvoiceChangeLogList,
    selectedItem,
    setSelectedItem,
    inputKeyword,
    setInputKeyword,
    searchItems,
    // 詳細モーダル
    modalOpen,
    isModalOpen,
    setIsModalOpen,
    isModalLoading,
    invoiceChangeLogIdData,
    onUpdateInvoiceClick,
    remarks,
    setRemarks,
    message,
    setMessage,
  } = useInoviceChangeLog();

  const { isAdmin } = useContext(IsAdminContext);

  return (
    <>
      <div className="flex flex-col pt-24 pb-2">
        <div className="flex items-center pb-10">
          <InoviceChangeLogIcon />
          <h2 className="text-2xl font-semibold leading-6 py-4 ml-2">
            支払請求変更履歴
          </h2>
          {isAdmin && (
            <div className="flex flex-grow justify-end items-center">
              <SearchModule
                onClick={() =>
                  getInvoiceChangeLogList(0, selectedItem, inputKeyword)
                }
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                inputKeyword={inputKeyword}
                setInputKeyword={setInputKeyword}
                searchItems={searchItems}
              />
            </div>
          )}
        </div>
      </div>
      {isLoading ? (
        <div className="mt-20">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <InvoiceDetailModal
            isModalOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            isModalLoading={isModalLoading}
            invoiceChangeLogIdData={invoiceChangeLogIdData}
            onUpdateInvoiceClick={onUpdateInvoiceClick}
            remarks={remarks}
            setRemarks={setRemarks}
            message={message}
            setMessage={setMessage}
          />
          <InvoiceTable
            invoiceChangeLogDataList={invoiceChangeLogDataList}
            modalOpen={modalOpen}
          />
          <Page
            onClick={getInvoiceChangeLogList}
            previousOffset={previousOffset}
            nextOffset={nextOffset}
            selectedItem={selectedItem}
            keyword={inputKeyword}
          />
        </>
      )}
    </>
  );
};

export default InoviceChangeLog;
