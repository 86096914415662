import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const getUsers = async (
  code: string,
  belongTo: "agency" | "payment"
) => {
  let path;
  if (belongTo === "agency") {
    path = `/users?agency_code=${code}`;
  } else {
    path = `/users?payment_code=${code}`;
  }
  const params = {};
  let result: any;
  await API.get(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
