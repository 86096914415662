import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";
import { PostNewsData } from "../common/type";

/**
 * お知らせ更新APIコール
 * @param data
 * @returns
 */
export const putNewsId = async (newsId: number, data: PostNewsData) => {
  let putData;
  if (data.agency_code) {
    putData = data;
  } else {
    putData = {
      subject: data.subject,
      content: data.content,
      post_user_id: data.post_user_id,
    };
  }
  const path = `/news/${encodeURIComponent(String(newsId))}`;
  const params = {
    body: putData,
  };
  let result: any;
  await API.put(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
