import React, { FC, useState } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import { AgencyData } from "../../common/type";
import LoadingSpinner from "../common/LoadingSpinner";
import Input from "../common/Input";
import Alert from "../common/Alert";
import * as luxon from "luxon";
import { useDBUserContext } from "../contexts/DBUserContext";

type Props = {
  modalTitle: string;
  selectedAgencyData: AgencyData;
  isModalOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalLoading: boolean;
  agencyCode: string;
  setAgencyCode: React.Dispatch<React.SetStateAction<string>>;
  agencyName: string;
  setAgencyName: React.Dispatch<React.SetStateAction<string>>;
  registrationNumber: string;
  setRegistrationNumber: React.Dispatch<React.SetStateAction<string>>;
  setIsTradingStart: React.Dispatch<React.SetStateAction<boolean>>;
  setIsModalReload: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateAgencyClick: () => Promise<void>;
  onUpdateAgencyTradingStatus: () => Promise<void>;
  isNewAgency: boolean;
  onRegisterAgencyClick: () => Promise<void>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * 代理店モーダル
 * @param props
 * @returns
 */
const AgencyDetailModal: FC<Props> = ({
  modalTitle,
  selectedAgencyData,
  isModalOpen,
  setIsOpen,
  isModalLoading,
  agencyCode,
  setAgencyCode,
  agencyName,
  setAgencyName,
  registrationNumber,
  setRegistrationNumber,
  setIsTradingStart,
  setIsModalReload,
  onUpdateAgencyClick,
  onUpdateAgencyTradingStatus,
  isNewAgency,
  onRegisterAgencyClick,
  message,
  setMessage,
}) => {
  const { dbUser } = useDBUserContext();
  const [isAlert, setIsAlert] = useState(false);
  const [isUpdateAlert, setIsUpdateAlert] = useState(false);
  const [isValidationAlert, setIsValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const validation = () => {
    if (agencyCode.length <= 0) {
      setValidationMessage("代理店コードが入力されていません。");
      setIsValidationAlert(true);
    } else if (agencyName.length <= 0) {
      setValidationMessage("代理店名が入力されていません。");
      setIsValidationAlert(true);
    } else if (/[^a-zA-Z0-9]/g.test(agencyCode)) {
      setValidationMessage("代理店コードには半角英数字のみ入力してください。");
      setIsValidationAlert(true);
    } else if (agencyCode.length !== 8) {
      setValidationMessage("代理店コードの文字数は8文字で入力してください。");
      setIsValidationAlert(true);
    } else if (agencyName.length > 255) {
      setValidationMessage("代理店名の文字数が255文字を超えています。");
      setIsValidationAlert(true);
    } else if (/[^a-zA-Z0-9]/g.test(registrationNumber)) {
      setValidationMessage("登録番号には半角英数字のみ入力してください。");
      setIsValidationAlert(true);
    } else if (registrationNumber.length > 14) {
      setValidationMessage("登録番号の文字数は14文字で入力してください。");
      setIsValidationAlert(true);
    } else {
      setValidationMessage("");
      setIsAlert(true);
    }
  };

  return (
    <Modal isOpen={isModalOpen}>
      <div>
        <h3 className="text-2xl font-semibold leading-6 border-b border-gray-300 text-text pb-2">
          {modalTitle}
        </h3>
      </div>
      {isModalLoading ? (
        <div className="my-20">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="mt-4">
            <dl>
              <div className="px-4 py-2 grid grid-cols-7 items-center h-16">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  登録日
                </dt>
                <dd className="leading-6 col-span-6">
                  {!isNewAgency &&
                    luxon.DateTime.fromSQL(
                      selectedAgencyData.createdAt
                    ).toFormat("yyyy/MM/dd")}
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center h-16">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  代理店コード
                </dt>
                <dd className="leading-6 col-span-6">
                  <Input
                    id="code"
                    type="text"
                    autoComplete="text"
                    isRequired={true}
                    defaultValue={selectedAgencyData.code}
                    inputValue={agencyCode}
                    placeholder="代理店コードを入力してください"
                    setInputValue={setAgencyCode}
                    maxLength={8}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center h-16">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  代理店名
                </dt>
                <dd className="leading-6 col-span-6">
                  <Input
                    id="name"
                    type="organization"
                    autoComplete="organization"
                    isRequired={true}
                    defaultValue={selectedAgencyData.name}
                    inputValue={agencyName}
                    placeholder="代理店名を入力してください"
                    setInputValue={setAgencyName}
                    maxLength={255}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center h-16">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  登録番号
                </dt>
                <dd className="leading-6 col-span-6">
                  <Input
                    id="registration_number"
                    type="organization"
                    autoComplete="organization"
                    isRequired={true}
                    defaultValue={selectedAgencyData.registration_number}
                    inputValue={registrationNumber}
                    placeholder="登録番号を入力してください"
                    setInputValue={setRegistrationNumber}
                    maxLength={14}
                  />
                </dd>
              </div>
              <div className="px-4 py-2 grid grid-cols-7 items-center h-16">
                <dt className="font-medium leading-6 text-gray-500 text-sm">
                  取引停止日
                </dt>
                <dd className="leading-6 col-span-6">
                  {!isNewAgency &&
                    selectedAgencyData.trading_stop_date &&
                    luxon.DateTime.fromSQL(
                      selectedAgencyData.trading_stop_date
                    ).toFormat("yyyy/MM/dd")}
                </dd>
              </div>
            </dl>
          </div>
          <div className="mt-6 grid grid-flow-row-dense grid-cols-3 gap-60">
            <Button
              onClick={() => setIsOpen(!isModalOpen)}
              disabled={!isModalOpen}
              buttonType={"normal"}
            >
              閉じる
            </Button>
            {isNewAgency ? (
              <>
                <Button onClick={() => validation()} disabled={isAlert}>
                  登録
                </Button>
                <Alert
                  isAlert={isAlert}
                  setIsAlert={setIsAlert}
                  onClick={onRegisterAgencyClick}
                  title={"代理店情報登録"}
                  submitText={"はい"}
                  cancelText={"いいえ"}
                  message={message}
                  setMessage={setMessage}
                >
                  代理店コードは登録後に変更できないケースがあります。
                  <br />
                  現在のコードで登録してよろしいですか？
                </Alert>
              </>
            ) : (
              <>
                <Button onClick={() => validation()} disabled={isAlert}>
                  更新
                </Button>
                <Alert
                  isAlert={isAlert}
                  setIsAlert={setIsAlert}
                  onClick={onUpdateAgencyClick}
                  title={"代理店情報変更"}
                  submitText={"はい"}
                  cancelText={"いいえ"}
                  message={message}
                  setMessage={setMessage}
                >
                  代理店情報を更新します。
                  <br />
                  よろしいですか？
                </Alert>
              </>
            )}
            {isNewAgency ? null : (
              <>
                {selectedAgencyData.trading_stop_date ? (
                  <>
                    <Button
                      onClick={() => {
                        setIsTradingStart(true);
                        setIsUpdateAlert(true);
                      }}
                      buttonType={"delete"}
                      disabled={isUpdateAlert}
                    >
                      取引再開
                    </Button>
                    <Alert
                      isAlert={isUpdateAlert}
                      setIsAlert={setIsUpdateAlert}
                      onClick={onUpdateAgencyTradingStatus}
                      title={"代理店取引再開"}
                      submitText={"はい"}
                      cancelText={"いいえ"}
                      message={message}
                      setMessage={setMessage}
                      onClose={() => {
                        if (
                          message ===
                          "代理店を取引再開しました。\n所属するユーザーを有効にしました。"
                        ) {
                          setIsModalReload(true);
                        }
                      }}
                    >
                      代理店を取引再開します。
                      <br />
                      該当の代理店ユーザーも有効化されログインできるようになります。
                      <br />
                      よろしいですか？
                    </Alert>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setIsTradingStart(false);
                        setIsUpdateAlert(true);
                      }}
                      buttonType={"delete"}
                      disabled={isUpdateAlert}
                    >
                      取引停止
                    </Button>
                    <Alert
                      isAlert={isUpdateAlert}
                      setIsAlert={setIsUpdateAlert}
                      onClick={onUpdateAgencyTradingStatus}
                      title={"代理店取引停止"}
                      submitText={"はい"}
                      cancelText={"いいえ"}
                      message={message}
                      setMessage={setMessage}
                      onClose={() => {
                        if (
                          message ===
                          "代理店を取引停止にしました。\n所属するユーザーを無効にしました。"
                        ) {
                          setIsModalReload(true);
                        }
                      }}
                    >
                      代理店を取引停止にします。
                      <br />
                      該当の代理店ユーザーも無効化されログインできなくなります。
                      <br />
                      よろしいですか？
                    </Alert>
                  </>
                )}
              </>
            )}
            {isValidationAlert && (
              <>
                <Alert
                  isAlert={isValidationAlert}
                  setIsAlert={setIsValidationAlert}
                  onClick={async () => {}}
                  title={"入力チェック"}
                  submitText={""}
                  cancelText={"閉じる"}
                  message={""}
                  setMessage={setMessage}
                >
                  {validationMessage}
                </Alert>
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default AgencyDetailModal;
