import "@aws-amplify/ui-react/styles.css";
import { XCircleIcon } from "@heroicons/react/24/outline";
import {
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Storage } from "aws-amplify";
import * as luxon from "luxon";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { FileWithPath, useDropzone } from "react-dropzone";
import { Document, Page, pdfjs } from "react-pdf";
import { v4 as uuidv4 } from "uuid";
import { getAgencies } from "../../api/getAgencies";
import { TermsDocsData } from "../../common/type";
import Button from "../../components/common/Button";
import { useTermsDocs } from "../../hooks/useTermsDocs";
import Alert from "../common/Alert";
import Modal from "../common/Modal";
import { useDBUserContext } from "../contexts/DBUserContext";
const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

interface CustomFile extends FileWithPath {
  previewPath?: string;
  numPages?: number;
}

type AgencyMenuItem = {
  code: string;
  name: string;
};

type Props = {
  openModal: boolean;
  termsDocs: TermsDocsData[];
  setTermsDocs: React.Dispatch<React.SetStateAction<TermsDocsData[]>>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const UploadModal = (props: Props) => {
  const {
    applicablePeriodLabels,
    applicablePeriodValues,
    getApplicablePeriodItemIndexes,
  } = useTermsDocs();
  const { dbUser } = useDBUserContext();
  const [isAlert, setIsAlert] = useState(false);
  const [isValidationAlert, setIsValidationAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isUploadAlert, setIsUploadAlert] = useState(false);
  const [uploadValidationMessage, setUploadValidationMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<CustomFile[]>([]);
  const [selectedApplicablePeriod, setSelectedApplicablePeriod] = useState("");
  const [selectedAgencyCode, setSelectedAgencyCode] = useState("");
  const [agencies, setAgencies] = useState<{ code: string; name: string }[]>(
    []
  );
  const [offset, setOffset] = useState(0);
  const onDocumentLoadSuccess = (event: any, loadedFile: CustomFile) => {
    setUploadedFiles(
      uploadedFiles.map((file) => {
        if (file === loadedFile) {
          file.numPages = event.numPages;
        }
        return file;
      })
    );
  };
  const handleUpload = async () => {
    if (!uploadedFiles) {
      return;
    }
    const termsDocs: TermsDocsData[] = [];
    await Promise.all(
      uploadedFiles.map(async (file: FileWithPath) => {
        const id = uuidv4();

        await Storage.put(`terms-docs/${id}.pdf`, file, {
          contentType: "application/pdf",
          metadata: {
            id: id,
            agency_code: selectedAgencyCode,
            applicable_period: selectedApplicablePeriod,
            post_user_id: dbUser.user_id || "",
            file_name: encodeURI(file.name || ""),
          },
        });
        const newTermDocs = {
          terms_docs_id: id,
          terms_docs_name: file.name || "",
          agency_code: selectedAgencyCode,
          agency_name:
            agencies.find((agency) => selectedAgencyCode === agency.code)
              ?.name || "",
          applicable_period: selectedApplicablePeriod,
          post_user_id: dbUser.user_id,
          post_user_name: dbUser.user_name,
          created_at: luxon.DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
          updated_at: luxon.DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
        };
        termsDocs.push(newTermDocs);
      })
    )
      .then(() => {
        props.setTermsDocs([...termsDocs, ...props.termsDocs]);
        setIsSuccess(true);
      })
      .catch((e) => {
        console.error(e);
        setIsSuccess(false);
      });
    setIsValidationAlert(true);
  };

  const onClose = () => {
    props.setOpenModal(false);
    setIsAlert(false);
    acceptedFiles.forEach((file: FileWithPath) => {
      acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    });
  };

  useEffect(() => {
    if (!props.openModal) {
      setSelectedApplicablePeriod("");
      setSelectedAgencyCode("");
      setUploadedFiles([]);
    }
  }, [props.openModal]);

  const maxSize = 500 * 1024 * 1024; // 500MB
  const maxPreviewSize = 10 * 1024 * 1024; // 10MB
  const onDrop = useCallback(
    (newFiles: CustomFile[]) => {
      const totalSize = uploadedFiles.reduce((sum, file) => sum + file.size, 0);
      const newFileSize = newFiles.reduce((sum, file) => sum + file.size, 0);
      if (totalSize + newFileSize > maxSize) {
        setIsUploadAlert(true);
        setUploadValidationMessage(
          `1度にアップロード可能なファイル容量は500MBまでです。`
        );
      } else {
        setUploadedFiles([
          ...uploadedFiles,
          ...newFiles.map((file) => {
            file.previewPath = URL.createObjectURL(file);
            return file;
          }),
        ]);
      }
    },
    [uploadedFiles, maxSize]
  );

  const getNoDataMessage = (file: any) => {
    if (file.type !== "application/pdf") {
      return "PDFではないためプレビューは表示されません。";
    } else if (file.size > maxPreviewSize) {
      return "PDFファイル容量が10MBを超えているため、プレビュー表示できません。";
    }
  };
  const fileSizeValidator = (file: any) => {
    if (file.size <= 0) {
      return {
        code: "ファイルが空",
        message: `空のファイルが選択されています。`,
      };
    }
    if (file.size > maxSize) {
      return {
        code: "ファイル容量オーバー",
        message: `アップロード可能なファイルは500MBまでです。`,
      };
    }
    return null;
  };

  const { getRootProps, getInputProps, acceptedFiles, fileRejections } =
    useDropzone({
      onDrop,
      maxSize,
      validator: fileSizeValidator,
    });

  useEffect(() => {
    if (fileRejections.length > 0) {
      fileRejections.forEach(({ file, errors }) => {
        errors.forEach((e) => setUploadValidationMessage(`${e.message}`));
        setIsUploadAlert(true);
      });
    }
  }, [fileRejections, setUploadValidationMessage, setIsUploadAlert]);

  useLayoutEffect(() => {
    const fetchBootLoader = async () => {
      const agency = await getAgencies(50, offset);
      const agencyBody = await JSON.parse(agency?.body);
      // 取引中の代理店のみリストアップ
      const enabledAgenciesData = agencyBody.data.filter((agency: any) => {
        return agency.trading_stop_date === null;
      });
      const data = enabledAgenciesData.map((item: AgencyMenuItem) => {
        return { code: item.code, name: item.name };
      });
      setAgencies([...agencies, ...data]);
      setOffset(agencyBody.next_offset);
    };
    offset !== -1 && fetchBootLoader();
  }, [agencies, offset]);

  const removeFile = (file: CustomFile) => {
    const newFiles = [...uploadedFiles];
    newFiles.splice(uploadedFiles.indexOf(file), 1);
    setUploadedFiles(newFiles);
  };

  const divRef = useRef<HTMLDivElement>(null);

  return (
    <Modal isOpen={props.openModal} height="h-[95vh]">
      <div className="flex flex-col h-full">
        <h3 className="text-2xl font-semibold leading-6 border-b border-gray-300 text-text pb-2">
          条件通知書アップロード
        </h3>
        <DialogContent>
          <div className="flex flex-col gap-5 m-5">
            <div className="flex justify-start items-center text-gray-500">
              <Typography sx={{ mr: 5, width: 80, fontSize: 16 }}>
                対象年月
              </Typography>
              <FormControl sx={{ minWidth: 150 }}>
                {
                  <Select
                    value={selectedApplicablePeriod}
                    onChange={(e) =>
                      setSelectedApplicablePeriod(e.target.value as string)
                    }
                    displayEmpty
                    className="text-left"
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      <em className="text-textSub">
                        対象年月を選択してください
                      </em>
                    </MenuItem>
                    <MenuItem
                      value={
                        applicablePeriodValues[
                          getApplicablePeriodItemIndexes() + 1
                        ]
                      }
                    >
                      {
                        applicablePeriodLabels[
                          getApplicablePeriodItemIndexes() + 1
                        ]
                      }
                    </MenuItem>
                    <MenuItem
                      value={
                        applicablePeriodValues[getApplicablePeriodItemIndexes()]
                      }
                    >
                      {applicablePeriodLabels[getApplicablePeriodItemIndexes()]}
                    </MenuItem>
                  </Select>
                }
              </FormControl>
            </div>
            <div className="flex justify-start items-center text-gray-500">
              <Typography sx={{ mr: 5, width: 80, fontSize: 16 }}>
                代理店
              </Typography>
              <FormControl sx={{ minWidth: 200 }}>
                <Select
                  value={selectedAgencyCode}
                  onChange={(e) =>
                    setSelectedAgencyCode(e.target.value as string)
                  }
                  displayEmpty
                  className="text-left"
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em className="text-textSub">代理店を選択してください</em>
                  </MenuItem>
                  {agencies.map((agency: AgencyMenuItem) => (
                    <MenuItem key={agency.code} value={agency.code}>
                      {"[" + agency.code + "] "}
                      {agency.name?.length > 20
                        ? `${agency.name.slice(0, 20)}...`
                        : agency.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="border rounded cursor-pointer">
              <div
                {...getRootProps()}
                className="flex flex-col justify-center items-center p-5 "
              >
                <input {...getInputProps()} />
                <p>
                  ファイルをここにドラッグアンドドロップするか、
                  クリックしてファイルを選択してください
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-5 m-5 justify-center items-center">
            <div className="max-h-10">
              {uploadedFiles.map((file: CustomFile, index) => (
                <div key={index}>
                  <div className="flex justify-center items-center">
                    <p>
                      {file.path} - {file.size} bytes
                    </p>
                    <div
                      className="whitespace-nowrap px-3 py-4 text-sm text-left"
                      onClick={() => removeFile(file)}
                    >
                      <XCircleIcon className="w-5 h-5 cursor-pointer" />
                    </div>
                  </div>
                  {file.type !== "application/pdf" ||
                  file.size > maxPreviewSize ? (
                    <div>{getNoDataMessage(file)}</div>
                  ) : (
                    <Document
                      file={file.previewPath}
                      noData={getNoDataMessage(file)}
                      onLoadSuccess={(e) => onDocumentLoadSuccess(e, file)}
                      className="border border-gray-300 rounded"
                    >
                      {[...Array(file.numPages)].map((_, index) => (
                        <Page
                          key={index}
                          pageNumber={index + 1}
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          width={
                            divRef.current ? divRef.current.offsetWidth : 900
                          }
                        />
                      ))}
                    </Document>
                  )}
                </div>
              ))}
            </div>
          </div>
        </DialogContent>
        <div className="mt-2 grid grid-flow-row-dense grid-cols-3 gap-60">
          <Button
            buttonType={"normal"}
            onClick={() => props.setOpenModal(false)}
          >
            閉じる
          </Button>
          <Button
            disabled={
              uploadedFiles.length === 0 ||
              selectedApplicablePeriod === "" ||
              selectedAgencyCode === "" ||
              isAlert
            }
            onClick={() => setIsAlert(true)}
          >
            アップロード
            {uploadedFiles.length > 0 && <span>({uploadedFiles.length})</span>}
          </Button>
          <div />
        </div>
        <Alert
          isAlert={isAlert}
          setIsAlert={setIsAlert}
          onClick={handleUpload}
          title="条件通知書アップロード"
          submitText="はい"
          cancelText="いいえ"
          message={message}
          setMessage={setMessage}
        >
          条件通知書をアップロードします。 よろしいですか？
        </Alert>

        <Alert
          isAlert={isUploadAlert}
          setIsAlert={setIsUploadAlert}
          onClick={async () => {}}
          title="アップロードファイルエラー"
          submitText=""
          cancelText="閉じる"
          message={message}
          setMessage={setMessage}
        >
          {uploadValidationMessage}
        </Alert>

        <Alert
          isAlert={isValidationAlert}
          setIsAlert={setIsValidationAlert}
          onClick={async () => {}}
          title={"条件通知書アップロード"}
          submitText={""}
          cancelText={"閉じる"}
          message={message}
          setMessage={setMessage}
          onClose={onClose}
        >
          {isSuccess
            ? "アップロードしました。"
            : "アップロードが失敗しました。"}
        </Alert>
      </div>
    </Modal>
  );
};

export default UploadModal;
