import { FC, useContext, useEffect, useState } from "react";
import { NewsData } from "../../common/type";
import * as luxon from "luxon";
import Alert from "../common/Alert";
import { IsAdminContext } from "../contexts/IsAdminContext";

type Props = {
  newsDataList: NewsData[];
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen: (data: NewsData) => void;
  selectedNewsData: NewsData;
};

/**
 * お知らせテーブル
 * @param props
 * @returns
 */
const NewsTable: FC<Props> = ({
  newsDataList,
  isModalOpen,
  setIsModalOpen,
  modalOpen,
  selectedNewsData,
}) => {
  const { isAdmin } = useContext(IsAdminContext);
  const [isAlert, setIsAlert] = useState(false);
  useEffect(() => {
    if (isModalOpen && selectedNewsData.id === undefined) {
      setIsAlert(true);
    }
  }, [isModalOpen, selectedNewsData]);
  return (
    <div>
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-8">
          <div className="inline-block min-w-full py-2 align-middle px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="text-gray-500 text-xs font-semibold">
                  <th scope="col" className="w-[10%] px-3 py-3.5 text-left">
                    お知らせ日
                  </th>
                  <th scope="col" className="w-[40%] px-3 py-3.5 text-left">
                    件名
                  </th>
                  <th scope="col" className="w-[20%] px-3 py-3.5 text-left">
                    代理店名
                  </th>
                  <th scope="col" className="w-[15%] px-3 py-3.5 text-left">
                    代理店コード
                  </th>
                  {isAdmin && (
                    <th scope="col" className="w-[15%] px-3 py-3.5 text-left">
                      投稿管理者
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 text-textSub">
                {newsDataList.map((data) => {
                  return (
                    <tr
                      key={data.id}
                      onClick={() => modalOpen(data)}
                      className={`${
                        !isModalOpen && "cursor-pointer hover:bg-gray-100"
                      }`}
                    >
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                        {luxon.DateTime.fromSQL(data.createdAt).toFormat(
                          "yyyy/MM/dd"
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                        {data.subject?.length > 24
                          ? `${data.subject.slice(0, 24)}...`
                          : data.subject}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                        {data.agencyName?.length > 12
                          ? `${data.agencyName.slice(0, 12)}...`
                          : data.agencyName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                        {data.agencyCode}
                      </td>
                      {isAdmin && (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                          {data.postUserName?.length > 8
                            ? `${data.postUserName.slice(0, 8)}...`
                            : data.postUserName}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
              {isAlert && (
                <Alert
                  isAlert={isAlert}
                  setIsAlert={setIsModalOpen}
                  onClick={async () => {}}
                  title={"お知らせ詳細"}
                  submitText={""}
                  cancelText={"閉じる"}
                  message={""}
                  setMessage={async () => {}}
                >
                  選択したお知らせが存在しません。
                </Alert>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsTable;
