import { getNews } from "../api/getNews";
import { PAGE_SIZE } from "../common/constants";
import { useState, useEffect, useCallback } from "react";
import { newsData } from "../common/initialData";
import { getNewsId } from "../api/getNewsId";
import { NewsData } from "../common/type";
import { getAgencies } from "../api/getAgencies";
import { postNews } from "../api/postNews";
import { useCognitoUserContext } from "../components/contexts/CognitoUserContext";
import { putNewsId } from "../api/putNewsId";
import { deleteNewsId } from "../api/deleteNewsId";
import { useLocation } from "react-router-dom";
import { useDBUserContext } from "../components/contexts/DBUserContext";

/**
 * お知らせフック
 * @returns
 */
export const useNews = () => {
  const { cognitoUser } = useCognitoUserContext();
  const { dbUser } = useDBUserContext();

  /* 登録・更新・削除結果メッセージ */
  const [message, setMessage] = useState("");

  /* 画面リロード真偽 */
  const [isReload, setIsReload] = useState(false);

  /* 画面パス取得 */
  const location = useLocation();
  const currentPath = location.pathname;
  const lastPath = currentPath.replace(/^\/admin/g, "").replace(/^\//g, "");

  /* ページング state */
  const [nowOffset, setNowOffset] = useState(0);
  const [nextOffset, setNextOffset] = useState(0);
  const [previousOffset, setPreviousOffset] = useState(0);

  /* お知らせ一覧 state */
  const [isLoading, setIsLoading] = useState(true);
  const [newsDataList, setNewsDataList] = useState([newsData]);
  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /* モーダル state */
  const defaultItem = { code: "", name: "全体" };
  const [selectedAgencyCode, setSelectedAgencyCode] = useState(
    defaultItem.code
  );
  const [agenciesList, setAgenciesList] = useState([defaultItem]);
  const [selectedNewsData, setSelectedNewsData] = useState(newsData);

  /* お知らせ登録ボタン押下 state */
  const [subjectValue, setSubjectValue] = useState(selectedNewsData.subject);
  const [contentValue, setContentValue] = useState(selectedNewsData.content);

  /* お知らせ削除ボタン押下 state */
  const [isDeleteComplete, setIsDeleteComplete] = useState(false);

  /* お知らせ一覧取得 */
  const getNewsList = useCallback(
    async (offset: number = 0) => {
      try {
        setIsLoading(true);

        let news;
        if (lastPath === "") {
          // トップ画面
          news = await getNews(PAGE_SIZE, offset, dbUser.agency_code);
        } else if (lastPath === "news") {
          // お知らせ画面
          news = await getNews(PAGE_SIZE, offset);
        }

        const newsBody = await JSON.parse(news?.body);
        setNewsDataList(
          newsBody.data.map((newsData: any) => {
            return {
              id: newsData.id,
              agencyCode: newsData.agency_code,
              agencyName: newsData.agency_name,
              subject: newsData.subject,
              content: "",
              postUserId: newsData.post_user_id,
              postUserName: newsData.post_user_name,
              createdAt: newsData.created_at,
              updatedAt: "",
            };
          })
        );
        setNowOffset(offset);
        setPreviousOffset(offset - PAGE_SIZE);
        setNextOffset(newsBody.next_offset);
      } catch (e) {
        console.error(e);
        setNewsDataList([]);
        setPreviousOffset(-1);
        setNextOffset(-1);
      } finally {
        setIsLoading(false);
        setIsReload(false);
      }
    },
    [lastPath, dbUser]
  );
  useEffect(() => {
    // ログインユーザーをDBから取得している場合に実行
    if (dbUser.user_id) getNewsList();
  }, [getNewsList, dbUser]);
  useEffect(() => {
    // モーダルが閉じたとき、かつ、お知らせ登録/更新/削除があったとき
    // お知らせ一覧リロード
    if (!isModalOpen && isReload) {
      getNewsList(nowOffset);
      setIsDeleteComplete(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, isReload, getNewsList]);

  /* モーダルオープン時にお知らせ単体取得 */
  const modalOpen = async (data: NewsData | null = null) => {
    const getNewsDetail = async (newsId: number) => {
      try {
        setIsModalLoading(true);

        const news = await getNewsId(newsId);
        const newsBody = await JSON.parse(news?.body);
        setSelectedNewsData({
          id: newsBody.data.id,
          agencyCode: newsBody.data.agency_code,
          agencyName: newsBody.data.agency_name,
          subject: newsBody.data.subject,
          content: newsBody.data.content,
          postUserId: newsBody.data.post_user_id,
          postUserName: "",
          createdAt: "",
          updatedAt: "",
        });
        if (newsBody.data.id === undefined) {
          setIsReload(true);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsModalLoading(false);
      }
    };
    setSelectedNewsData(newsData);
    if (data) {
      getNewsDetail(data.id);
    } else {
      // お知らせ単体開く⇒F5更新⇒新規登録でloadingが継続するのでその回避
      setIsModalLoading(false);
    }
    setIsModalOpen(!isModalOpen);
  };

  /* お知らせ登録ボタン押下 */
  const onRegisterNewsClick = async () => {
    try {
      const res = await postNews({
        agency_code: selectedAgencyCode,
        subject: subjectValue,
        content: contentValue,
        post_user_id: cognitoUser.attributes.email,
      });
      if (res.statusCode === 200) {
        setMessage("お知らせを登録しました。");
        setSelectedAgencyCode(defaultItem.code);
        setSubjectValue("");
        setContentValue("");

        // 画面リロード
        setIsReload(true);
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  /* お知らせ更新ボタン押下 */
  const onUpdateNewsClick = async () => {
    try {
      const res = await putNewsId(selectedNewsData.id, {
        agency_code: selectedAgencyCode,
        subject: subjectValue,
        content: contentValue,
        post_user_id: cognitoUser.attributes.email,
      });
      if (res.statusCode === 200) {
        setMessage("お知らせを更新しました。");
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }

    // 画面リロード
    setIsReload(true);
  };

  /* お知らせ削除ボタン押下 */
  const onDeleteNewsClick = async () => {
    try {
      const res = await deleteNewsId(selectedNewsData.id);
      if (res.statusCode === 200) {
        setMessage("お知らせを削除しました。");
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }

    // 画面リロード
    setIsReload(true);
    // 削除完了フラグを立てる
    setIsDeleteComplete(true);
  };

  /* モーダルオープン時 */
  const getAgenciesList = useCallback(async () => {
    if (lastPath === "") {
      // トップ画面
      return;
    }
    let nextOffset = 0;
    let allAgencies = [defaultItem];
    while (nextOffset > -1) {
      const agencies = await getAgencies(50, nextOffset);
      const agenciesBody = await JSON.parse(agencies?.body);
      // 取引中の代理店のみリストアップ
      const enabledAgenciesData = agenciesBody.data.filter((agency: any) => {
        return agency.trading_stop_date === null;
      });
      allAgencies.push(
        ...enabledAgenciesData.map((agency: any) => {
          return {
            code: agency.code,
            name: agency.name,
          };
        })
      );
      nextOffset = parseInt(agenciesBody.next_offset);
    }
    setAgenciesList(allAgencies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAgenciesList();
  }, [getAgenciesList]);

  /* リスト内の代理店が選択されたとき */
  useEffect(() => {
    if (selectedNewsData.agencyCode) {
      setSelectedAgencyCode(selectedNewsData.agencyCode);
    } else {
      setSelectedAgencyCode(defaultItem.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNewsData]);

  return {
    nextOffset,
    previousOffset,
    isLoading,
    getNewsList,
    newsDataList,
    isModalOpen,
    setIsModalOpen,
    isModalLoading,
    modalOpen,
    selectedNewsData,
    selectedAgencyCode,
    setSelectedAgencyCode,
    agenciesList,
    onRegisterNewsClick,
    onUpdateNewsClick,
    onDeleteNewsClick,
    isDeleteComplete,
    subjectValue,
    setSubjectValue,
    contentValue,
    setContentValue,
    message,
    setMessage,
  };
};
