import { FC } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideMenu from "./SideMenu";
import { Outlet } from "react-router-dom";

/**
 * 共通レイアウト
 * @returns
 */
const Layout: FC = () => {
  return (
    <div className="min-w-[1080px] text-text w-[calc(100vw_-_20px)]">
      <Header />
      <div className="flex">
        <SideMenu />
        <main className="pl-[10rem] w-4/5 mx-auto">
          <Outlet />
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
