import { useState, useEffect, useCallback, useContext } from "react";
import { PAGE_SIZE } from "../common/constants";
import { IsAdminContext } from "../components/contexts/IsAdminContext";
import { getReleaseFiles } from "../api/getReleaseFiles";
import { releaseFilesData } from "../common/initialData";
import {
  ReleaseFilesData,
  SearchItemsType,
  SearchSelect,
} from "../common/type";
import { Storage } from "aws-amplify";
import { saveAs } from "file-saver";
import Button from "../components/common/Button";
import LoadingSpinner from "../components/common/LoadingSpinner";
import SearchModule from "../components/common/SearchModule";
import {
  ArchiveBoxArrowDownIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Loader } from "@aws-amplify/ui-react";
import RemoveModal from "../components/releaseFiles/RemoveModal";
import Page from "../components/common/Pagenation";
import * as luxon from "luxon";
import Alert from "../components/common/Alert";
import UploadModal from "../components/releaseFiles/UploadModal";
import { useDBUserContext } from "../components/contexts/DBUserContext";
import { fileCategoryDict } from "../hooks/useReleaseFiles";
import { ReleaseFilesIcon } from "../components/Icon";

/**
 * 公開ファイル管理画面
 * @returns
 */

const ReleaseFiles = () => {
  const { isAdmin } = useContext(IsAdminContext);
  const { dbUser } = useDBUserContext();

  const [nextOffset, setNextOffset] = useState(0);
  const [previousOffset, setPreviousOffset] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [releaseFilesDataList, setReleaseFilesDataList] = useState([
    releaseFilesData,
  ]);

  const [searchItems] = useState<SearchItemsType[]>([
    {
      value: "selectName",
      label: "代理店名",
      method: "word",
      type: "string",
    },
    {
      value: "selectCode",
      label: "代理店コード",
      method: "word",
      type: "string",
    },
  ]);
  const [selectedItem, setSelectedItem] = useState<SearchSelect>(
    searchItems[0].value
  );
  const [inputKeyword, setInputKeyword] = useState("");

  const [isAlert, setIsAlert] = useState(false);
  const [selectedRemoveReleaseFiles, setSelectedRemoveReleaseFiles] =
    useState<ReleaseFilesData>(releaseFilesData);

  const [isFileCheckAlert, setIsFileCheckAlert] = useState(false);
  const [fileCheckMessage, setFileCheckMessage] = useState("");

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [downloadingFileId, setDownloadingFileId] = useState("");

  const handleDownloadFile = async (
    release_file_id: string,
    file_name: string
  ) => {
    setDownloadingFileId(release_file_id);
    try {
      const extension = file_name.split(".").pop();
      const res = await Storage.get(
        `release-files/${release_file_id}.${extension}`,
        {
          download: true,
          progressCallback(progress) {
            progress.loaded >= progress.total && setDownloadingFileId("");
          },
        }
      );
      saveAs(res.Body as Blob, file_name);
    } catch (e) {
      console.error(e);
      setFileCheckMessage("ファイルが存在しません。");
      setIsFileCheckAlert(true);
      setDownloadingFileId("");
    }
  };

  const getReleaseFilesList = useCallback(
    async (
      offset: number = 0,
      searchSelect?: SearchSelect,
      keyword?: string,
      agencyCode?: string
    ) => {
      try {
        setIsLoading(true);
        const releaseFiles = await getReleaseFiles(
          PAGE_SIZE,
          offset,
          searchSelect,
          keyword,
          agencyCode
        );
        const releaseFilesBody = await JSON.parse(releaseFiles?.body);
        if (releaseFilesBody.data && Array.isArray(releaseFilesBody.data)) {
          let dataList = releaseFilesBody.data.map((filesData: any) => {
            return {
              release_file_id: filesData.release_file_id,
              agency_code: filesData.agency_code,
              agency_name: filesData.agency_name,
              file_category: filesData.file_category,
              file_name: filesData.file_name,
              comment: filesData.comment,
              post_user_id: filesData.post_user_id,
              post_user_name: filesData.post_user_name,
              created_at: filesData.created_at,
              updated_at: "",
            };
          });
          setReleaseFilesDataList(dataList);
        }
        setPreviousOffset(offset - PAGE_SIZE);
        setNextOffset(releaseFilesBody.next_offset);
      } catch (e) {
        console.error(e);
        setReleaseFilesDataList([]);
        setPreviousOffset(-1);
        setNextOffset(-1);
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dbUser.agency_code]
  );

  useEffect(() => {
    if (dbUser.user_id && isAdmin) {
      getReleaseFilesList(0, selectedItem, "", "");
    } else if (dbUser.user_id && !isAdmin) {
      getReleaseFilesList(0, selectedItem, "", dbUser.agency_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser]);

  return (
    <>
      <div className="flex flex-col pt-24">
        <div className="flex items-center pb-10">
          <ReleaseFilesIcon />
          <h2 className="text-2xl font-semibold leading-6 ml-2">
            公開ファイル管理
          </h2>
          {isAdmin && (
            <div className="flex flex-grow justify-end items-center">
              <SearchModule
                onClick={() => {
                  getReleaseFilesList(0, selectedItem, inputKeyword);
                }}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                inputKeyword={inputKeyword}
                setInputKeyword={setInputKeyword}
                searchItems={searchItems}
              />
              {dbUser.user_id && dbUser.permissions !== 2 && (
                <Button
                  onClick={() => setOpenUploadModal(true)}
                  disabled={openUploadModal === true}
                >
                  アップロード
                </Button>
              )}
            </div>
          )}
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="flow-root">
              <div className="-my-2 -mx-8">
                <div className="inline-block min-w-full py-2 align-middle px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr className="text-gray-500 text-xs font-semibold">
                        <th
                          scope="col"
                          className="w-[10%] px-3 py-3.5 text-left"
                        >
                          アップ日
                        </th>
                        <th
                          scope="col"
                          className="w-[15%] px-3 py-3.5 text-left"
                        >
                          ファイル名
                        </th>
                        <th
                          scope="col"
                          className="w-[15%] px-3 py-3.5 text-left"
                        >
                          ファイル種別
                        </th>
                        <th
                          scope="col"
                          className="w-[12%] px-3 py-3.5 text-left"
                        >
                          代理店名
                        </th>
                        <th
                          scope="col"
                          className="w-[13%] px-3 py-3.5 text-left"
                        >
                          代理店コード
                        </th>
                        <th
                          scope="col"
                          className="w-[25%] px-3 py-3.5 text-left"
                        >
                          コメント
                        </th>
                        <th
                          scope="col"
                          className="w-[5%] px-3 py-3.5 text-left"
                        >
                          &nbsp;
                        </th>
                        <th
                          scope="col"
                          className="w-[5%] px-3 py-3.5 text-left"
                        >
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {releaseFilesDataList.map((data, index) => {
                        return (
                          <tr key={index} onClick={() => {}}>
                            <td className=" px-3 py-2 text-sm text-left">
                              {luxon.DateTime.fromSQL(data.created_at).toFormat(
                                "yyyy/MM/dd"
                              )}
                            </td>
                            <td className="px-3 py-2 text-sm text-left break-all">
                              {data.file_name?.length > 20
                                ? `${data.file_name.slice(0, 20)}...`
                                : data.file_name}
                            </td>
                            <td className="px-3 py-2 text-sm text-left">
                              {fileCategoryDict[Number(data.file_category)]}
                            </td>
                            <td className="px-3 py-2 text-sm text-left">
                              {data.agency_name?.length > 14
                                ? `${data.agency_name.slice(0, 14)}...`
                                : data.agency_name}
                            </td>
                            <td className="px-3 py-2 text-sm text-left">
                              {data.agency_code}
                            </td>
                            <td className="px-3 py-2 text-sm text-left break-all">
                              {data.comment?.length > 60
                                ? `${data.comment.slice(0, 60)}...`
                                : data.comment}
                            </td>
                            <td className="px-3 py-2 text-sm text-left">
                              {downloadingFileId === data.release_file_id ? (
                                <Loader />
                              ) : downloadingFileId ? (
                                <div className="text-gray-400 cursor-not-allowed w-5 h-5">
                                  <ArchiveBoxArrowDownIcon title="ダウンロード" />
                                </div>
                              ) : (
                                <ArchiveBoxArrowDownIcon
                                  title="ダウンロード"
                                  className="w-5 h-5 cursor-pointer"
                                  onClick={() =>
                                    handleDownloadFile(
                                      data.release_file_id,
                                      data.file_name
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td className="px-3 py-2 text-sm text-left">
                              {isAdmin && (
                                <>
                                  {dbUser.user_id &&
                                    dbUser.permissions !== 2 && (
                                      <XCircleIcon
                                        title="削除"
                                        className={`w-5 h-5 ${
                                          downloadingFileId ===
                                          data.release_file_id
                                            ? "text-gray-400 cursor-not-allowed"
                                            : "cursor-pointer"
                                        }`}
                                        onClick={() => {
                                          if (
                                            downloadingFileId !==
                                            data.release_file_id
                                          ) {
                                            setSelectedRemoveReleaseFiles(data);
                                            setIsAlert(true);
                                          }
                                        }}
                                      />
                                    )}
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Page
              onClick={getReleaseFilesList}
              previousOffset={previousOffset}
              nextOffset={nextOffset}
              selectedItem={selectedItem}
              keyword={inputKeyword}
              agencyCode={dbUser.agency_code}
            />
          </>
        )}
      </div>
      {/* アップロードモーダル */}
      <UploadModal
        openModal={openUploadModal}
        releaseFilesDataList={releaseFilesDataList}
        setReleaseFilesDataList={setReleaseFilesDataList}
        setOpenModal={setOpenUploadModal}
      />
      {/* 削除確認モーダル */}
      <RemoveModal
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        releaseFilesDataList={releaseFilesDataList}
        setReleaseFilesDataList={setReleaseFilesDataList}
        selectedRemoveReleaseFiles={selectedRemoveReleaseFiles}
      />
      {isFileCheckAlert && (
        <>
          <Alert
            isAlert={isFileCheckAlert}
            setIsAlert={setIsFileCheckAlert}
            onClick={async () => {}}
            title={"ダウンロードエラー"}
            submitText={""}
            cancelText={"閉じる"}
            message={""}
            setMessage={async () => {}}
          >
            {fileCheckMessage}
          </Alert>
        </>
      )}
    </>
  );
};

export default ReleaseFiles;
