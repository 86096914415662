import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { UserData } from "../../common/type";
import { userData } from "../../common/initialData";

export const DBUserContext = createContext(
  {} as {
    dbUser: UserData;
    setDBUser: Dispatch<SetStateAction<UserData>>;
  }
);

export const useDBUserContext = () => {
  return useContext(DBUserContext);
};

type Props = {
  children: ReactNode;
};

/**
 * データベース内のログインユーザー情報
 * @param props
 * @returns
 */
export const DBUserProvider: FC<Props> = ({ children }) => {
  const [dbUser, setDBUser] = useState<UserData>(userData);
  return (
    <DBUserContext.Provider value={{ dbUser, setDBUser }}>
      {children}
    </DBUserContext.Provider>
  );
};
