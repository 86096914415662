import React, { FC, useEffect, useState } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Storage } from "aws-amplify";
import { saveAs } from "file-saver";
import Alert from "../common/Alert";
import * as luxon from "luxon";
import { LinearProgress } from "@mui/material";

type Props = {
  allDLModalTitle: string;
  isAllDLModalOpen: boolean;
  setIsAllDLOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * 通知書一括ダウンロード、モーダル
 * @param props
 * @returns
 */
const NotificationDocsAllDLModal: FC<Props> = ({
  allDLModalTitle,
  isAllDLModalOpen,
  setIsAllDLOpen,
}) => {
  const [selectedYearMonth, setSelectedYearMonth] = useState("");

  const [isAlert, setIsAlert] = useState(false);

  // 対象年月リスト
  const [yearMonthLabels, setYearMonthLabels] = useState<string[]>([]);
  const [yearMonthValues, setYearMonthValues] = useState<string[]>([]);

  const [downloadingFileType, setDownloadingFileType] = useState("");
  const [downloadProgress, setDownloadProgress] = useState(0);

  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const closeModal = () => {
    setIsAllDLOpen(!isAllDLModalOpen);
  };

  const convertDateStr = (dateStr: string) => {
    return dateStr.replace("年", "").replace("月", "");
  };

  const handleDownloadFile = async (type: string) => {
    setDownloadingFileType(type);
    setDownloadProgress(0);
    try {
      const resultDate = convertDateStr(selectedYearMonth);
      const res = await Storage.get(
        `notification-docs/${type}/${resultDate}/${resultDate}.zip`,
        {
          download: true,
          cacheControl: "no-cache",
          progressCallback(progress) {
            setDownloadProgress(
              Math.round((progress.loaded / progress.total) * 100)
            );
          },
        }
      );
      setAlertTitle("通知書一括ダウンロード");
      setAlertMessage("ダウンロードが完了しました。");
      setIsAlert(true);
      saveAs(res.Body as Blob, `${selectedYearMonth}${type}.zip`);
    } catch (e) {
      console.error(e);
      setAlertTitle("通知書一括ダウンロードエラー");
      setAlertMessage("ファイルが存在しません。");
      setIsAlert(true);
    } finally {
      setDownloadingFileType("");
    }
  };

  //対象年月リスト作成
  useEffect(() => {
    const today = luxon.DateTime.now();
    const yearMonthList = Array.from({ length: 6 }, (_, i) =>
      today.minus({ months: i + 1 })
    );
    setYearMonthLabels(
      yearMonthList.map(
        (yearMonth: luxon.DateTime) => `${yearMonth.year}年${yearMonth.month}月`
      )
    );
    setYearMonthValues(
      yearMonthList.map(
        (yearMonth: luxon.DateTime) =>
          `${yearMonth.year}${yearMonth.month.toString().padStart(2, "0")}`
      )
    );
  }, []);

  return (
    <Modal isOpen={isAllDLModalOpen}>
      <h3 className="flex justify-between text-xl font-semibold leading-6 border-b border-gray-300 text-text pb-2">
        {allDLModalTitle}
      </h3>
      <div className="mt-8 flex items-center justify-center w-full">
        <FormControl sx={{ width: "100%" }}>
          <Select
            value={selectedYearMonth}
            onChange={(e) => setSelectedYearMonth(e.target.value)}
            displayEmpty
            disabled={downloadingFileType !== ""}
            className="text-left"
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              <em className="text-textSub">
                通知書の対象年月を選択してください
              </em>
            </MenuItem>
            {yearMonthLabels.map((yearMonth: string, i: number) => (
              <MenuItem key={i} value={yearMonthValues[i]}>
                {yearMonth}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {downloadingFileType && (
        <div className="mt-4">
          <LinearProgress variant="determinate" value={downloadProgress} />
        </div>
      )}
      <div className="mt-8 grid grid-flow-row-dense grid-cols-3 gap-4">
        <Button
          onClick={() => closeModal()}
          disabled={!isAllDLModalOpen || downloadingFileType !== ""}
          buttonType={"normal"}
        >
          閉じる
        </Button>
        <Button
          onClick={() => handleDownloadFile("pdf")}
          disabled={downloadingFileType !== "" || selectedYearMonth === ""}
          isLoading={downloadingFileType === "pdf"}
        >
          PDFダウンロード
        </Button>
        <Button
          onClick={() => handleDownloadFile("csv")}
          disabled={downloadingFileType !== "" || selectedYearMonth === ""}
          isLoading={downloadingFileType === "csv"}
        >
          CSVダウンロード
        </Button>
      </div>
      <Alert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        onClick={async () => {}}
        title={alertTitle}
        submitText={""}
        cancelText={"閉じる"}
        message={""}
        setMessage={async () => {}}
      >
        {alertMessage}
      </Alert>
    </Modal>
  );
};

export default NotificationDocsAllDLModal;
