import { FC, useState } from "react";
import { ArchiveBoxArrowDownIcon } from "@heroicons/react/24/outline";
import { Storage } from "aws-amplify";
import { saveAs } from "file-saver";
import { Loader } from "@aws-amplify/ui-react";

type Props = {
  errorFileNames: string[];
  errorFilePath: string;
  downloadingFileName: string;
  setDownloadingFileName: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * エラーリストテーブル
 * @param props
 * @returns
 */
const ErrorListTable: FC<Props> = ({
  errorFileNames,
  errorFilePath,
  downloadingFileName,
  setDownloadingFileName,
}) => {
  const handleDownloadFile = async (errorFileName: string) => {
    setDownloadingFileName(errorFileName);
    try {
      const res = await Storage.get(`${errorFilePath}${errorFileName}`, {
        download: true,
        progressCallback(progress) {
          progress.loaded >= progress.total && setDownloadingFileName("");
        },
      });
      saveAs(res.Body as Blob, errorFileName);
    } catch (e) {
      console.error(e);
      setDownloadingFileName("");
    }
  };
  return (
    <div>
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-8">
          <div className="inline-block min-w-full py-2 align-middle px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="text-gray-500 text-base font-semibold">
                  <th scope="col" className="w-[80%] px-3 py-3.5 text-left">
                    ファイル名
                  </th>
                  <th
                    scope="col"
                    className="w-[20%] px-3 py-3.5 text-left"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 text-text">
                {errorFileNames.map((errorFileName, i) => {
                  return (
                    <tr key={i}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                        {errorFileName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                        {downloadingFileName === errorFileName ? (
                          <Loader />
                        ) : downloadingFileName ? (
                          <div className="text-gray-400 cursor-not-allowed w-5 h-5">
                            <ArchiveBoxArrowDownIcon title="ダウンロード" />
                          </div>
                        ) : (
                          <ArchiveBoxArrowDownIcon
                            title="ダウンロード"
                            className="w-5 h-5 cursor-pointer"
                            onClick={() => handleDownloadFile(errorFileName)}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorListTable;
