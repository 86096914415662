import { Amplify, Auth } from "aws-amplify";
import { API_NAME } from "../common/constants";

/**
 * Amplify設定フック
 * @returns
 */
export const useAmplifyConfigure = () => {
  // 代理店ユーザー用
  const setUserConfigure = () => {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_REGION,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      },

      Storage: {
        AWSS3: {
          bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          region: "ap-northeast-1",
        },
      },
      API: {
        endpoints: [
          {
            name: API_NAME,
            endpoint: process.env.REACT_APP_API_GATEWAY_ENDPOINT,
            custom_header: async () => {
              return {
                "Content-Type": "application/json",
                Authorization: `Bearer ${(await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken()}`,
              };
            },
          },
        ],
      },
    });
  };

  // 管理ユーザー用
  const setAdminConfigure = () => {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_REGION,
        identityPoolId: process.env.REACT_APP_ADMIN_IDENTITY_POOL_ID,
        userPoolId: process.env.REACT_APP_ADMIN_USER_POOL_ID,
        userPoolWebClientId:
          process.env.REACT_APP_ADMIN_USER_POOL_WEB_CLIENT_ID,
      },
      Storage: {
        AWSS3: {
          bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          region: "ap-northeast-1",
        },
      },
      API: {
        endpoints: [
          {
            name: API_NAME,
            endpoint: process.env.REACT_APP_API_GATEWAY_ENDPOINT,
            custom_header: async () => {
              return {
                "Content-Type": "application/json",
                Authorization: `Bearer ${(await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken()}`,
              };
            },
          },
        ],
      },
    });
  };

  return { setUserConfigure, setAdminConfigure };
};
