import { useState, useContext, FC } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { IsAdminContext } from "./contexts/IsAdminContext";
import { Link } from "react-router-dom";
import { useCognitoUserContext } from "../components/contexts/CognitoUserContext";
import { GRANDATA_ICON192 } from "../common/constants";
import {
  ArrowRightOnRectangleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";

/**
 * ヘッダー
 * @returns
 */
const Header: FC = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const { isAdmin } = useContext(IsAdminContext);
  const { cognitoUser } = useCognitoUserContext();
  let topPath = "/";
  if (isAdmin) {
    topPath = "/admin";
  }

  const [isSignOut, setIsSignOut] = useState(false);
  const handleSignOut = () => {
    setIsSignOut(true);
    signOut();
  };

  const label = process.env.REACT_APP_TITLE_LABEL;

  return (
    <header className="z-10 fixed top-0 left-0 w-screen bg-main">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-4 px-8">
        <div className="flex">
          <Link
            to={topPath}
            className="flex -m-1.5 p-1.5 tracking-widest items-center"
          >
            <img
              alt="grandata"
              src={GRANDATA_ICON192}
              height="4.5%"
              width="4.5%"
            />
            <h1 className="ml-2 text-3xl font-bold tracking-widest">PAC3</h1>
            {label !== "" ? (
              <h1 className="ml-2 text-3xl font-bold tracking-widest">
                {label}
              </h1>
            ) : null}
          </Link>
        </div>
        <div className="flex flex-1 justify-end">
          <div className="my-auto mr-8">
            <UserCircleIcon className="inline w-6 mr-2 text-gray-400" />
            <span className="text-xs">{cognitoUser?.attributes?.email}</span>
          </div>
          <button disabled={isSignOut}>
            <ArrowRightOnRectangleIcon
              title="ログアウト"
              className="w-8 p-1 rounded-full text-gray-400 hover:bg-gray-400 hover:text-white"
              onClick={handleSignOut}
            />
          </button>
        </div>
      </nav>
    </header>
  );
};

export default Header;
