import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

/**
 * お知らせ削除APIコール
 * @param data
 * @returns
 */
export const deleteNewsId = async (newsId: number) => {
  const path = `/news/${encodeURIComponent(String(newsId))}`;
  const params = {
    data: {},
  };
  let result: any;
  await API.del(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
