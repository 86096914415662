import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const getNotificationDocs = async (
  limit = 50,
  offset = 0,
  paymentCode?: string | undefined,
  searchSelect?: string,
  keyword?: string
) => {
  let path = `/notification_docs?limit=${limit}&offset=${offset}`;
  if (paymentCode !== undefined) {
    path += `&payment_code=${paymentCode}`;
  }
  if (searchSelect === "selectName") {
    path += `&agency_name=${keyword}`;
  } else if (searchSelect === "selectCode") {
    path += `&agency_code=${keyword}`;
  } else if (searchSelect === "selectYearMonth") {
    path += `&year_month=${keyword?.replaceAll("/", "")}`;
  } else if (searchSelect === "selectFixedAmount") {
    if (keyword !== undefined) {
      if (keyword.split(":")[0])
        path += `&fixed_amount_start=${keyword.split(":")[0]}`;
      if (keyword.split(":")[1])
        path += `&fixed_amount_end=${keyword.split(":")[1]}`;
    }
  }
  const params = {};
  let result: any;
  await API.get(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
