import NewsTable from "../components/news/NewsTable";
import LoadingSpinner from "../components/common/LoadingSpinner";
import Page from "../components/common/Pagenation";
import NewsBrowseModal from "../components/news/NewsBrowseModal";
import { useNews } from "../hooks/useNews";
import { NewsIcon } from "../components/Icon";

/**
 * トップ画面
 * @returns
 */
const Top = () => {
  const {
    nextOffset,
    previousOffset,
    isLoading,
    getNewsList,
    newsDataList,
    isModalOpen,
    setIsModalOpen,
    isModalLoading,
    modalOpen,
    selectedNewsData,
  } = useNews();

  return (
    <>
      <div className="flex items-center pt-24">
        <NewsIcon />
        <div className="flex-auto ml-2">
          <h2 className="text-2xl font-semibold leading-6 py-4">お知らせ</h2>
        </div>
      </div>
      {isLoading ? (
        <div className="mt-20">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <NewsBrowseModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            selectedNewsData={selectedNewsData}
            isModalLoading={isModalLoading}
          />
          <NewsTable
            newsDataList={newsDataList}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            modalOpen={modalOpen}
            selectedNewsData={selectedNewsData}
          />
          <Page
            onClick={getNewsList}
            previousOffset={previousOffset}
            nextOffset={nextOffset}
          />
        </>
      )}
    </>
  );
};

export default Top;
