import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const putAgencyCode = async (
  agencyCode: string,
  newAgencyCode: string,
  agencyName: string,
  registrationNumber: string,
  tradingStopDate?: string | null
) => {
  let putData;
  const path = `/agencies/${encodeURIComponent(agencyCode)}`;
  if (tradingStopDate === undefined) {
    // 代理店コード、代理店名、登録番号、修正
    putData = {
      new_agency_code: newAgencyCode,
      agency_name: agencyName,
      registration_number: registrationNumber,
    };
  } else {
    // 取引再開、停止
    putData = {
      new_agency_code: newAgencyCode,
      agency_name: agencyName,
      registration_number: registrationNumber,
      trading_stop_date: tradingStopDate,
    };
  }
  let result: any;
  const params = { body: putData };
  await API.put(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
