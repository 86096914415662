import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";
import { SearchSelect } from "../common/type";

export const getTermsDocs = async (
  limit: number = 50,
  offset: number = 0,
  searchType?: SearchSelect,
  searchText?: string
) => {
  try {
    let path = "";
    if (searchType === "selectName") {
      path = `/terms_docs?limit=${limit}&offset=${offset}&agency_name=${searchText}`;
    } else if (searchType === "selectCode") {
      path = `/terms_docs?limit=${limit}&offset=${offset}&agency_code=${searchText}`;
    } else {
      path = `/terms_docs?limit=${limit}&offset=${offset}`;
    }
    const params = {};
    return await API.get(API_NAME, path, params);
  } catch (e) {
    console.error(e);
    throw e;
  }
};
