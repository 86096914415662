import * as luxon from "luxon";

export const useNotificationDocsImport = () => {
  const today = luxon.DateTime.now();
  const previousMonth = today.minus({ months: 1 });
  const previousPreviousMonth = today.minus({ months: 2 });

  const yearMonthLabels = [
    `${previousMonth.year}年${previousMonth.month}月`,
    `${previousPreviousMonth.year}年${previousPreviousMonth.month}月`,
  ];
  const yearMonthValues = [
    `${previousMonth.year}${previousMonth.month.toString().padStart(2, "0")}`,
    `${previousPreviousMonth.year}${previousPreviousMonth.month
      .toString()
      .padStart(2, "0")}`,
  ];

  return {
    yearMonthLabels,
    yearMonthValues,
  };
};
