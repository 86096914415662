import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const putUser = async (
  userId: string,
  code: string,
  enabled: boolean,
  userName?: string,
  permissions?: number
) => {
  const path = `/users/${encodeURIComponent(userId)}`;
  let putData;
  if (userName) {
    putData = {
      user_name: userName,
      payment_code: code,
      enabled: enabled,
      permissions: permissions,
    };
  } else {
    putData = { agency_code: code, enabled: enabled };
  }
  const params = { body: putData };
  let result: any;
  await API.put(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
