import { getAgencies } from "../api/getAgencies";
import { PAGE_SIZE } from "../common/constants";
import { useState, useEffect, useCallback, useContext } from "react";
import { agencyData, userData } from "../common/initialData";
import {
  AgencyData,
  SearchItemsType,
  SearchSelect,
  UserData,
} from "../common/type";
import { getUsers } from "../api/getUsers";
import { postUser } from "../api/postUser";
import { putUser } from "../api/putUser";
import { getAgencyCode } from "../api/getAgencyCode";
import { putAgencyCode } from "../api/putAgencyCode";
import { postAgency } from "../api/postAgency";
import { useDBUserContext } from "../components/contexts/DBUserContext";
import { IsAdminContext } from "../components/contexts/IsAdminContext";
import { putUserResetPassword } from "../api/putUserResetPassword";

/**
 * 代理店フック
 * @returns
 */
export const useAgency = () => {
  const { isAdmin } = useContext(IsAdminContext);

  // 代理店一覧リロード判定
  const [isReload, setIsReload] = useState(false);

  // 代理店詳細モーダルリロード判定
  const [isModalReload, setIsModalReload] = useState(false);

  // ユーザー登録モーダルリロード判定
  const [isUserModalReload, setIsUserModalReload] = useState(false);

  const [nowOffset, setNowOffset] = useState(0);
  const [nextOffset, setNextOffset] = useState(0);
  const [previousOffset, setPreviousOffset] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [agencyDataList, setAgencyDataList] = useState([agencyData]);

  const [isModalLoading, setIsModalLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isUserModalLoading, setIsUserModalLoading] = useState(true);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const [usersListData, setUsersListData] = useState([userData]);

  /* 登録・更新・削除結果メッセージ */
  const [message, setMessage] = useState("");

  /* 検索機能 */
  const [searchItems] = useState<SearchItemsType[]>([
    {
      value: "selectName",
      label: "代理店名",
      method: "word",
      type: "string",
    },
    {
      value: "selectCode",
      label: "代理店コード",
      method: "word",
      type: "string",
    },
  ]);
  const [selectedItem, setSelectedItem] = useState<SearchSelect>(
    searchItems[0].value
  );
  const [inputKeyword, setInputKeyword] = useState("");

  /* 代理店一覧取得 */
  const getAgencyList = useCallback(
    async (offset: number = 0, searchSelect?: string, keyword?: string) => {
      try {
        setIsLoading(true);
        const agency = await getAgencies(
          PAGE_SIZE,
          offset,
          searchSelect,
          keyword
        );
        const agencyBody = await JSON.parse(agency?.body);
        setAgencyDataList(
          agencyBody.data.map((agencyData: any) => {
            return {
              code: agencyData.code,
              payment_code: agencyData.payment_code,
              name: agencyData.name,
              registration_number: agencyData.registration_number,
              trading_stop_date: agencyData.trading_stop_date,
              createdAt: agencyData.created_at,
              updatedAt: "",
            };
          })
        );
        setNowOffset(offset);
        setPreviousOffset(offset - PAGE_SIZE);
        setNextOffset(agencyBody.next_offset);
      } catch (e) {
        console.error(e);
        setAgencyDataList([]);
        setPreviousOffset(-1);
        setNextOffset(-1);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const { dbUser } = useDBUserContext();

  useEffect(() => {
    if (dbUser.user_id && !isAdmin) {
      getAgency(dbUser.agency_code);
    }
    if (dbUser.user_id && isAdmin) {
      getAgencyList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser]);

  /* ユーザーが所属している代理店取得(ユーザー画面表示用) */
  const getAgency = useCallback(async (agency_code: string) => {
    try {
      setIsLoading(true);
      const agency = await getAgencyCode(agency_code);
      const agencyBody = await JSON.parse(agency);
      setAgencyDataList([
        {
          code: agencyBody.data.code,
          payment_code: agencyBody.data.payment_code,
          name: agencyBody.data.name,
          registration_number: agencyBody.data.registration_number,
          trading_stop_date: agencyBody.data.trading_stop_date,
          createdAt: agencyBody.data.created_at,
          updatedAt: "",
        },
      ]);
    } catch (e) {
      console.error(e);
      setAgencyDataList([agencyData]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  /* ユーザー追加ボタン押下、モーダル */
  const [selectedAgencyData, setSelectedAgencyData] = useState(agencyData);
  const [userEmailValue, setUserEmailValue] = useState("");

  /* 代理店ユーザーモーダルオープン時に代理店ユーザー一覧取得 */
  const userModalOpen = async (data: AgencyData | null = null) => {
    if (data) {
      getUserIdDetail(data.code);
    }
    setIsUserModalOpen(!isUserModalOpen);
  };

  /* ユーザー一覧取得 */
  const getUserIdDetail = async (agencyCode: string) => {
    try {
      setIsUserModalLoading(true);
      setSelectedAgencyData({
        code: agencyCode,
        payment_code: "",
        name: "",
        registration_number: "",
        trading_stop_date: "",
        createdAt: "",
        updatedAt: "",
      });
      setUsersListData([]);
      const users = await getUsers(agencyCode, "agency");
      const usersBody = await JSON.parse(users);
      setUsersListData(
        usersBody.map((usersData: UserData) => {
          return {
            user_id: usersData.user_id,
            user_name: "",
            agency_code: usersData.agency_code,
            payment_code: "",
            enabled: usersData.enabled === 1 ? true : false,
            invalid_datetime: usersData.invalid_datetime,
            createdAt: usersData.created_at,
            updatedAt: "",
            status: usersData.status,
          };
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsUserModalLoading(false);
    }
  };

  /* ユーザー追加モーダルで、登録ボタン押下 */
  const onRegisterUserClick = async () => {
    try {
      const res = await postUser(userEmailValue, selectedAgencyData.code);
      if (res.statusCode === 200) {
        setMessage("代理店ユーザーを登録しました。");
        setIsUserModalReload(true);
        setUserEmailValue("");
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  /* ユーザー追加モーダルで、有効もしくは無効ボタン押下 */
  const onUpdateUserClick = async (selectedEmail: string, enabled: boolean) => {
    try {
      const res = await putUser(
        selectedEmail,
        selectedAgencyData.code,
        enabled
      );
      if (res.statusCode === 200) {
        if (enabled) {
          setMessage("選択した代理店ユーザーを有効にしました。");
        } else {
          setMessage("選択した代理店ユーザーを無効にしました。");
        }
        setIsUserModalReload(true);
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  const resetPassword = async (selectedEmail: string) => {
    try {
      const res = await putUserResetPassword(
        selectedEmail,
        dbUser.payment_code
      );

      if (res.statusCode === 200) {
        setIsUserModalReload(true);
        setMessage("仮パスワードのメールを再送信しました。");
      } else {
        setMessage(
          "エラーが発生しました。\n再度実施するか管理者へ連絡してください。"
        );
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  // 登録、更新した場合、代理店ユーザー一覧リロード
  useEffect(() => {
    if (isUserModalReload === true) {
      getUserIdDetail(selectedAgencyData.code);
      setIsUserModalReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserModalReload]);

  // 代理店詳細モーダル
  const [agencyCode, setAgencyCode] = useState(selectedAgencyData.code);
  const [agencyName, setAgencyName] = useState(selectedAgencyData.name);
  const [registrationNumber, setRegistrationNumber] = useState(
    selectedAgencyData.registration_number
  );

  /* 代理店詳細モーダルオープン時、代理店情報取得 */
  const modalOpen = async (data: AgencyData | null = null) => {
    if (data) {
      getAgencyDetail(data.code);
    } else {
      setSelectedAgencyData(agencyData);
      setIsModalLoading(false);
    }
    setIsModalOpen(!isModalOpen);
  };

  // 代理店登録/更新後に、新規登録、詳細モーダルを閉じた時、代理店一覧リロード
  useEffect(() => {
    if (isReload && !isModalOpen && isAdmin) {
      setIsReload(false);
      if (inputKeyword === "") {
        getAgencyList(nowOffset);
      } else {
        //検索されている場合は絞り込みを保持
        getAgencyList(nowOffset, selectedItem, inputKeyword);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  // 代理店詳細画面で、代理店情報取得
  const getAgencyDetail = async (code: string) => {
    try {
      setIsModalLoading(true);
      const agency = await getAgencyCode(code);
      const agencyBody = await JSON.parse(agency);
      setSelectedAgencyData({
        code: agencyBody.data.code,
        payment_code: agencyBody.data.payment_code,
        name: agencyBody.data.name,
        registration_number: agencyBody.data.registration_number,
        trading_stop_date: agencyBody.data.trading_stop_date,
        createdAt: agencyBody.data.created_at,
        updatedAt: "",
      });
    } catch (e) {
      console.error(e);
    } finally {
      setIsModalLoading(false);
    }
  };

  /* 代理店登録ボタン押下 */
  const onRegisterAgencyClick = async () => {
    try {
      const res = await postAgency(
        agencyCode,
        dbUser.payment_code,
        agencyName,
        registrationNumber
      );
      if (res.statusCode === 200) {
        setMessage("代理店情報を登録しました。");
        setAgencyCode("");
        setAgencyName("");
        setRegistrationNumber("");
        setIsReload(true);
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  /* 代理店情報更新ボタン押下 */
  const onUpdateAgencyClick = async () => {
    try {
      let res;
      res = await putAgencyCode(
        selectedAgencyData.code,
        agencyCode,
        agencyName,
        registrationNumber
      );
      if (res.statusCode === 200) {
        setMessage("代理店情報を更新しました。");
        setIsReload(true);
        setSelectedAgencyData({
          ...selectedAgencyData,
          code: agencyCode,
          name: agencyName,
          registration_number: registrationNumber,
        });
        return;
      }
      if (res.statusCode === 200) {
        setIsReload(true);
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  /* 代理店取引再開、取引停止ボタン押下 */
  const [isTradingStart, setIsTradingStart] = useState(false);
  const onUpdateAgencyTradingStatus = async () => {
    try {
      // 取引停止の場合は本日日付を取引停止日にセット
      const res = await putAgencyCode(
        selectedAgencyData.code,
        selectedAgencyData.code,
        selectedAgencyData.name,
        selectedAgencyData.registration_number,
        isTradingStart ? null : new Date().toLocaleDateString("ja-JP")
      );
      if (res.statusCode === 200) {
        const users = await getUsers(agencyCode, "agency");
        const usersBody = await JSON.parse(users);

        let isSuccess = true;
        for (const usersData of usersBody) {
          const putUserRes = await putUser(
            usersData.user_id,
            usersData.agency_code,
            isTradingStart
          );
          if (putUserRes.statusCode !== 200) {
            isSuccess = false;
          }
        }
        if (isSuccess) {
          if (isTradingStart) {
            setMessage(
              "代理店を取引再開しました。\n所属するユーザーを有効にしました。"
            );
          } else {
            setMessage(
              "代理店を取引停止にしました。\n所属するユーザーを無効にしました。"
            );
          }
        } else {
          if (isTradingStart) {
            setMessage(
              "代理店を取引再開しました。\n有効にできなかった所属ユーザーが存在します。\n個別で有効にしてください。"
            );
          } else {
            setMessage(
              "代理店を取引停止にしました。\n無効にできなかった所属ユーザーが存在します。\n個別で無効にしてください。"
            );
          }
        }

        setIsReload(true);
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  // 代理店詳細モーダルリロード
  useEffect(() => {
    if (isModalReload) {
      getAgencyDetail(selectedAgencyData.code);
      setIsModalReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalReload]);

  return {
    agencyDataList,
    nextOffset,
    previousOffset,
    isLoading,
    isModalOpen,
    setIsModalOpen,
    isModalLoading,
    modalOpen,
    userModalOpen,
    getAgencyList,
    message,
    setMessage,
    selectedItem,
    setSelectedItem,
    inputKeyword,
    setInputKeyword,
    searchItems,
    // ユーザー追加モーダル
    usersListData,
    isUserModalOpen,
    setIsUserModalOpen,
    isUserModalLoading,
    userEmailValue,
    setUserEmailValue,
    onRegisterUserClick,
    onUpdateUserClick,
    // ユーザー詳細モーダル
    selectedAgencyData,
    agencyCode,
    setAgencyCode,
    agencyName,
    setAgencyName,
    registrationNumber,
    setRegistrationNumber,
    setIsTradingStart,
    setIsModalReload,
    onRegisterAgencyClick,
    onUpdateAgencyClick,
    onUpdateAgencyTradingStatus,
    resetPassword,
  };
};
