import React from "react";

/**
 * フッター
 * @returns
 */
const Footer = () => {
  return <footer></footer>;
};

export default Footer;
