import { FC } from "react";
import { PaymentData } from "../../common/type";

type Props = {
  payment: PaymentData;
};

/**
 * 会社情報テーブル(管理者)
 * @param props
 * @returns
 */
const PaymentTable: FC<Props> = ({ payment }) => {
  return (
    <dl className="divide-y divide-gray-100">
      <div className="px-4 py-3 grid grid-cols-5">
        <dt className="font-medium leading-6 text-gray-500 text-sm">会社名</dt>
        <dd className="mt-1 leading-6 col-span-4">{payment.name}</dd>
      </div>
      <div className="px-4 py-3 grid grid-cols-5">
        <dt className="font-medium leading-6 text-gray-500 text-sm">
          会社名カナ
        </dt>
        <dd className="mt-1 leading-6 col-span-4">{payment.name_kana}</dd>
      </div>
      <div className="px-4 py-3 grid grid-cols-5">
        <dt className="font-medium leading-6 text-gray-500 text-sm">
          郵便番号
        </dt>
        <dd className="mt-1 leading-6 col-span-4">{payment.postal_code}</dd>
      </div>
      <div className="px-4 py-3 grid grid-cols-5">
        <dt className="font-medium leading-6 text-gray-500 text-sm">住所</dt>
        <dd className="mt-1 leading-6 col-span-4">{payment.address}</dd>
      </div>
      <div className="px-4 py-3 grid grid-cols-5">
        <dt className="font-medium leading-6 text-gray-500 text-sm">
          電話番号
        </dt>
        <dd className="mt-1 leading-6 col-span-4">{payment.phone_number}</dd>
      </div>
      <div className="px-4 py-3 grid grid-cols-5">
        <dt className="font-medium leading-6 text-gray-500 text-sm">
          金融機関名
        </dt>
        <dd className="mt-1 leading-6 col-span-4">
          {payment.financial_facility_name}
        </dd>
      </div>
      <div className="px-4 py-3 grid grid-cols-5">
        <dt className="font-medium leading-6 text-gray-500 text-sm">
          金融機関支店名
        </dt>
        <dd className="mt-1 leading-6 col-span-4">{payment.branch_name}</dd>
      </div>
      <div className="px-4 py-3 grid grid-cols-5">
        <dt className="font-medium leading-6 text-gray-500 text-sm">
          口座種別
        </dt>
        <dd className="mt-1 leading-6 col-span-4">{payment.account_type}</dd>
      </div>
      <div className="px-4 py-3 grid grid-cols-5">
        <dt className="font-medium leading-6 text-gray-500 text-sm">
          口座番号
        </dt>
        <dd className="mt-1 leading-6 col-span-4">
          {payment.bank_account_number}
        </dd>
      </div>
      <div className="px-4 py-3 grid grid-cols-5">
        <dt className="font-medium leading-6 text-gray-500 text-sm">
          登録番号
        </dt>
        <dd className="mt-1 leading-6 col-span-4">
          {payment.registration_number}
        </dd>
      </div>
    </dl>
  );
};

export default PaymentTable;
