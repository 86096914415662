import React, { FC, useState, useContext, useEffect } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import { NotificationDocsDetailNoData } from "../../common/type";
import LoadingSpinner from "../common/LoadingSpinner";
import Alert from "../common/Alert";
import { IsAdminContext } from "../contexts/IsAdminContext";
import { Storage } from "aws-amplify";
import { saveAs } from "file-saver";
import axios from "axios";
import { useDBUserContext } from "../contexts/DBUserContext";

type Props = {
  isModalOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalLoading: boolean;
  notificationDocsNoData: NotificationDocsDetailNoData;
  onUpdateNotificationDocs: () => Promise<void>;
  setSelectedData: React.Dispatch<
    React.SetStateAction<{
      notification_doc_no: string;
      year_month: string;
      confirmedDate: string | null;
    }>
  >;
  setIsSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * 通知書詳細モーダル
 * @param props
 * @returns
 */
const NotificationDetailModal: FC<Props> = ({
  isModalOpen,
  setIsOpen,
  isModalLoading,
  notificationDocsNoData,
  onUpdateNotificationDocs,
  setSelectedData,
  setIsSuccess,
  message,
  setMessage,
}) => {
  const { isAdmin } = useContext(IsAdminContext);
  const { dbUser } = useDBUserContext();
  const [yearMonth, setYearMonth] = useState("");

  const [confirmedAlert, setConfirmedAlert] = useState(false);
  const [confirmedCancelAlert, setConfirmedCancelAlert] = useState(false);

  const [isFileCheckAlert, setIsFileCheckAlert] = useState(false);
  const [fileCheckMessage, setFileCheckMessage] = useState("");

  const [downloadingFileType, setDownloadingFileType] = useState("");
  const [issueDate, setIssueDate] = useState<string | null>(null);
  const [csvIssueDate, setCsvIssueDate] = useState<string | null>(null);

  const closeModal = () => {
    setIsOpen(!isModalOpen);
  };

  const updateButtonFunc = (isConfirmed: boolean) => {
    let confirmedDate;
    if (isConfirmed) {
      setConfirmedAlert(true);
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      confirmedDate = `${year}/${month}/${day}`;
    } else {
      setConfirmedCancelAlert(true);
      confirmedDate = null;
    }
    setSelectedData({
      notification_doc_no: notificationDocsNoData.notification_doc_no,
      year_month: notificationDocsNoData.year_month,
      confirmedDate: confirmedDate,
    });
  };

  const handlePDFView = async (type: string) => {
    try {
      let fileName;
      if (issueDate) {
        fileName = `${notificationDocsNoData.year_month}_${notificationDocsNoData.notification_doc_no}_${notificationDocsNoData.agency_name}_${issueDate}_${notificationDocsNoData.amount_paid_tax_included}.${type}`;
      } else {
        fileName = `${notificationDocsNoData.year_month}_${notificationDocsNoData.notification_doc_no}_${notificationDocsNoData.agency_name}.${type}`;
      }

      const signedUrl = await Storage.get(
        `notification-docs/${type}/${notificationDocsNoData.year_month}/${fileName}`
      );
      const response = await axios.get(signedUrl, { responseType: "blob" });
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    } catch (e) {
      console.error(e);
      setFileCheckMessage("ファイルが存在しません。");
      setIsFileCheckAlert(true);
    }
  };

  const handleDownloadFile = async (type: string) => {
    setDownloadingFileType(type);
    try {
      let fileName;
      if (type === "pdf" && issueDate) {
        fileName = `${notificationDocsNoData.year_month}_${notificationDocsNoData.notification_doc_no}_${notificationDocsNoData.agency_name}_${issueDate}_${notificationDocsNoData.amount_paid_tax_included}.${type}`;
      } else if (type === "csv" && csvIssueDate) {
        fileName = `${notificationDocsNoData.year_month}_${notificationDocsNoData.notification_doc_no}_${notificationDocsNoData.agency_name}_${csvIssueDate}_${notificationDocsNoData.amount_paid_tax_included}.${type}`;
      } else {
        fileName = `${notificationDocsNoData.year_month}_${notificationDocsNoData.notification_doc_no}_${notificationDocsNoData.agency_name}.${type}`;
      }

      const res = await Storage.get(
        `notification-docs/${type}/${notificationDocsNoData.year_month}/${fileName}`,
        {
          download: true,
          cacheControl: "no-cache",
          progressCallback(progress) {
            progress.loaded >= progress.total && setDownloadingFileType("");
          },
        }
      );
      saveAs(res.Body as Blob, fileName);
    } catch (e) {
      console.error(e);
      setFileCheckMessage("ファイルが存在しません。");
      setIsFileCheckAlert(true);
      setDownloadingFileType("");
    }
  };

  useEffect(() => {
    if (isModalOpen && notificationDocsNoData) {
      let input = notificationDocsNoData.year_month;
      let str = input.toString();
      let year = str.substring(0, 4);
      let month = str.substring(4);
      setYearMonth(`${year}年${month}月`);
      const issueDatetime = notificationDocsNoData.issue_date
        ? notificationDocsNoData.issue_date.replaceAll("-", "")
        : null;
      setIssueDate(issueDatetime);
      const csvIssueDatetime = notificationDocsNoData.csv_issue_date
        ? notificationDocsNoData.csv_issue_date.replaceAll("-", "")
        : null;
      setCsvIssueDate(csvIssueDatetime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationDocsNoData]);
  return (
    <Modal isOpen={isModalOpen} width="w-[1100px]">
      <div className="grid grid-flow-row-dense grid-cols-2 border-b border-gray-300 pb-2">
        <h3 className="flex justify-between text-2xl font-semibold leading-6 text-text">
          通知書詳細
        </h3>
        {!isModalLoading && (
          <div className="grid grid-flow-row-dense grid-cols-3 gap-4 ml-auto">
            <Button onClick={() => handlePDFView("pdf")} buttonType={"normal"}>
              PDFをブラウザで表示
            </Button>
            <Button
              onClick={() => handleDownloadFile("pdf")}
              disabled={downloadingFileType !== ""}
              isLoading={downloadingFileType === "pdf"}
              buttonType={"normal"}
            >
              通知書ダウンロード
            </Button>
            <Button
              onClick={() => handleDownloadFile("csv")}
              disabled={downloadingFileType !== ""}
              isLoading={downloadingFileType === "csv"}
              buttonType={"normal"}
            >
              明細CSVダウンロード
            </Button>
          </div>
        )}
      </div>

      {isModalLoading ? (
        <>
          <div className="my-20">
            <LoadingSpinner />
          </div>
        </>
      ) : (
        <>
          <div className="grid grid-flow-row-dense grid-cols-3 gap-2 mt-4 border border-gray-400 rounded-lg p-4">
            <dl>
              <div className="flex">
                <dt className="w-1/4 text-sm text-gray-500 font-medium">
                  対象年月
                </dt>
                <dd className="w-3/4">{yearMonth}</dd>
              </div>
              <div className="flex mt-4">
                <dt className="w-1/4 text-sm text-gray-500 font-medium">
                  代理店
                </dt>
                <dd className="w-3/4">{notificationDocsNoData.agency_name}</dd>
              </div>
            </dl>
            <dl className="flex justify-center">
              <div>
                <dt className="text-sm text-gray-500 font-medium">
                  支払予定日
                </dt>
                <dd className="ml-4 text-xl">
                  {notificationDocsNoData.payment_date
                    ? `${notificationDocsNoData.payment_date.slice(
                        0,
                        4
                      )}年${notificationDocsNoData.payment_date.slice(
                        5,
                        7
                      )}月${notificationDocsNoData.payment_date.slice(8, 10)}日`
                    : notificationDocsNoData.payment_date}
                </dd>
              </div>
            </dl>
            <div className="grid grid-rows-2 gap-8">
              <dl>
                <div className="flex">
                  <dt className="w-1/4 text-sm text-gray-500 font-medium">
                    予定額(税込)
                  </dt>
                  <dd className="w-1/4 flex justify-end">
                    {notificationDocsNoData.amount_paid_tax_included.toLocaleString()}
                  </dd>
                </div>
                <div className="flex mt-4">
                  <dt className="w-1/4 text-sm text-gray-500 font-medium">
                    消費税金額
                  </dt>
                  <dd className="w-1/4 flex justify-end">
                    {notificationDocsNoData.tax_amount.toLocaleString()}
                  </dd>
                </div>
              </dl>
              <dl>
                <div className="flex">
                  <dt className="w-1/4 text-sm text-gray-500 font-medium">
                    確定額(税込)
                  </dt>
                  <dd className="w-1/4 flex justify-end">
                    {notificationDocsNoData.confirmed_date &&
                      notificationDocsNoData.amount_paid_tax_included.toLocaleString()}
                  </dd>
                </div>
                <div className="flex mt-4">
                  <dt className="w-1/4 text-sm text-gray-500 font-medium">
                    消費税金額
                  </dt>
                  <dd className="w-1/4 flex justify-end">
                    {notificationDocsNoData.confirmed_date &&
                      notificationDocsNoData.tax_amount.toLocaleString()}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="mt-4 flow-root">
            <div className="-my-2 -mx-8">
              <div className="inline-block min-w-full py-2 align-middle px-8">
                <div className="overflow-auto max-h-[30vh]">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="sticky top-0 bg-white">
                      <tr className="text-gray-500 text-xs font-semibold">
                        <th
                          scope="col"
                          className="w-[34%] px-3 py-3.5 text-left"
                        >
                          商材名
                        </th>
                        <th
                          scope="col"
                          className="w-[11%] px-3 py-3.5 text-left"
                        >
                          支払区分
                        </th>
                        <th
                          scope="col"
                          className="w-[15%] px-3 py-3.5 text-left"
                        >
                          課税区分
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-center"
                        >
                          予定額(税抜)
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-center"
                        >
                          確定額(税抜)
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {notificationDocsNoData.products.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-left">
                              {data.product_name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-left">
                              {data.payment_category}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-left">
                              {data.tax_category}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-right">
                              {data.amount_paid_tax_included.toLocaleString()}
                            </td>
                            <td className="whitespace-nowrap px-3 py-2 text-sm text-right">
                              {notificationDocsNoData.confirmed_date
                                ? data.amount_paid_tax_included.toLocaleString()
                                : ""}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 grid grid-flow-row-dense grid-cols-3 gap-60">
            <Button
              onClick={() => closeModal()}
              disabled={!isModalOpen}
              buttonType={"normal"}
            >
              閉じる
            </Button>
            {isAdmin && !notificationDocsNoData.confirmed_date ? (
              <>
                {dbUser.user_id && dbUser.permissions !== 2 && (
                  <>
                    <Button
                      onClick={() => updateButtonFunc(true)}
                      disabled={confirmedAlert}
                    >
                      確定処理
                    </Button>
                    <Alert
                      isAlert={confirmedAlert}
                      setIsAlert={setConfirmedAlert}
                      onClick={onUpdateNotificationDocs}
                      title={"通知書確定処理"}
                      submitText={"はい"}
                      cancelText={"いいえ"}
                      message={message}
                      setMessage={setMessage}
                      onClose={() => {
                        if (message === "確定処理が完了しました。") {
                          setIsSuccess(true);
                        }
                      }}
                    >
                      確定処理します。
                      <br />
                      よろしいですか？
                    </Alert>
                  </>
                )}
              </>
            ) : (
              <div></div>
            )}
            {isAdmin && notificationDocsNoData.confirmed_date ? (
              <>
                {dbUser.user_id && dbUser.permissions !== 2 && (
                  <>
                    <Button
                      onClick={() => updateButtonFunc(false)}
                      buttonType={"delete"}
                      disabled={confirmedCancelAlert}
                    >
                      確定取消
                    </Button>
                    <Alert
                      isAlert={confirmedCancelAlert}
                      setIsAlert={setConfirmedCancelAlert}
                      onClick={onUpdateNotificationDocs}
                      title={"通知書確定取消"}
                      submitText={"はい"}
                      cancelText={"いいえ"}
                      message={message}
                      setMessage={setMessage}
                      onClose={() => {
                        if (message === "確定取消が完了しました。") {
                          setIsSuccess(true);
                        }
                      }}
                    >
                      確定取消します。
                      <br />
                      よろしいですか？
                    </Alert>
                  </>
                )}
              </>
            ) : (
              <div></div>
            )}
            {isFileCheckAlert && (
              <>
                <Alert
                  isAlert={isFileCheckAlert}
                  setIsAlert={setIsFileCheckAlert}
                  onClick={async () => {}}
                  title={"ダウンロードエラー"}
                  submitText={""}
                  cancelText={"閉じる"}
                  message={""}
                  setMessage={setMessage}
                >
                  {fileCheckMessage}
                </Alert>
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default NotificationDetailModal;
