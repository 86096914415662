import { FC } from "react";
import { NotificationDocsData } from "../../common/type";
import * as luxon from "luxon";

type Props = {
  notificationDocsDataList: NotificationDocsData[];
  modalOpen: (data: NotificationDocsData) => void;
};

/**
 * 通知書テーブル
 * @param props
 * @returns
 */
const NotificationDocsTable: FC<Props> = ({
  notificationDocsDataList,
  modalOpen,
}) => {
  const detailModalOpen = (data: any) => {
    modalOpen(data);
  };
  return (
    <div className="flow-root">
      <div className="-my-2 -mx-8">
        <div className="inline-block min-w-full py-2 align-middle px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="text-gray-500 text-xs font-semibold">
                <th scope="col" className="w-[12%] px-3 py-3.5 text-left">
                  対象年月
                </th>
                <th scope="col" className="w-[40%] px-3 py-3.5 text-left">
                  代理店名
                </th>
                <th scope="col" className="w-[12%] px-3 py-3.5 text-left">
                  代理店コード
                </th>
                <th scope="col" className="w-[12%] px-3 py-3.5 text-left">
                  支払予定日
                </th>
                <th scope="col" className="w-[12%] px-3 py-3.5 text-right">
                  予定額(税込)
                </th>
                <th scope="col" className="w-[12%] px-3 py-3.5 text-right">
                  確定額(税込)
                </th>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {notificationDocsDataList.map((data, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => detailModalOpen(data)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {data.year_month.slice(0, 4)}/{data.year_month.slice(4)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {data.agency_name?.length > 27
                        ? `${data.agency_name.slice(0, 27)}...`
                        : data.agency_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {data.agency_code}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {luxon.DateTime.fromSQL(data.payment_date).toFormat(
                        "yyyy/MM/dd"
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-1 text-sm">
                      <div className="flex justify-end">
                        {data.amount_paid_tax_included.toLocaleString()}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-1 text-sm">
                      <div className="flex justify-end">
                        {data.confirmed_date
                          ? data.amount_paid_tax_included.toLocaleString()
                          : ""}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NotificationDocsTable;
