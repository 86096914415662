/**
 * ダミー画面
 * (画面更新、ログイン時の読み込み完了まで表示)
 * @returns
 */
const Dummy = () => {
  return <div></div>;
};

export default Dummy;
