import "@aws-amplify/ui-react/styles.css";
import {
  ArchiveBoxArrowDownIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Loader } from "@aws-amplify/ui-react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { Storage } from "aws-amplify";
import { saveAs } from "file-saver";
import * as luxon from "luxon";
import { useEffect, useState } from "react";
import { getTermsDocs } from "../api/getTermsDocs";
import { termsDocData } from "../common/initialData";
import { SearchItemsType, SearchSelect, TermsDocsData } from "../common/type";
import Button from "../components/common/Button";
import LoadingSpinner from "../components/common/LoadingSpinner";
import SearchModule from "../components/common/SearchModule";
import { useDBUserContext } from "../components/contexts/DBUserContext";
import { useIsAdminContext } from "../components/contexts/IsAdminContext";
import RemoveModal from "../components/terms-docs/RemoveModal";
import UploadModal from "../components/terms-docs/UploadModal";
import { useTermsDocs } from "../hooks/useTermsDocs";
import { TermsDocsIcon } from "../components/Icon";
import Alert from "../components/common/Alert";

/**
 * 条件通知書情報画面
 * @returns
 */
const TermsDocs = () => {
  const { getSummaries } = useTermsDocs();
  const { isAdmin } = useIsAdminContext();
  const { dbUser } = useDBUserContext();
  const [searchItems] = useState<SearchItemsType[]>([
    {
      value: "selectName",
      label: "代理店名",
      method: "word",
      type: "string",
    },
    {
      value: "selectCode",
      label: "代理店コード",
      method: "word",
      type: "string",
    },
  ]);
  const [selectedItem, setSelectedItem] = useState<SearchSelect>(
    searchItems[0].value
  );
  const [inputKeyword, setInputKeyword] = useState("");
  const [selectedRemoveTermsDoc, setSelectedRemoveTermsDoc] =
    useState<TermsDocsData>(termsDocData);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [termsDocs, setTermsDocs] = useState<TermsDocsData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadingFileId, setDownloadingFileId] = useState("");
  const [isFileCheckAlert, setIsFileCheckAlert] = useState(false);
  const [fileCheckMessage, setFileCheckMessage] = useState("");

  const handleSearchButton = async () => {
    setIsLoading(true);
    let offset = 0;
    let data: TermsDocsData[] = [];
    while (offset !== -1) {
      const res = await getTermsDocs(50, offset, selectedItem, inputKeyword);
      const body = JSON.parse(res.body);
      data.push(...body.data);
      offset = body.next_offset;
    }
    setTermsDocs(data);
    setIsLoading(false);
  };
  const handleDownloadFile = async (
    terms_docs_id: string,
    terms_docs_name: string
  ) => {
    setDownloadingFileId(terms_docs_id);
    try {
      const res = await Storage.get(`terms-docs/${terms_docs_id}.pdf`, {
        download: true,
        progressCallback(progress) {
          progress.loaded >= progress.total && setDownloadingFileId("");
        },
      });
      saveAs(res.Body as Blob, terms_docs_name);
    } catch (e) {
      console.error(e);
      setFileCheckMessage("ファイルが存在しません。");
      setIsFileCheckAlert(true);
      setDownloadingFileId("");
    }
  };
  useEffect(() => {
    const fetchBootLoader = async () => {
      let offset = 0;
      let data: TermsDocsData[] = [];
      while (offset !== -1) {
        let res;
        if (isAdmin) {
          res = await getTermsDocs(50, offset);
        } else if (dbUser.agency_code !== "") {
          res = await getTermsDocs(
            50,
            offset,
            "selectCode",
            dbUser.agency_code
          );
        } else {
          return;
        }
        const body = JSON.parse(res.body);
        data.push(...body.data);
        offset = body.next_offset;
      }
      setTermsDocs(data);
      setIsLoading(false);
    };
    fetchBootLoader();
  }, [isAdmin, dbUser]);

  return (
    <>
      <div className="flex flex-col pt-24 w-fit min-w-full">
        {/* ヘッダー */}
        <div className="flex items-center pb-10">
          <TermsDocsIcon />
          <h2 className="text-2xl font-semibold leading-6 ml-2">
            条件通知書管理
          </h2>
          {isAdmin && (
            <div className="flex flex-grow justify-end items-center">
              <SearchModule
                inputKeyword={inputKeyword}
                setInputKeyword={setInputKeyword}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                onClick={handleSearchButton}
                searchItems={searchItems}
              />
              {dbUser.user_id && dbUser.permissions !== 2 && (
                <Button
                  onClick={() => setOpenUploadModal(true)}
                  disabled={openUploadModal === true}
                >
                  アップロード
                </Button>
              )}
            </div>
          )}
        </div>
        {/* テーブル */}
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div>
            {getSummaries().map((summary) => (
              <Accordion key={summary.label}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="flex justify-between items-center px-20 w-full">
                    <Typography>{summary.label}</Typography>
                    {termsDocs.filter(
                      (termsDoc) =>
                        termsDoc.applicable_period === summary.applicablePeriod
                    ).length === 0 ? (
                      <Typography />
                    ) : (
                      <Typography>{`${
                        termsDocs.filter(
                          (termsDoc) =>
                            termsDoc.applicable_period ===
                            summary.applicablePeriod
                        ).length
                      } 件`}</Typography>
                    )}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {termsDocs.filter(
                    (termsDoc) =>
                      termsDoc.applicable_period === summary.applicablePeriod
                  ).length === 0 ? (
                    <p className="text-center pb-5">
                      条件通知書が見つかりません。
                    </p>
                  ) : (
                    <div className="mt-8 flow-root">
                      <div className="-my-2 -mx-8">
                        <div className="inline-block min-w-full py-2 align-middle px-8">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                              <tr className="text-gray-500 text-xs font-semibold">
                                <th
                                  scope="col"
                                  className="w-[15%] px-3 py-3.5 text-left"
                                >
                                  アップ日
                                </th>
                                <th
                                  scope="col"
                                  className="w-[20%] px-3 py-3.5 text-left"
                                >
                                  ファイル名
                                </th>
                                <th
                                  scope="col"
                                  className="w-[25%] px-3 py-3.5 text-left"
                                >
                                  代理店名
                                </th>
                                <th
                                  scope="col"
                                  className="w-[15%] px-3 py-3.5 text-left"
                                >
                                  代理店コード
                                </th>
                                {isAdmin && (
                                  <th
                                    scope="col"
                                    className="w-[15%] px-3 py-3.5 text-left"
                                  >
                                    投稿管理者
                                  </th>
                                )}
                                <th
                                  scope="col"
                                  className="w-[5%] px-3 py-3.5 text-left"
                                >
                                  &nbsp;
                                </th>
                                <th
                                  scope="col"
                                  className="w-[5%] px-3 py-3.5 text-left"
                                >
                                  &nbsp;
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 text-text">
                              {termsDocs
                                .filter(
                                  (termsDoc) =>
                                    termsDoc.applicable_period ===
                                    summary.applicablePeriod
                                )
                                .map((termsDoc, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                                        {luxon.DateTime.fromSQL(
                                          termsDoc.created_at
                                        ).toFormat("yyyy/MM/dd")}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                                        {termsDoc.terms_docs_name?.length > 22
                                          ? `${termsDoc.terms_docs_name.slice(
                                              0,
                                              22
                                            )}...`
                                          : termsDoc.terms_docs_name}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                                        {termsDoc.agency_name?.length > 12
                                          ? `${termsDoc.agency_name.slice(
                                              0,
                                              12
                                            )}...`
                                          : termsDoc.agency_name}
                                      </td>
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                                        {termsDoc.agency_code}
                                      </td>
                                      {isAdmin && (
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                                          {termsDoc.post_user_name}
                                        </td>
                                      )}
                                      <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                                        {downloadingFileId ===
                                        termsDoc.terms_docs_id ? (
                                          <Loader />
                                        ) : downloadingFileId ? (
                                          <div className="text-gray-400 cursor-not-allowed w-5 h-5">
                                            <ArchiveBoxArrowDownIcon title="ダウンロード" />
                                          </div>
                                        ) : (
                                          <ArchiveBoxArrowDownIcon
                                            title="ダウンロード"
                                            className="w-5 h-5 cursor-pointer"
                                            onClick={() =>
                                              handleDownloadFile(
                                                termsDoc.terms_docs_id,
                                                termsDoc.terms_docs_name
                                              )
                                            }
                                          />
                                        )}
                                      </td>
                                      {isAdmin && (
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                                          {dbUser.user_id &&
                                            dbUser.permissions !== 2 && (
                                              <XCircleIcon
                                                title="削除"
                                                className={`w-5 h-5 ${
                                                  downloadingFileId ===
                                                  termsDoc.terms_docs_id
                                                    ? "text-gray-400 cursor-not-allowed"
                                                    : "cursor-pointer"
                                                }`}
                                                onClick={() => {
                                                  if (
                                                    downloadingFileId !==
                                                    termsDoc.terms_docs_id
                                                  ) {
                                                    setSelectedRemoveTermsDoc(
                                                      termsDoc
                                                    );
                                                    setIsAlert(true);
                                                  }
                                                }}
                                              />
                                            )}
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
      </div>
      {/* アップロードモーダル */}
      <UploadModal
        openModal={openUploadModal}
        termsDocs={termsDocs}
        setTermsDocs={setTermsDocs}
        setOpenModal={setOpenUploadModal}
      />

      {/* 削除確認モーダル */}
      <RemoveModal
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        termsDocs={termsDocs}
        setTermsDocs={setTermsDocs}
        selectedRemoveTermsDoc={selectedRemoveTermsDoc}
      />
      {isFileCheckAlert && (
        <>
          <Alert
            isAlert={isFileCheckAlert}
            setIsAlert={setIsFileCheckAlert}
            onClick={async () => {}}
            title={"ダウンロードエラー"}
            submitText={""}
            cancelText={"閉じる"}
            message={""}
            setMessage={async () => {}}
          >
            {fileCheckMessage}
          </Alert>
        </>
      )}
    </>
  );
};

export default TermsDocs;
