import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

/**
 * 商品明細ファイルステータス取得APIコール
 * @param data
 * @returns
 */
export const getProductDetailsStatus = async (fileNames: string[]) => {
  let path = "/product_details/status?";
  fileNames.forEach((fileName, index) => {
    if (index !== 0) {
      path += "&";
    }
    path += `file_names=${fileName}`;
  });

  const params = {};
  let result: any;
  await API.get(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
