import { translations } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";

/**
 * 日本語翻訳フック
 * @returns
 */
export const useTranslation = () => {
  const setJapanese = () => {
    I18n.putVocabularies(translations);
    const dict = {
      ja: {
        "Sign in": "ログイン",
        "Signing in": "ログイン中",
        "User does not exist.": "存在しないユーザーです。",
        "User is disabled.": "無効なユーザーです。",
        "Reset Password": "パスワード再設定",
        "Back to Sign In": "ログインへ戻る",
        "Send code": "検証コードを送信",
        "Attempt limit exceeded, please try after some time.":
          "試行回数制限を超えましたので、しばらく時間をおいてからお試しください。",
        "Username/client id combination not found.":
          "入力されたユーザーが見つかりません。",
        "Cannot reset password for the user as there is no registered/verified email or phone_number":
          "入力されたメールアドレスのメール受信確認がされていません。",
        "Code *": "検証コード",
        "Your passwords must match": "入力したパスワードが一致しません。",
        "Invalid verification code provided, please try again.":
          "入力された検証コードに誤りがあります。",
        "Invalid code provided, please request a code again.":
          "無効な検証コードです。再度検証コードをリクエストしてください。",
        "Password does not conform to policy: Password not long enough":
          "パスワードは6桁以上入力してください。",
        "Incorrect username or password.":
          "メールアドレスかパスワードに誤りがあります。",
        "Drop files here or": "ここにドラッグ＆ドロップしてください。",
        "Browse files": "ファイルを開く",
      },
    };
    I18n.putVocabularies(dict);
    I18n.setLanguage("ja");
  };

  return { setJapanese };
};
