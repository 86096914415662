import React, { FC, useState } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import { InvoiceChangeLogDetailIdData } from "../../common/type";
import LoadingSpinner from "../common/LoadingSpinner";
import Alert from "../common/Alert";
import { Storage } from "aws-amplify";
import { saveAs } from "file-saver";
import axios from "axios";
import Textarea from "../common/Textarea";

type Props = {
  isModalOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalLoading: boolean;
  invoiceChangeLogIdData: InvoiceChangeLogDetailIdData;
  onUpdateInvoiceClick: () => Promise<void>;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * 支払請求変更履歴モーダル
 * @param props
 * @returns
 */
const InvoiceChangeLogModal: FC<Props> = ({
  isModalOpen,
  setIsOpen,
  isModalLoading,
  invoiceChangeLogIdData,
  remarks,
  setRemarks,
  onUpdateInvoiceClick,
  message,
  setMessage,
}) => {
  const [isFileCheckAlert, setIsFileCheckAlert] = useState(false);
  const [fileCheckMessage, setFileCheckMessage] = useState("");

  const [downloadingFileType, setDownloadingFileType] = useState("");

  const [isAlert, setIsAlert] = useState(false);
  const [isValidationAlert, setIsValidationAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const closeModal = () => {
    setIsOpen(!isModalOpen);
  };

  const validation = () => {
    if (remarks.length > 255) {
      setValidationMessage("備考の文字数が255文字を超えています。");
      setIsValidationAlert(true);
    } else {
      setValidationMessage("");
      setIsAlert(true);
    }
  };

  const handleFileView = async () => {
    try {
      const signedUrl = await Storage.get(
        `notification-docs/pdf/backup/${invoiceChangeLogIdData.year_month}/${invoiceChangeLogIdData.file_name}`
      );
      const response = await axios.get(signedUrl, { responseType: "blob" });
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, "_blank");
    } catch (e) {
      console.error(e);
      setFileCheckMessage("ファイルが存在しません。");
      setIsFileCheckAlert(true);
    }
  };

  const handleDownloadFile = async (type: string) => {
    setDownloadingFileType(type);
    let fileName;
    if (type === "pdf") {
      fileName = `${invoiceChangeLogIdData.file_name}`;
    } else {
      fileName = `${invoiceChangeLogIdData.details_file_name}`;
    }
    try {
      const res = await Storage.get(
        `notification-docs/${type}/backup/${invoiceChangeLogIdData.year_month}/${fileName}`,
        {
          download: true,
          cacheControl: "no-cache",
          progressCallback(progress) {
            progress.loaded >= progress.total && setDownloadingFileType("");
          },
        }
      );
      saveAs(res.Body as Blob, fileName);
    } catch (e) {
      console.error(e);
      setFileCheckMessage("ファイルが存在しません。");
      setIsFileCheckAlert(true);
      setDownloadingFileType("");
    }
  };

  return (
    <Modal isOpen={isModalOpen} width="w-[980px]">
      <div className="grid grid-flow-row-dense grid-cols-3 border-b border-gray-300 pb-2">
        <h3 className="flex justify-between text-2xl font-semibold leading-6 text-text mt-1">
          支払請求変更履歴詳細
        </h3>
        {!isModalLoading && (
          <div className="col-span-2 grid grid-flow-row-dense grid-cols-3 gap-4 ml-auto">
            <Button onClick={() => handleFileView()} buttonType={"normal"}>
              ファイルをブラウザで表示
            </Button>
            <Button
              onClick={() => handleDownloadFile("pdf")}
              disabled={downloadingFileType !== ""}
              isLoading={downloadingFileType === "pdf"}
              buttonType={"normal"}
            >
              ファイルダウンロード
            </Button>
            <Button
              onClick={() => handleDownloadFile("csv")}
              disabled={downloadingFileType !== ""}
              isLoading={downloadingFileType === "csv"}
              buttonType={"normal"}
            >
              明細ファイルダウンロード
            </Button>
          </div>
        )}
      </div>

      {isModalLoading ? (
        <>
          <div className="my-20">
            <LoadingSpinner />
          </div>
        </>
      ) : (
        <>
          <dl className="mt-4 border border-gray-400 rounded-lg p-4">
            <div className="py-3 grid grid-cols-2">
              <dl>
                <div className="flex">
                  <dt className="w-1/4 font-medium items-center leading-6 text-gray-500 text-sm">
                    通知書No
                  </dt>
                  <dd className="w-3/4 break-all whitespace-pre-wrap">
                    {invoiceChangeLogIdData.docs_no}
                  </dd>
                </div>
              </dl>
              <dl>
                <div className="flex">
                  <dt className="w-1/4 font-medium leading-6 text-gray-500 text-sm">
                    代理店コード
                  </dt>
                  <dd className="w-3/4 break-all whitespace-pre-wrap">
                    {invoiceChangeLogIdData.agency_code}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="py-3 grid grid-cols-2">
              <dl>
                <div className="flex">
                  <dt className="w-1/4 font-medium leading-6 text-gray-500 text-sm">
                    対象年月
                  </dt>
                  <dd className="w-3/4 ">{`${invoiceChangeLogIdData.year_month.slice(
                    0,
                    4
                  )}年${invoiceChangeLogIdData.year_month.slice(5, 7)}月
                `}</dd>
                </div>
              </dl>
              <dl>
                <div className="flex">
                  <dt className="w-1/4 font-medium leading-6 text-gray-500 text-sm ">
                    代理店名
                  </dt>
                  <dd className="w-3/4 break-all whitespace-pre-wrap">
                    {invoiceChangeLogIdData.agency_name}
                  </dd>
                </div>
              </dl>
            </div>
            <div className="py-3 grid grid-cols-2">
              <dl>
                <div className="flex">
                  <dt className="w-1/4 font-medium leading-6 text-gray-500 text-sm">
                    変更日
                  </dt>
                  <dd className="w-3/4">
                    {`${invoiceChangeLogIdData.created_at.slice(
                      0,
                      4
                    )}年${invoiceChangeLogIdData.created_at.slice(
                      5,
                      7
                    )}月${invoiceChangeLogIdData.created_at.slice(
                      8,
                      10
                    )}日 ${invoiceChangeLogIdData.created_at.slice(11)}`}
                  </dd>
                </div>
              </dl>
              <dl>
                <div className="flex">
                  <dt className="w-1/4 font-medium leading-6 text-gray-500 text-sm">
                    変更者
                  </dt>
                  <dd className="w-3/4 break-all whitespace-pre-wrap">
                    {invoiceChangeLogIdData.modifier}
                  </dd>
                </div>
              </dl>
            </div>
          </dl>
          <dl className="mt-1 p-4">
            <div className="py-3 grid grid-cols-8">
              <dt className="font-medium leading-6 text-gray-500 text-sm">
                変更内容
              </dt>
              <dd className="leading-6 col-span-4 break-all whitespace-pre-wrap">
                {invoiceChangeLogIdData.change_details}
              </dd>
            </div>
            <div className="py-3 grid grid-cols-8">
              <dt className="font-medium leading-6 text-gray-500 text-sm">
                備考
              </dt>
              <dd className="leading-6 col-span-7 overflow-y-auto">
                <div className="w-96 -mt-4">
                  <Textarea
                    row={3}
                    isRequired={false}
                    defaultValue={invoiceChangeLogIdData.remarks}
                    inputValue={remarks}
                    setInputValue={setRemarks}
                    placeholder="備考を入力してください"
                    maxLength={255}
                  />
                </div>
              </dd>
            </div>
          </dl>
          <div className="grid grid-flow-row-dense grid-cols-3 gap-60">
            <Button
              onClick={() => closeModal()}
              disabled={!isModalOpen}
              buttonType={"normal"}
            >
              閉じる
            </Button>
            <>
              <Button onClick={() => validation()} disabled={isAlert}>
                更新
              </Button>
              <Alert
                isAlert={isAlert}
                setIsAlert={setIsAlert}
                onClick={onUpdateInvoiceClick}
                title={"支払請求変更履歴更新"}
                submitText={"はい"}
                cancelText={"いいえ"}
                message={message}
                setMessage={setMessage}
              >
                支払請求変更履歴の備考を更新します。
                <br />
                よろしいですか？
              </Alert>
            </>
            {isFileCheckAlert && (
              <>
                <Alert
                  isAlert={isFileCheckAlert}
                  setIsAlert={setIsFileCheckAlert}
                  onClick={async () => {}}
                  title={"ダウンロードエラー"}
                  submitText={""}
                  cancelText={"閉じる"}
                  message={""}
                  setMessage={setMessage}
                >
                  {fileCheckMessage}
                </Alert>
              </>
            )}
            {isValidationAlert && (
              <>
                <Alert
                  isAlert={isValidationAlert}
                  setIsAlert={setIsValidationAlert}
                  onClick={async () => {}}
                  title={"入力チェック"}
                  submitText={""}
                  cancelText={"閉じる"}
                  message={""}
                  setMessage={setMessage}
                >
                  {validationMessage}
                </Alert>
              </>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default InvoiceChangeLogModal;
