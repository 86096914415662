import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const getPaymentCode = async (paymentCode: string) => {
  const path = `/payments/${encodeURIComponent(paymentCode)}`;
  const params = {};
  let result: any;
  await API.get(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
