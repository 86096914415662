import { useState, useContext } from "react";
import LoadingSpinner from "../components/common/LoadingSpinner";
import SearchModule from "../components/common/SearchModule";
import Button from "../components/common/Button";
import NotificationDocsTable from "../components/notificationDocs/NotificationDocsTable";
import Page from "../components/common/Pagenation";
import NotificationDetailModal from "../components/notificationDocs/NotificationDocsModal";
import { useNotificationDocs } from "../hooks/useNotificationDocs";
import { IsAdminContext } from "../components/contexts/IsAdminContext";
import NotificationDocsAllDLModal from "../components/notificationDocs/NotificationDocsAllDLModal";
import Alert from "../components/common/Alert";
import { NotificationDocsIcon } from "../components/Icon";
import { useDBUserContext } from "../components/contexts/DBUserContext";

/**
 * 通知書管理画面
 * @returns
 */
const NotificationDocs = () => {
  const {
    notificationDocsDataList,
    nextOffset,
    previousOffset,
    isLoading,
    getNotificationDocsList,
    selectedItem,
    setSelectedItem,
    inputKeyword,
    setInputKeyword,
    searchItems,
    // 詳細モーダル
    modalOpen,
    isModalOpen,
    setIsModalOpen,
    isModalLoading,
    notificationDocsNoData,
    onUpdateNotificationDocs,
    setSelectedData,
    setIsSuccess,
    message,
    setMessage,
    // 一括モーダル
    isAllDLModalOpen,
    setIsAllDLModalOpen,
    onUpdateAllConfirmed,
    setConfirmedCancel,
    lastMonth,
  } = useNotificationDocs();

  const { isAdmin } = useContext(IsAdminContext);
  const { dbUser } = useDBUserContext();

  const [allConfirmed, setAllConfirmed] = useState(false);
  const [allConfirmedCancel, setAllConfirmedCancel] = useState(false);
  const [yearMonth, setYearMonth] = useState("");

  const allChangeFunc = (isConfirmed: boolean) => {
    setYearMonth(lastMonth(true));
    if (isConfirmed) {
      setAllConfirmed(true);
      setConfirmedCancel(false);
    } else {
      setAllConfirmedCancel(true);
      setConfirmedCancel(true);
    }
  };

  const [modalTitle, setModalTitle] = useState("");

  const openAllDLModal = (type: string) => {
    if (type === "download") {
      setModalTitle("通知書一括ダウンロード");
      setIsAllDLModalOpen(true);
    }
  };

  return (
    <>
      <div className="flex flex-col pt-24 pb-2">
        <div className="flex items-center">
          <NotificationDocsIcon />
          <h2 className="text-2xl font-semibold leading-6 py-4 ml-2">
            通知書管理
          </h2>
          {isAdmin && (
            <>
              <SearchModule
                onClick={() =>
                  getNotificationDocsList(0, selectedItem, inputKeyword)
                }
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                inputKeyword={inputKeyword}
                setInputKeyword={setInputKeyword}
                searchItems={searchItems}
              />
              {dbUser.user_id && dbUser.permissions !== 2 ? (
                <>
                  <div className="mr-3">
                    <Button
                      onClick={() => allChangeFunc(true)}
                      disabled={allConfirmed}
                    >
                      一括確定処理
                    </Button>
                    <Alert
                      isAlert={allConfirmed}
                      setIsAlert={setAllConfirmed}
                      onClick={onUpdateAllConfirmed}
                      title={"一括確定処理"}
                      submitText={"確定処理"}
                      cancelText={"閉じる"}
                      message={message}
                      setMessage={setMessage}
                    >
                      {yearMonth}支払い分の明細を一括で確定処理します。
                      <br />
                      よろしい場合は「確定処理」ボタンを押してください。
                    </Alert>
                  </div>
                  <div>
                    <Button
                      onClick={() => allChangeFunc(false)}
                      buttonType={"delete"}
                      disabled={allConfirmedCancel}
                    >
                      一括確定取消
                    </Button>
                    <Alert
                      isAlert={allConfirmedCancel}
                      setIsAlert={setAllConfirmedCancel}
                      onClick={onUpdateAllConfirmed}
                      title={"一括確定取消"}
                      submitText={"一括取消"}
                      cancelText={"閉じる"}
                      message={message}
                      setMessage={setMessage}
                    >
                      {yearMonth}支払い分の明細を一括で確定取消します。
                      <br />
                      よろしい場合は「一括取消」ボタンを押してください。
                    </Alert>
                  </div>
                </>
              ) : (
                dbUser.user_id && (
                  <div className="flex justify-end">
                    <Button
                      onClick={() => openAllDLModal("download")}
                      buttonType={"normal"}
                      disabled={isAllDLModalOpen}
                    >
                      通知書一括ダウンロード
                    </Button>
                  </div>
                )
              )}
            </>
          )}
        </div>
        {isAdmin && dbUser.user_id && dbUser.permissions !== 2 && (
          <div className="flex justify-end">
            <Button
              onClick={() => openAllDLModal("download")}
              buttonType={"normal"}
              disabled={isAllDLModalOpen}
            >
              通知書一括ダウンロード
            </Button>
          </div>
        )}
      </div>
      {isLoading ? (
        <div className="mt-20">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <NotificationDocsAllDLModal
            allDLModalTitle={modalTitle}
            isAllDLModalOpen={isAllDLModalOpen}
            setIsAllDLOpen={setIsAllDLModalOpen}
          />
          <NotificationDetailModal
            isModalOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            isModalLoading={isModalLoading}
            notificationDocsNoData={notificationDocsNoData}
            onUpdateNotificationDocs={onUpdateNotificationDocs}
            setSelectedData={setSelectedData}
            setIsSuccess={setIsSuccess}
            message={message}
            setMessage={setMessage}
          />
          <NotificationDocsTable
            notificationDocsDataList={notificationDocsDataList}
            modalOpen={modalOpen}
          />
          <Page
            onClick={getNotificationDocsList}
            previousOffset={previousOffset}
            nextOffset={nextOffset}
            selectedItem={selectedItem}
            keyword={inputKeyword}
          />
        </>
      )}
    </>
  );
};

export default NotificationDocs;
