import { paymentData, userData } from "../common/initialData";
import { useState, useEffect, useCallback, useContext } from "react";
import { getPaymentCode } from "../api/getPaymentCode";
import { putPaymentCode } from "../api/putPaymentCode";
import { getUsers } from "../api/getUsers";
import { postUser } from "../api/postUser";
import { putUser } from "../api/putUser";
import { putUserResetPassword } from "../api/putUserResetPassword";

import { useDBUserContext } from "../components/contexts/DBUserContext";
import { IsAdminContext } from "../components/contexts/IsAdminContext";
import { UserData } from "../common/type";

/**
 * 支払元情報フック
 * @returns
 */
export const usePayment = () => {
  const { dbUser } = useDBUserContext();
  const { isAdmin } = useContext(IsAdminContext);

  const [isLoading, setIsLoading] = useState(true);
  const [paymentDataInfo, setPaymentDataInfo] = useState(paymentData);

  const [isModalLoading, setIsModalLoading] = useState(true);

  // ユーザー登録モーダルリロード判定
  const [isUserModalReload, setIsUserModalReload] = useState(false);

  // 編集に必要なState
  const [paymentName, setPaymentName] = useState("");
  const [paymentNameKana, setPaymentNameKana] = useState("");
  const [paymentPostalCode, setPaymentPostalCode] = useState("");
  const [paymentAddress, setPaymentAddress] = useState("");
  const [paymentPhoneNumber, setPaymentPhoneNumber] = useState("");
  const [paymentFinancialFacilityName, setPaymentFinancialFacilityName] =
    useState("");
  const [paymentBranchName, setPaymentBranchName] = useState("");
  const [paymentAccountType, setPaymentAccountType] = useState("");
  const [paymentBankAccountNumber, setPaymentBankAccountNumber] = useState("");
  const [paymentRegistrationNumber, setPaymentRegistrationNumber] =
    useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (dbUser.user_id && isAdmin) {
      getPayment(dbUser.payment_code, setIsLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser]);

  /* 会社情報取得 */
  const getPayment = useCallback(
    async (
      payment_code: string,
      setIsLoading: (value: React.SetStateAction<boolean>) => void
    ) => {
      try {
        setIsLoading(true);
        const payment = await getPaymentCode(payment_code);
        const paymentBody = await JSON.parse(payment);
        setPaymentDataInfo({
          name: paymentBody.name,
          name_kana: paymentBody.name_kana,
          postal_code: paymentBody.postal_code,
          address: paymentBody.address,
          phone_number: paymentBody.phone_number,
          financial_facility_name: paymentBody.financial_facility_name,
          branch_name: paymentBody.branch_name,
          account_type: paymentBody.account_type,
          bank_account_number: paymentBody.bank_account_number,
          registration_number: paymentBody.registration_number,
        });
      } catch (e) {
        console.error(e);
        setPaymentDataInfo(paymentData);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  // 会社情報編集後、モーダルを閉じたとき、判定
  const [isReload, setIsReload] = useState(false);

  /* 会社情報編集*/
  const onUpdatePayment = async () => {
    try {
      const putData = {
        name: paymentName,
        name_kana: paymentNameKana,
        postal_code: paymentPostalCode,
        address: paymentAddress,
        phone_number: paymentPhoneNumber,
        financial_facility_name: paymentFinancialFacilityName,
        branch_name: paymentBranchName,
        account_type: paymentAccountType,
        bank_account_number: paymentBankAccountNumber,
        registration_number: paymentRegistrationNumber,
      };
      const res = await putPaymentCode(dbUser.payment_code, putData);
      if (res.statusCode === 200) {
        setMessage("会社情報を更新しました。");
        setIsReload(true);
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchPayment = async () => {
      if (isModalOpen) {
        await getPayment(dbUser.payment_code, setIsModalLoading);
      }
      if (isReload && !isModalOpen) {
        setIsReload(false);
        await getPayment(dbUser.payment_code, setIsLoading);
      }
    };

    fetchPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const [userEmailValue, setUserEmailValue] = useState("");
  const [userNameValue, setUserNameValue] = useState("");
  const [userPermissionsValue, setUserPermissionsValue] = useState("");
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isUserModalLoading, setIsUserModalLoading] = useState(true);
  const [usersListData, setUsersListData] = useState([userData]);

  /* 会社情報ユーザーモーダルオープン時に管理ユーザー一覧取得 */
  const userModalOpen = async () => {
    setIsUserModalOpen(!isUserModalOpen);
    getUserIdDetail(dbUser.payment_code);
  };

  /* 管理ユーザーモーダルで、管理ユーザー一覧取得 */
  const getUserIdDetail = async (code: string) => {
    try {
      setIsUserModalLoading(true);
      setUsersListData([]);
      const users = await getUsers(code, "payment");
      const usersBody = await JSON.parse(users);
      setUsersListData(
        usersBody.map((usersData: UserData) => {
          return {
            user_id: usersData.user_id,
            user_name: usersData.user_name,
            agency_code: "",
            payment_code: usersData.payment_code,
            permissions: usersData.permissions,
            enabled: usersData.enabled === 1 ? true : false,
            invalid_datetime: usersData.invalid_datetime,
            createdAt: new Date(usersData.created_at).toLocaleDateString(
              "ja-JP"
            ),
            updatedAt: "",
            status: usersData.status,
          };
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsUserModalLoading(false);
    }
  };

  const [resetUserEditingList, setResetUserEditingList] = useState(false);

  /* 管理ユーザー登録モーダルで、登録ボタン押下 */
  const onRegisterUserClick = async () => {
    try {
      const res = await postUser(
        userEmailValue,
        dbUser.payment_code,
        userNameValue,
        userPermissionsValue
      );
      if (res.statusCode === 200) {
        setMessage("管理ユーザーを登録しました。");
        setIsUserModalReload(true);
        setUserEmailValue("");
        setUserNameValue("");
        setUserPermissionsValue("");
        setResetUserEditingList(true);
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  /* 管理ユーザー更新モーダルで、修正ボタン押下 */
  const [updateUserId, setUpdateUserId] = useState("");
  const [updateUserName, setUpdateUserName] = useState("");
  const [updateUserStatus, setUpdateUserStatus] = useState(false);
  const [updateUserPermissions, setUpdateUserPermissions] = useState(0);
  const onUpdateUserClick = async () => {
    try {
      const res = await putUser(
        updateUserId,
        dbUser.payment_code,
        updateUserStatus,
        updateUserName,
        updateUserPermissions
      );
      if (res.statusCode === 200) {
        setMessage("選択した管理ユーザーを修正しました。");
        setIsUserModalReload(true);
        setUpdateUserId("");
        setUpdateUserName("");
        setUpdateUserStatus(false);
        setUpdateUserPermissions(0);
        setResetUserEditingList(true);
      } else {
        setMessage(res?.message);
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  const resetPassword = async () => {
    try {
      const res = await putUserResetPassword(
        updateUserId,
        dbUser.payment_code,
        updateUserName
      );

      if (res.statusCode === 200) {
        setIsUserModalReload(true);
        setMessage("仮パスワードのメールを再送信しました。");
      } else {
        setMessage(
          "エラーが発生しました。\n再度実施するか管理者へ連絡してください。"
        );
      }
    } catch (e) {
      console.error(e);
      setMessage(
        "エラーが発生しました。\n再度実施するか管理者へ連絡してください。\n" + e
      );
    }
  };

  // 登録、更新した場合、管理ユーザー一覧リロード
  useEffect(() => {
    if (isUserModalReload === true) {
      getUserIdDetail(dbUser.payment_code);
      setIsUserModalReload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserModalReload]);

  return {
    // 会社情報データ
    paymentDataInfo,
    // 会社情報編集
    isLoading,
    isModalLoading,
    isModalOpen,
    setIsModalOpen,
    paymentName,
    setPaymentName,
    paymentNameKana,
    setPaymentNameKana,
    paymentPostalCode,
    setPaymentPostalCode,
    paymentAddress,
    setPaymentAddress,
    paymentPhoneNumber,
    setPaymentPhoneNumber,
    paymentFinancialFacilityName,
    setPaymentFinancialFacilityName,
    paymentBranchName,
    setPaymentBranchName,
    paymentAccountType,
    setPaymentAccountType,
    paymentBankAccountNumber,
    setPaymentBankAccountNumber,
    paymentRegistrationNumber,
    setPaymentRegistrationNumber,
    onUpdatePayment,
    message,
    setMessage,
    // 管理ユーザー登録画面
    usersListData,
    isUserModalLoading,
    userModalOpen,
    isUserModalOpen,
    setIsUserModalOpen,
    userEmailValue,
    setUserEmailValue,
    userNameValue,
    setUserNameValue,
    userPermissionsValue,
    setUserPermissionsValue,
    setUpdateUserId,
    setUpdateUserName,
    setUpdateUserStatus,
    setUpdateUserPermissions,
    resetUserEditingList,
    setResetUserEditingList,
    onRegisterUserClick,
    onUpdateUserClick,
    resetPassword,
  };
};
