import { FC, useCallback, useState, useLayoutEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import { Auth, Hub } from "aws-amplify";
import { useCognitoUserContext } from "../components/contexts/CognitoUserContext";
import { useDBUserContext } from "../components/contexts/DBUserContext";
import { useIsAdminContext } from "../components/contexts/IsAdminContext";
import { getUserId } from "../api/getUserId";

import { AdminPages, AdminPagesType, PageType, UserPages } from "./Pages";
import { userData } from "../common/initialData";
import Dummy from "../containers/Dummy";
type Props = {
  isAdmin: boolean;
};

/**
 * ルート定義
 * @returns
 */
const Root: FC<Props> = ({ isAdmin }) => {
  const navigate = useNavigate();
  const { setCognitoUser } = useCognitoUserContext();
  const { setDBUser } = useDBUserContext();
  const { setIsAdmin } = useIsAdminContext();

  const [pages, setPages] = useState<[string, PageType][]>([
    [
      "TOP",
      {
        NAME: "トップ",
        ICON: null,
        PATH: "*",
        CONTAINER: <Dummy />,
        IS_DISPLAY: true,
        PERMISSIONS: [],
      },
    ],
  ]); //setPagesでページがセットされるまで画面が真っ白になるのでダミーを設定

  /** ログインユーザの情報を取得 */
  const getUser = useCallback(async () => {
    try {
      //Cognito情報取得
      const cognitoUser = await Auth.currentAuthenticatedUser();
      // Userテーブル取得
      const dbUserResult = await getUserId(
        String(cognitoUser?.attributes?.email)
      );
      const dbUser = await JSON.parse(dbUserResult);
      setDBUser(dbUser);
      setCognitoUser(cognitoUser);

      //管理者処理
      if (
        String(cognitoUser?.pool?.userPoolId) ===
        process.env.REACT_APP_ADMIN_USER_POOL_ID
      ) {
        setIsAdmin(true);
        //管理者権限と閲覧権限の制御
        const filteredPages = Object.fromEntries(
          Object.entries(AdminPages).filter(([key, Page]) =>
            isAdmin
              ? Page.PERMISSIONS.includes(dbUser.permissions) ||
                Page.PERMISSIONS.length === 0
              : true
          )
        ) as AdminPagesType;
        setPages(Object.entries(filteredPages));
      } else {
        setPages(Object.entries(UserPages));
      }
    } catch (e) {
      return console.log("Not signed in", e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* ユーザー認証後の制御 */
  useLayoutEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          // getUser();
          break;
        case "signOut":
          setCognitoUser(null);
          setDBUser(userData);
          if (isAdmin) {
            navigate("/admin");
          } else {
            navigate("/");
          }
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser]);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {pages.map(([key, Page]) => {
          return (
            <Route key={key} path={Page.PATH} element={Page.CONTAINER}>
              {Page.NAME}
            </Route>
          );
        })}
      </Route>
    </Routes>
  );
};

export default Root;
