import React, { FC, useEffect, useState } from "react";
import Button from "../common/Button";
import Modal from "../common/Modal";
import LoadingSpinner from "../common/LoadingSpinner";
import Input from "../common/Input";
import Alert from "../common/Alert";
import { UserData } from "../../common/type";
import * as luxon from "luxon";
import { CancelIcon, DecisionIcon, EditIcon } from "../Icon";
import { FormControl, MenuItem, Select } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

type Props = {
  modalTitle: string;
  usersListData: UserData[];
  isUserModalOpen: boolean;
  setIsUserModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isUserModalLoading: boolean;
  userEmailValue: string;
  setUserEmailValue: React.Dispatch<React.SetStateAction<string>>;
  userNameValue: string;
  setUserNameValue: React.Dispatch<React.SetStateAction<string>>;
  userPermissionsValue: string;
  setUserPermissionsValue: React.Dispatch<React.SetStateAction<string>>;
  setUpdateUserId: React.Dispatch<React.SetStateAction<string>>;
  setUpdateUserName: React.Dispatch<React.SetStateAction<string>>;
  setUpdateUserStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateUserPermissions: React.Dispatch<React.SetStateAction<number>>;
  resetUserEditingList: boolean;
  setResetUserEditingList: React.Dispatch<React.SetStateAction<boolean>>;
  onRegisterUserClick: () => Promise<void>;
  onUpdateUserClick: () => Promise<void>;
  resetPassword: () => Promise<void>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

/**
 * 会社情報、管理ユーザーモーダル
 * @param props
 * @returns
 */
const PaymentUserModal: FC<Props> = ({
  modalTitle,
  usersListData,
  isUserModalOpen,
  setIsUserModalOpen,
  isUserModalLoading,
  userEmailValue,
  setUserEmailValue,
  userNameValue,
  setUserNameValue,
  userPermissionsValue,
  setUserPermissionsValue,
  setUpdateUserId,
  setUpdateUserName,
  setUpdateUserStatus,
  setUpdateUserPermissions,
  resetUserEditingList,
  setResetUserEditingList,
  onRegisterUserClick,
  onUpdateUserClick,
  resetPassword,
  message,
  setMessage,
}) => {
  const [isAlert, setIsAlert] = useState(false);
  const [isUpdateAlert, setIsUpdateAlert] = useState(false);
  const [isValidationAlert, setIsValidationAlert] = useState(false);
  const [isResetPasswordAlert, setIsResetPasswordAlert] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const [isUsersEditingList, setIsUsersEditingList] = useState(
    Array(usersListData.length).fill(false)
  );

  const validation = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (userEmailValue.length <= 0) {
      setValidationMessage("ユーザーIDが入力されていません。");
      setIsValidationAlert(true);
    } else if (!emailRegex.test(userEmailValue)) {
      setValidationMessage(
        "ユーザーID(メールアドレス)の形式が正しくありません。"
      );
      setIsValidationAlert(true);
    } else if (userNameValue.length <= 0) {
      setValidationMessage("ユーザー名が入力されていません。");
      setIsValidationAlert(true);
    } else if (userPermissionsValue.length <= 0) {
      setValidationMessage("権限が選択されていません。");
      setIsValidationAlert(true);
    } else if (userEmailValue.length > 255) {
      setValidationMessage("ユーザーIDが255文字を超えています。");
      setIsValidationAlert(true);
    } else if (userNameValue.length > 255) {
      setValidationMessage("ユーザー名が255文字を超えています。");
      setIsValidationAlert(true);
    } else {
      setValidationMessage("");
      setIsAlert(true);
    }
  };

  type PermissionsMap = { [key: string]: number };
  type StatusMap = { [key: string]: number };
  // ユーザー権限、ステータス
  const [userPermissions, setUserPermissions] = useState<PermissionsMap>({});
  const [userStatus, setUserStatus] = useState<StatusMap>({});
  // 変更前（現在値）のユーザー権限、ステータス
  const [nowUserPermission, setNowUserPermission] = useState<PermissionsMap>(
    {}
  );
  const [nowUserStatus, setNowUserStatus] = useState<StatusMap>({});

  // 変更前（現在値）のユーザー権限、ステータスを保存
  useEffect(() => {
    const permissions: PermissionsMap = {};
    const status: StatusMap = {};
    usersListData.forEach((user, i) => {
      permissions[user.user_id] = user.permissions;
      status[user.user_id] = user.enabled;
    });
    setUserPermissions(permissions);
    setUserStatus(status);
    setNowUserPermission(permissions);
    setNowUserStatus(status);
  }, [usersListData]);

  // リロード後、選択解除
  useEffect(() => {
    if (isUserModalLoading) {
      setIsUsersEditingList(Array(usersListData.length).fill(false));
      setResetUserEditingList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserModalLoading, resetUserEditingList]);

  // ユーザー更新データセット
  const updateUserData = async () => {
    const userindex = isUsersEditingList.findIndex((data) => data === true);
    const userId = usersListData[userindex]["user_id"];
    const userName = usersListData[userindex]["user_name"];
    const permissionsValue = userPermissions[userId];
    const statusValue = userStatus[userId];
    // アラート権限状態、変更履歴セット
    if (nowUserPermission[userId] !== permissionsValue) {
      setAlertChangePermissions(permissionsValue !== 2 ? "管理者" : "閲覧");
    } else {
      setAlertChangePermissions("");
    }
    if (Number(nowUserStatus[userId]) !== Number(statusValue)) {
      setAlertChangeStatus(statusValue ? "有効" : "無効");
    } else {
      setAlertChangeStatus("");
    }
    setUpdateUserId(userId);
    setUpdateUserName(userName);
    setUpdateUserStatus(statusValue ? true : false);
    setUpdateUserPermissions(permissionsValue);
  };

  const [isUserChanged, setIsUserChanged] = useState(false);
  const [alertUserId, setAlertUserId] = useState("");
  const [alertUserName, setAlertUserName] = useState("");
  const [alertPermissions, setAlertPermissions] = useState("");
  const [alertStatus, setAlertStatus] = useState("");
  const [alertChangePermissions, setAlertChangePermissions] = useState("");
  const [alertChangeStatus, setAlertChangeStatus] = useState("");
  const [alertResetUserId, setAlertResetUserId] = useState("");

  useEffect(() => {
    if (
      isUserModalOpen &&
      alertUserId &&
      alertUserName &&
      alertPermissions &&
      alertStatus
    ) {
      setIsUpdateAlert(true);
    }
  }, [
    isUserModalOpen,
    alertUserId,
    alertUserName,
    alertPermissions,
    alertStatus,
  ]);

  const [selectPermissionUserId, setSelectPermissionUserId] = useState("");
  const [selectStatusUserId, setSelectStatusUserId] = useState("");

  // 権限、状態を変更した場合
  useEffect(() => {
    if (selectPermissionUserId) {
      // 権限を変化した場合
      if (
        userPermissions[selectPermissionUserId] !==
        nowUserPermission[selectPermissionUserId]
      ) {
        setIsUserChanged(true);
        setSelectPermissionUserId("");
      } else {
        // 状態に変化がなければ、チェックアイコン非表示
        if (
          Number(userStatus[selectPermissionUserId]) ===
          Number(nowUserStatus[selectPermissionUserId])
        ) {
          setIsUserChanged(false);
        }
        setSelectPermissionUserId("");
      }
    }
    if (selectStatusUserId) {
      // 状態を変更した場合
      if (
        Number(userStatus[selectStatusUserId]) !==
        Number(nowUserStatus[selectStatusUserId])
      ) {
        setIsUserChanged(true);
        setSelectStatusUserId("");
      } else {
        // 権限に変化がなければ、チェックアイコン非表示
        if (
          userPermissions[selectStatusUserId] ===
          nowUserPermission[selectStatusUserId]
        ) {
          setIsUserChanged(false);
        }
        setSelectStatusUserId("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPermissionUserId, selectStatusUserId]);

  const alertDataClear = () => {
    setAlertUserId("");
    setAlertUserName("");
    setAlertPermissions("");
    setAlertStatus("");
    setAlertChangePermissions("");
    setAlertChangeStatus("");
  };

  return (
    <Modal isOpen={isUserModalOpen}>
      <h3 className="text-2xl font-semibold leading-6 border-b border-gray-300 text-text pb-2">
        {modalTitle}
      </h3>
      <>
        <div className="flex mt-8">
          <dl>
            <div className="px-4 py-2 grid grid-cols-6 items-center">
              <dt className="font-medium leading-6 col-span-1 text-gray-500 text-sm">
                ユーザーID(メールアドレス)
              </dt>
              <dd className="leading-6 col-span-4 ml-4">
                <Input
                  id="userEmail"
                  type="email"
                  autoComplete="email"
                  isRequired={true}
                  defaultValue={""}
                  inputValue={userEmailValue}
                  setInputValue={setUserEmailValue}
                  placeholder="ユーザーID(メールアドレス)を入力してください"
                  maxLength={255}
                />
              </dd>
              <dd className="leading-6 col-span-1 ml-4">
                <Button
                  onClick={() => validation()}
                  disabled={!isUserModalOpen || isAlert}
                  buttonType={"accent"}
                >
                  登録
                </Button>
              </dd>
            </div>
            <div className="px-4 py-2 grid grid-cols-6 items-center">
              <dt className="font-medium leading-6 col-span-1 text-gray-500 text-sm">
                ユーザー名
              </dt>
              <dd className="leading-6 col-span-4 ml-4">
                <Input
                  id="userName"
                  type="username"
                  autoComplete="username"
                  isRequired={true}
                  defaultValue={""}
                  inputValue={userNameValue}
                  placeholder="ユーザー名を入力してください"
                  setInputValue={setUserNameValue}
                  maxLength={255}
                />
              </dd>
            </div>
            <div className="px-4 py-2 grid grid-cols-6 items-center">
              <dt className="font-medium leading-6 col-span-1 text-gray-500 text-sm">
                権限
              </dt>
              <dd className="leading-6 col-span-4 ml-4">
                <FormControl size="small">
                  <Select
                    value={userPermissionsValue}
                    onChange={(e) => {
                      setUserPermissionsValue(e.target.value as string);
                    }}
                    displayEmpty
                    className="w-full text-left"
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      <em className="text-textSub">権限を選択してください</em>
                    </MenuItem>
                    <MenuItem key={0} value={1}>
                      管理者
                    </MenuItem>
                    <MenuItem key={1} value={2}>
                      閲覧
                    </MenuItem>
                  </Select>
                </FormControl>
              </dd>
            </div>
          </dl>

          <Alert
            isAlert={isAlert}
            setIsAlert={setIsAlert}
            onClick={onRegisterUserClick}
            title={"管理ユーザー登録"}
            submitText={"はい"}
            cancelText={"いいえ"}
            message={message}
            setMessage={setMessage}
          >
            管理ユーザーを登録します。
            <br />
            よろしいですか？
          </Alert>
        </div>
        <div className="mt-6 flow-root">
          <div className="-my-2 -mx-8">
            <div className="inline-block min-w-full py-2 align-middle px-8">
              {isUserModalLoading ? (
                <div className="my-20">
                  <LoadingSpinner />
                </div>
              ) : (
                <div className="overflow-auto min-h-[8vh] max-h-[40vh]">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="sticky top-0 bg-white z-50 shadow-sm">
                      <tr className="text-gray-500 text-xs">
                        <th
                          scope="col"
                          className="w-[30%] px-3 py-3.5 text-left"
                        >
                          ユーザーID(メールアドレス)
                        </th>
                        <th
                          scope="col"
                          className="w-[16%] px-3 py-3.5 text-left"
                        >
                          ユーザー名
                        </th>
                        <th
                          scope="col"
                          className="w-[12%] px-3 py-3.5 text-left"
                        >
                          権限
                        </th>
                        <th
                          scope="col"
                          className="w-[12%] px-3 py-3.5 text-left"
                        >
                          ステータス
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left"
                        >
                          無効日時
                        </th>
                        <th
                          scope="col"
                          className="w-[20%] px-3 py-3.5 text-left"
                        >
                          仮パスワード再通知
                        </th>
                        <th
                          scope="col"
                          className="w-[10%] px-3 py-3.5 text-left"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {usersListData.map((data, i) => {
                        return (
                          <tr key={i} className="h-16">
                            <td className="whitespace-wrap px-3 py-4 text-sm text-left">
                              {data.user_id}
                            </td>
                            <td className="whitespace-wrap px-3 py-2 text-sm text-left">
                              {data.user_name}
                            </td>
                            <td className="whitespace-wrap px-3 py-2 text-sm text-left">
                              {isUsersEditingList[i] ? (
                                <FormControl size="small">
                                  <Select
                                    value={userPermissions[data.user_id]}
                                    onChange={(e) => {
                                      setUserPermissions({
                                        ...userPermissions,
                                        [data.user_id]: e.target
                                          .value as number,
                                      });
                                      setSelectPermissionUserId(data.user_id);
                                    }}
                                    displayEmpty
                                    className="text-left"
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      <em className="text-textSub">権限</em>
                                    </MenuItem>
                                    <MenuItem key={0} value={1}>
                                      管理者
                                    </MenuItem>
                                    <MenuItem key={1} value={2}>
                                      閲覧
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              ) : data.permissions !== 2 ? (
                                "管理者"
                              ) : (
                                "閲覧"
                              )}
                            </td>
                            <td className="whitespace-wrap px-3 py-2 text-sm text-left">
                              {isUsersEditingList[i] ? (
                                <FormControl size="small">
                                  <Select
                                    value={
                                      userStatus[data.user_id]
                                        ? "true"
                                        : "false"
                                    }
                                    onChange={(e) => {
                                      setUserStatus({
                                        ...userStatus,
                                        [data.user_id]:
                                          e.target.value === "true" ? 1 : 0,
                                      });
                                      setSelectStatusUserId(data.user_id);
                                    }}
                                    displayEmpty
                                    className="text-left"
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem disabled value="">
                                      <em className="text-textSub">
                                        ステータス
                                      </em>
                                    </MenuItem>
                                    <MenuItem key={0} value={"true"}>
                                      有効
                                    </MenuItem>
                                    <MenuItem key={1} value={"false"}>
                                      無効
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              ) : data.enabled ? (
                                "有効"
                              ) : (
                                "無効"
                              )}
                            </td>
                            <td className="whitespace-wrap px-3 py-2 text-sm text-left">
                              {data.invalid_datetime &&
                                luxon.DateTime.fromSQL(
                                  data.invalid_datetime
                                ).toFormat("yyyy/MM/dd HH:mm:ss")}
                            </td>
                            <td className="whitespace-wrap px-3 py-2 text-sm text-left">
                              {data.status === "FORCE_CHANGE_PASSWORD" ? (
                                <Button
                                  onClick={() => {
                                    setAlertResetUserId(data.user_id);
                                    setUpdateUserId(data.user_id);
                                    setUpdateUserName(data.user_name);
                                    setIsResetPasswordAlert(true);
                                  }}
                                  disabled={!isUserModalOpen}
                                  buttonType={"normal"}
                                >
                                  送信
                                </Button>
                              ) : null}
                            </td>
                            <td className="flex items-center justify-end gap-4 whitespace-nowrap pr-6 h-16 text-sm text-left relative text-gray-500">
                              {isUsersEditingList[i] ? (
                                <>
                                  {isUserChanged && (
                                    <button
                                      onClick={() => {
                                        setAlertUserId(data.user_id);
                                        setAlertUserName(data.user_name);
                                        setAlertPermissions(
                                          data.permissions !== 2
                                            ? "管理者"
                                            : "閲覧"
                                        );
                                        setAlertStatus(
                                          data.enabled ? "有効" : "無効"
                                        );
                                        updateUserData();
                                      }}
                                    >
                                      <DecisionIcon />
                                    </button>
                                  )}
                                  <button
                                    onClick={() => {
                                      setIsUsersEditingList([]);
                                      setIsUserChanged(false);
                                      setAlertChangePermissions("");
                                      setAlertChangeStatus("");
                                      setUserPermissions(nowUserPermission);
                                      setUserStatus(nowUserStatus);
                                    }}
                                  >
                                    <CancelIcon />
                                  </button>
                                </>
                              ) : (
                                <Tooltip
                                  title={
                                    data.permissions === 0 &&
                                    "最高管理者のため変更できません。"
                                  }
                                >
                                  <button
                                    onClick={() => {
                                      const updatedIsEditingList = [
                                        ...isUsersEditingList,
                                      ].fill(false);
                                      updatedIsEditingList[i] = true;
                                      setIsUsersEditingList(
                                        updatedIsEditingList
                                      );
                                      setIsUserChanged(false);
                                      setAlertChangePermissions("");
                                      setAlertChangeStatus("");
                                      setUserPermissions(nowUserPermission);
                                      setUserStatus(nowUserStatus);
                                    }}
                                    className={`w-6 h-6 ${
                                      data.permissions === 0
                                        ? "text-gray-300"
                                        : ""
                                    }`}
                                    disabled={data.permissions === 0}
                                  >
                                    <EditIcon />
                                  </button>
                                </Tooltip>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              <Alert
                isAlert={isUpdateAlert}
                setIsAlert={setIsUpdateAlert}
                onClick={onUpdateUserClick}
                title={"管理ユーザー更新"}
                submitText={"はい"}
                cancelText={"いいえ"}
                message={message}
                setMessage={setMessage}
                onClose={alertDataClear}
              >
                ユーザーを更新します。よろしいでしょうか？
                <br />
                ※変更箇所をよく確認してから更新してください。
                <br />
                <br />
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody className="divide-y divide-gray-200">
                    <tr>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-gray-500 text-xs">
                        ユーザーID
                      </td>
                      <td
                        colSpan={2}
                        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      >
                        {alertUserId}
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-gray-500 text-xs">
                        ユーザー名
                      </td>
                      <td
                        colSpan={2}
                        className="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                      >
                        {alertUserName}
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-gray-500 text-xs">
                        権限
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {alertChangePermissions ? (
                          <>
                            <div>{alertPermissions}</div>
                            <div>↓</div>
                            <div className="font-semibold text-red-500">
                              {alertChangePermissions}
                            </div>
                          </>
                        ) : (
                          <div>{alertPermissions}</div>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-gray-500 text-xs">
                        ステータス
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {alertChangeStatus ? (
                          <>
                            <div>{alertStatus}</div>
                            <div>↓</div>
                            <div className="font-semibold text-red-500">
                              {alertChangeStatus}
                            </div>
                          </>
                        ) : (
                          <div>{alertStatus}</div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Alert>
              <Alert
                isAlert={isResetPasswordAlert}
                setIsAlert={setIsResetPasswordAlert}
                onClick={() => resetPassword()}
                title={"仮パスワードメール再送信"}
                submitText={"はい"}
                cancelText={"いいえ"}
                message={message}
                setMessage={setMessage}
              >
                仮パスワードのメールを再送信します。
                <br />
                よろしいですか？
                <br />
                {alertResetUserId}
              </Alert>
            </div>
          </div>
        </div>
        <div className="mt-6 grid grid-flow-row-dense grid-cols-3 gap-60">
          <Button
            onClick={() => {
              setIsUserModalOpen(!isUserModalOpen);
              setIsUsersEditingList([]);
              setUserEmailValue("");
              setUserNameValue("");
              setUserPermissionsValue("");
            }}
            disabled={!isUserModalOpen}
            buttonType={"normal"}
          >
            閉じる
          </Button>
        </div>
        {isValidationAlert && (
          <>
            <Alert
              isAlert={isValidationAlert}
              setIsAlert={setIsValidationAlert}
              onClick={async () => {}}
              title={"入力チェック"}
              submitText={""}
              cancelText={"閉じる"}
              message={""}
              setMessage={setMessage}
            >
              {validationMessage}
            </Alert>
          </>
        )}
      </>
    </Modal>
  );
};

export default PaymentUserModal;
