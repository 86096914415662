/* 取得したユーザー情報 */
export const userData = {
  user_id: "",
  user_name: "",
  agency_code: "",
  payment_code: "",
  permissions: 99,
  enabled: 0,
  invalid_datetime: "",
  created_at: "",
  updated_at: "",
  status: "",
};

/* 取得したお知らせ */
export const newsData = {
  id: 0,
  agencyCode: "",
  agencyName: "",
  subject: "",
  content: "",
  postUserId: "",
  postUserName: "",
  createdAt: "",
  updatedAt: "",
};

/* 投稿するお知らせ */
export const postNewsData = {
  agency_code: "",
  subject: "",
  content: "",
  post_user_id: "",
};

/* 取得した代理店 */
export const agencyData = {
  code: "",
  payment_code: "",
  name: "",
  registration_number: "",
  trading_stop_date: "",
  createdAt: "",
  updatedAt: "",
};

/* 代理店リストボックス */
export const agencyMenuItem = { code: "", name: "" };

/* 条件通知書 */
export const termsDocData = {
  terms_docs_id: "",
  terms_docs_name: "",
  agency_code: "",
  agency_name: "",
  applicable_period: "",
  post_user_id: "",
  post_user_name: "",
  created_at: "",
  updated_at: "",
};

/* 取得した会社情報 */
export const paymentData = {
  name: "",
  name_kana: "",
  postal_code: "",
  address: "",
  phone_number: "",
  financial_facility_name: "",
  branch_name: "",
  account_type: "",
  bank_account_number: "",
  registration_number: "",
};

/* 取得した条件通知書 */
export const termsDocsData = {
  terms_docs_id: "",
  terms_docs_name: "",
  agency_name: "",
  agency_code: "",
  post_user_id: "",
  post_user_name: "",
  applicable_period: "",
  created_at: "",
  updated_at: "",
};

/* 取得した通知書 */
export const notificationDocsData = {
  notification_doc_no: "",
  year_month: "",
  confirmed_date: "",
  agency_code: "",
  agency_name: "",
  payment_date: "",
  amount_paid_tax_included: "",
  tax_amount: "",
  created_at: "",
  updated_at: "",
};

/* 取得した単一通知書 */
export const notificationDocsDetailNoData = {
  notification_doc_no: "",
  year_month: "",
  confirmed_date: "",
  agency_code: "",
  agency_name: "",
  payment_date: "",
  amount_paid_tax_included: "",
  tax_amount: "",
  products: [
    {
      product_name: "",
      payment_category: "",
      tax_category: "",
      amount_paid_tax_included: "",
      tax_amount: "",
    },
  ],
  issue_date: "",
  csv_issue_date: "",
  created_at: "",
  updated_at: "",
};

/* 取得した公開ファイル */
export const releaseFilesData = {
  release_file_id: "",
  agency_code: "",
  agency_name: "",
  file_category: "",
  file_name: "",
  comment: "",
  post_user_id: "",
  post_user_name: "",
  created_at: "",
  updated_at: "",
};

/* 取得した支払請求変更履歴 */
export const invoiceChangeLogData = {
  id: 0,
  docs_no: "",
  year_month: "",
  agency_code: "",
  agency_name: "",
  modifier: "",
  created_at: "",
  updated_at: "",
};

/* 取得した支払請求変更履歴詳細 */
export const invoiceChangeLogDetailData = {
  id: 0,
  docs_no: "",
  year_month: "",
  agency_code: "",
  agency_name: "",
  change_details: "",
  modifier: "",
  remarks: "",
  file_name: "",
  details_file_name: "",
  created_at: "",
  updated_at: "",
};
