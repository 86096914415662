import * as luxon from "luxon";
export const useTermsDocs = () => {
  const today = luxon.DateTime.now();
  const applicablePeriodLabels = [
    `${today.year}年1月 - 3月`,
    `${today.year}年4月 - 6月`,
    `${today.year}年7月 - 9月`,
    `${today.year}年10月 - 12月`,
    `${today.plus({ year: 1 }).year}年1月 - 3月`,
  ];
  const applicablePeriodValues = [
    `${today.year}01`,
    `${today.year}04`,
    `${today.year}07`,
    `${today.year}10`,
    `${today.plus({ year: 1 }).year}01`,
  ];

  const getSummaries = () => {
    const applicablePeriodItemIndex = getApplicablePeriodItemIndexes();
    const applicablePeriod = applicablePeriodValues[applicablePeriodItemIndex];
    const summary: { label: string; applicablePeriod: string }[] = [];
    for (let i = 0; i < 8; i++) {
      let dt = luxon.DateTime.fromFormat(applicablePeriod, "yyyyMM");
      dt = dt.plus({ month: 3 }).minus({ month: i * 3 });
      summary.push({
        label: `${dt.year}年${dt.month}月 〜 ${dt.month + 2}月`,
        applicablePeriod: `${dt.toFormat("yyyyMM")}`,
      });
    }
    return summary;
  };

  const getApplicablePeriodItemIndexes = () => {
    switch (today.month) {
      case 1:
      case 2:
      case 3:
        return 0;
      case 4:
      case 5:
      case 6:
        return 1;
      case 7:
      case 8:
      case 9:
        return 2;
      case 10:
      case 11:
      case 12:
        return 3;
    }
    return -1;
  };

  return {
    getSummaries,
    applicablePeriodLabels,
    applicablePeriodValues,
    getApplicablePeriodItemIndexes,
  };
};
