import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

export const IsAdminContext = createContext(
  {} as {
    isAdmin: boolean;
    setIsAdmin: Dispatch<SetStateAction<boolean>>;
  }
);

export const useIsAdminContext = () => {
  return useContext(IsAdminContext);
};

type Props = {
  children: ReactNode;
};

/**
 * 管理者権限フラグ
 * @param props
 * @returns
 */
export const IsAdminProvider: FC<Props> = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  return (
    <IsAdminContext.Provider value={{ isAdmin, setIsAdmin }}>
      {children}
    </IsAdminContext.Provider>
  );
};
