import { FC } from "react";

type Props = {
  onClick: (...args: any[]) => void;
  previousOffset: number;
  nextOffset: number;
  selectedItem?: string;
  keyword?: string;
  agencyCode?: string;
};

/**
 * ページネーション
 * @param props
 * @returns
 */
const Pagenation: FC<Props> = ({
  onClick,
  previousOffset,
  nextOffset,
  selectedItem,
  keyword,
  agencyCode,
}) => {
  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 pt-10 pb-20">
      <div className="-mt-px flex w-0 flex-1">
        {previousOffset > -1 && (
          <button
            className="inline-flex items-center border-b-2 border-transparent pl-1 pb-4 text-sm font-medium hover:border-gray-300 hover:text-gray-700"
            onClick={() =>
              onClick(previousOffset, selectedItem, keyword, agencyCode)
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
            前へ
          </button>
        )}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        {nextOffset > -1 && (
          <button
            className="inline-flex items-center border-b-2 border-transparent pl-1 pb-4 text-sm font-medium hover:border-gray-300 hover:text-gray-700"
            onClick={() =>
              onClick(nextOffset, selectedItem, keyword, agencyCode)
            }
          >
            次へ
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        )}
      </div>
    </nav>
  );
};

export default Pagenation;
