import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";

export const getAgencies = async (
  limit: number = 50,
  offset: number = 0,
  searchSelect?: string,
  keyword?: string
) => {
  let path;
  if (searchSelect === "selectName") {
    path = `/agencies?agency_name=${keyword}&limit=${limit}&offset=${offset}`;
  } else if (searchSelect === "selectCode") {
    path = `/agencies?agency_code=${keyword}&limit=${limit}&offset=${offset}`;
  } else {
    path = `/agencies?limit=${limit}&offset=${offset}`;
  }
  const params = {};
  let result: any;
  await API.get(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
