import { Storage } from "aws-amplify";
import { useState } from "react";
import { TermsDocsData } from "../../common/type";
import Alert from "../common/Alert";

type Props = {
  isAlert: boolean;
  setIsAlert: React.Dispatch<React.SetStateAction<boolean>>;
  termsDocs: TermsDocsData[];
  setTermsDocs: React.Dispatch<React.SetStateAction<TermsDocsData[]>>;
  selectedRemoveTermsDoc: TermsDocsData;
};

const RemoveModal = (props: Props) => {
  const [message, setMessage] = useState("");

  const handleRemove = async () => {
    try {
      const { results } = await Storage.list(
        `terms-docs/${props.selectedRemoveTermsDoc.terms_docs_id}.pdf`
      );
      if (results.length === 0) {
        setMessage("ファイルが存在しないため、削除できませんでした。");
        return;
      }
      await Storage.remove(
        `terms-docs/${props.selectedRemoveTermsDoc.terms_docs_id}.pdf`
      );
      props.setTermsDocs(
        props.termsDocs.filter(
          (termsDoc) =>
            termsDoc.terms_docs_id !==
            props.selectedRemoveTermsDoc.terms_docs_id
        )
      );
      setMessage("削除しました。");
    } catch (e) {
      console.error(e);
      setMessage("削除が失敗しました。");
    }
  };

  return (
    <div>
      <Alert
        isAlert={props.isAlert}
        setIsAlert={props.setIsAlert}
        onClick={handleRemove}
        title="条件通知書削除"
        submitText="はい"
        cancelText="いいえ"
        message={message}
        setMessage={setMessage}
      >
        {`${props.selectedRemoveTermsDoc.terms_docs_name}を削除しますか？`}
      </Alert>
    </div>
  );
};

export default RemoveModal;
