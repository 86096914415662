import { Routes, Route } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import Login from "./containers/Login";
import { Authenticator } from "@aws-amplify/ui-react";
import { IsAdminProvider } from "./components/contexts/IsAdminContext";
import { CognitoUserProvider } from "./components/contexts/CognitoUserContext";
import { DBUserProvider } from "./components/contexts/DBUserContext";

function App() {
  return (
    <Authenticator.Provider>
      <DBUserProvider>
        <CognitoUserProvider>
          <IsAdminProvider>
            <Routes>
              <Route path="/*" element={<Login isAdmin={false} />} />
              <Route path="/admin/*" element={<Login isAdmin={true} />} />
            </Routes>
          </IsAdminProvider>
        </CognitoUserProvider>
      </DBUserProvider>
    </Authenticator.Provider>
  );
}

export default App;
