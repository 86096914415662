import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";
import { PaymentData } from "../common/type";

export const putPaymentCode = async (
  paymentCode: string,
  paymentChangeData: PaymentData
) => {
  const path = `/payments/${encodeURIComponent(paymentCode)}`;
  const putData = {
    payment_name: paymentChangeData.name,
    payment_name_kana: paymentChangeData.name_kana,
    postal_code: paymentChangeData.postal_code,
    address: paymentChangeData.address,
    phone_number: paymentChangeData.phone_number,
    financial_facility_name: paymentChangeData.financial_facility_name,
    branch_name: paymentChangeData.branch_name,
    account_type: paymentChangeData.account_type,
    bank_account_number: paymentChangeData.bank_account_number,
    registration_number: paymentChangeData.registration_number,
  };
  const params = { body: putData };
  let result: any;
  await API.put(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
