import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  AdminPages,
  AdminPagesType,
  UserPages,
  UserPagesType,
} from "../routes/Pages";
import { useContext } from "react";
import { IsAdminContext } from "./contexts/IsAdminContext";
import { useLocation } from "react-router-dom";
import { useDBUserContext } from "./contexts/DBUserContext";

/**
 * サイドメニュー
 * @returns
 */
const SideMenu: FC = () => {
  const { isAdmin } = useContext(IsAdminContext);
  const { dbUser } = useDBUserContext();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  let displayPages: UserPagesType | AdminPagesType = UserPages;
  if (isAdmin) {
    displayPages = AdminPages;
  }
  const pages = Object.entries(displayPages).filter(([key, Page]) =>
    isAdmin
      ? Page.IS_DISPLAY === true &&
        Page.PERMISSIONS.includes(dbUser.permissions)
      : Page.IS_DISPLAY === true
  );
  useEffect(() => {
    if (dbUser.user_id) {
      setIsMenuVisible(true);
    }
  }, [dbUser]);

  const location = useLocation();
  const currentPath = location.pathname;
  const lastPath = currentPath.replace(/^\/admin/g, "").replace(/^\//g, "");

  return (
    <div className="fixed top-[4.5rem] left-0 flex flex-col h-screen w-56">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-main px-6">
        <nav className="flex flex-1 flex-col">
          <ul className="-mx-2 mt-2 space-y-1">
            {isMenuVisible &&
              pages.map(([key, Page]) => (
                <li key={key}>
                  <Link
                    to={Page.PATH}
                    className={`bg-main border-b-2 hover:border-gray-300 group flex gap-x-3 my-4 mx-2 text-sm leading-6 font-semibold 
                    ${
                      lastPath === Page.PATH
                        ? "pointer-events-none border-gray-300"
                        : "border-main"
                    }`}
                  >
                    {Page.ICON}
                    <span>{Page.NAME}</span>
                  </Link>
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SideMenu;
