import SearchIcon from "@mui/icons-material/Search";
import MuiButton from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { FC, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { SearchItemsType, SearchSelect } from "../../common/type";
import { jaJP } from "@mui/x-date-pickers/locales";

type Props = {
  onClick: (...args: any[]) => void;
  selectedItem: SearchSelect;
  setSelectedItem: React.Dispatch<React.SetStateAction<SearchSelect>>;
  inputKeyword: string;
  setInputKeyword: React.Dispatch<React.SetStateAction<string>>;
  searchItems: SearchItemsType[];
};

const SearchModule: FC<Props> = ({
  onClick,
  selectedItem,
  setSelectedItem,
  inputKeyword,
  setInputKeyword,
  searchItems,
}) => {
  const [selectSearchItem, setSelectSearchItem] = useState<
    SearchItemsType | undefined
  >(searchItems[0]);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const formatDateToString = (date: any) => {
    if (date === null || !date.isValid()) {
      return "";
    }
    return date.format("YYYY/MM/DD");
  };

  return (
    <div className="flex justify-center flex-auto text-center h-13">
      <div className="flex items-center">
        <FormControl
          sx={selectSearchItem?.method === "date" ? {} : { minWidth: 150 }}
        >
          <Select
            value={selectedItem}
            onChange={(e) => {
              setInputKeyword("");
              setSelectedItem(e.target.value as SearchSelect);
              setSelectSearchItem(
                searchItems.find((item) => item.value === e.target.value)
              );
            }}
            displayEmpty
            className="text-left"
            inputProps={{ "aria-label": "Without label" }}
          >
            {searchItems.map((searchItem, key) => (
              <MenuItem key={key} value={searchItem.value}>
                {searchItem.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectSearchItem?.method === "range" && (
          <>
            <TextField
              id="outlined-basic-start"
              label="数値"
              sx={{ width: "125px" }}
              type={selectSearchItem.type}
              variant="outlined"
              value={inputKeyword.split(":")[0]}
              onChange={(e) => {
                const splitKeyword = inputKeyword.split(":");
                const totalAmountPaidEnd =
                  splitKeyword.length > 1 ? splitKeyword[1] : "";
                setInputKeyword(`${e.target.value}:${totalAmountPaidEnd}`);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  onClick();
                  event.preventDefault();
                }
              }}
            />
            <div className="px-4">{" ～ "}</div>
            <TextField
              id="outlined-basic-end"
              label="数値"
              sx={{ width: "125px" }}
              type={selectSearchItem.type}
              variant="outlined"
              value={
                inputKeyword.split(":").length > 1
                  ? inputKeyword.split(":")[1]
                  : ""
              }
              onChange={(e) => {
                const splitKeyword = inputKeyword.split(":");
                setInputKeyword(`${splitKeyword[0]}:${e.target.value}`);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  onClick();
                  event.preventDefault();
                }
              }}
            />
          </>
        )}
        {selectSearchItem?.method === "date" && (
          <div className="-mt-2">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              dateFormats={{ monthAndYear: "YYYY年MM月" }}
              localeText={
                jaJP.components.MuiLocalizationProvider.defaultProps.localeText
              }
            >
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <DatePicker
                  sx={{ width: "205px" }}
                  label="年月日"
                  value={startDate}
                  format="YYYY年MM月DD日"
                  slotProps={{
                    textField: {
                      onKeyPress: (event) => {
                        if (event.key === "Enter") {
                          onClick();
                          event.preventDefault();
                        }
                      },
                    },
                  }}
                  onChange={(selectedDate) => {
                    if (selectedDate) {
                      setStartDate(selectedDate);
                      setInputKeyword(
                        `${formatDateToString(
                          selectedDate
                        )}:${formatDateToString(endDate)}`
                      );
                    }
                  }}
                />
                <div className="flex items-center justify-center">{" ～ "}</div>
                <DatePicker
                  sx={{ width: "205px" }}
                  label="年月日"
                  value={endDate}
                  format="YYYY年MM月DD日"
                  slotProps={{
                    textField: {
                      onKeyPress: (event) => {
                        if (event.key === "Enter") {
                          onClick();
                          event.preventDefault();
                        }
                      },
                    },
                  }}
                  onChange={(selectedDate) => {
                    if (selectedDate) {
                      setEndDate(selectedDate);
                      setInputKeyword(
                        `${formatDateToString(startDate)}:${formatDateToString(
                          selectedDate
                        )}`
                      );
                    }
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        )}
        {(selectSearchItem?.method === "word" ||
          selectSearchItem === undefined) && (
          <TextField
            id="outlined-basic"
            label="検索ワード"
            variant="outlined"
            value={inputKeyword}
            onChange={(e) => setInputKeyword(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                onClick();
                event.preventDefault();
              }
            }}
          />
        )}
      </div>
      <MuiButton
        color="inherit"
        variant="contained"
        onClick={() => onClick(0, selectedItem, inputKeyword)}
      >
        <SearchIcon color="inherit" />
      </MuiButton>
    </div>
  );
};

export default SearchModule;
