import { FC, useContext } from "react";
import { AgencyData } from "../../common/type";
import { UserPlusIcon } from "@heroicons/react/24/outline";
import { IsAdminContext } from "../contexts/IsAdminContext";
import * as luxon from "luxon";
import { useDBUserContext } from "../contexts/DBUserContext";

type Props = {
  agencyDataList: AgencyData[];
  isModalOpen: boolean;
  modalOpen: (data: AgencyData) => void;
  userModalOpen: (code: AgencyData) => void;
  setIsNewAgency: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * 代理店テーブル(管理者)
 * @param props
 * @returns
 */
const AgencyTable: FC<Props> = ({
  agencyDataList,
  isModalOpen,
  modalOpen,
  userModalOpen,
  setIsNewAgency,
}) => {
  const { isAdmin } = useContext(IsAdminContext);
  const { dbUser } = useDBUserContext();

  const detailModalOpen = (data: any) => {
    setIsNewAgency(false);
    modalOpen(data);
  };

  return (
    <div className="flow-root">
      <div className="-my-2 -mx-8">
        <div className="inline-block min-w-full py-2 align-middle px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="text-gray-500 text-xs font-semibold">
                <th scope="col" className="w-[15%] px-3 py-3.5 text-left">
                  登録日
                </th>
                <th scope="col" className="w-[35%] px-3 py-3.5 text-left">
                  代理店名
                </th>
                <th scope="col" className="w-[15%] px-3 py-3.5 text-left">
                  代理店コード
                </th>
                <th scope="col" className="w-[15%] px-3 py-3.5 text-left">
                  登録番号
                </th>
                <th scope="col" className="w-[15%] px-3 py-3.5 text-left">
                  取引停止日
                </th>
                <th scope="col" className="w-[5%] px-3 py-3.5 text-left"></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {agencyDataList.map((data) => {
                return (
                  <tr
                    key={data.code}
                    onClick={
                      isAdmin && dbUser.permissions !== 2
                        ? () => detailModalOpen(data)
                        : undefined
                    }
                    className={`${
                      isAdmin &&
                      dbUser.permissions !== 2 &&
                      !isModalOpen &&
                      "cursor-pointer hover:bg-gray-100"
                    }`}
                  >
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {luxon.DateTime.fromSQL(data.createdAt).toFormat(
                        "yyyy/MM/dd"
                      )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {data.name?.length > 24
                        ? `${data.name.slice(0, 24)}...`
                        : data.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {data.code}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {data.registration_number}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-left">
                      {data.trading_stop_date
                        ? luxon.DateTime.fromSQL(
                            data.trading_stop_date
                          ).toFormat("yyyy/MM/dd")
                        : ""}
                    </td>
                    {dbUser.user_id && dbUser.permissions !== 2 && (
                      <td
                        className="whitespace-nowrap px-3 py-1 hover:bg-gray-200"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          userModalOpen(data);
                        }}
                      >
                        {isAdmin && data.code && (
                          <div className="flex items-center justify-center">
                            <UserPlusIcon
                              title="ユーザー追加"
                              className="w-5 h-5 cursor-pointer"
                            />
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AgencyTable;
