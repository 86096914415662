import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";
import { SearchSelect } from "../common/type";

export const getReleaseFiles = async (
  limit: number = 50,
  offset: number = 0,
  searchType?: SearchSelect,
  keyword?: string,
  agencyCode?: string | null
) => {
  let path;
  if (keyword) {
    if (searchType === "selectName") {
      path = `/release_files?agency_name=${keyword}&limit=${limit}&offset=${offset}&is_search=true`;
    } else {
      path = `/release_files?agency_code=${keyword}&limit=${limit}&offset=${offset}&is_search=true`;
    }
  } else if (agencyCode) {
    path = `/release_files?limit=${limit}&offset=${offset}&agency_code=${agencyCode}&is_search=false`;
  } else {
    path = `/release_files?limit=${limit}&offset=${offset}&is_search=false`;
  }
  let result: any;
  const params = {};
  await API.get(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
