import { Loader } from "@aws-amplify/ui-react";
import { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>; // Event bubbling
  disabled?: boolean;
  isLoading?: boolean;
  buttonType?: "accent" | "delete" | "normal";
};

/**
 * 共通ボタン
 * @param props
 * @returns
 */
const Button: FC<Props> = ({
  children,
  onClick,
  disabled = false,
  isLoading = false,
  buttonType = "accent",
}) => {
  return (
    <button
      className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
      ${buttonType === "accent" && "bg-accent hover:bg-accentHover text-white"} 
      ${buttonType === "delete" && "bg-black hover:bg-gray-800 text-white"} 
      ${
        buttonType === "normal" &&
        "bg-white hover:bg-gray-100 border border-gray-500"
      }
      ${disabled && "opacity-50 bg-gray-500 hover:bg-gray-500"}
      `}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? <Loader /> : children}
    </button>
  );
};

export default Button;
