//https://ui.docs.amplify.aws/react/connected-components/authenticator/customization

import {
  Alert,
  Button,
  Heading,
  Image,
  Text,
  View,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";
import { GRANDATA_LOGO } from "../common/constants";
import { memo } from "react";

type MainHeaderType = {
  submitElement: JSX.Element | null;
};
type SignInHeaderType = {
  isAdmin: boolean;
};

/**
 * ヘッダー
 * @param props
 * @returns
 */
const MainHeader = memo(({ submitElement }: MainHeaderType) => {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Image alt="grandata" src={GRANDATA_LOGO} height="30%" width="30%" />
      <Text
        variation="primary"
        as="p"
        lineHeight="1.5em"
        fontWeight={600}
        fontSize="3rem"
        fontStyle="normal"
        textDecoration="none"
      >
        PAC3
      </Text>
      <Text
        as="p"
        color={tokens.colors.neutral[60]}
        lineHeight="1.5em"
        fontWeight={400}
        fontSize="1rem"
        fontStyle="normal"
        textDecoration="none"
      >
        <span className="font-extrabold">P</span>ortal site{" "}
        <span className="font-extrabold">A</span>gency{" "}
        <span className="font-extrabold">C</span>ontract{" "}
        <span className="font-extrabold">C</span>ustomer{" "}
        <span className="font-extrabold">C</span>ommission
      </Text>
      {submitElement && (
        <Alert
          textAlign="left"
          variation="success"
          isDismissible={false}
          hasIcon={true}
          heading=""
          margin={`${tokens.space.large} 0 0 0`}
        >
          {submitElement}
        </Alert>
      )}
    </View>
  );
});

/**
 * フッター
 * @returns
 */
const MainFooter = memo(() => {
  const { tokens } = useTheme();

  return (
    <View textAlign="center" padding={tokens.space.large}>
      <Text color={tokens.colors.neutral[60]}>
        Copyright Grandata 2023 All rights reserved
      </Text>
    </View>
  );
});

/**
 * サインインヘッダー
 */
const SignInHeader = memo(({ isAdmin }: SignInHeaderType) => {
  const { tokens } = useTheme();

  return (
    <Heading
      textAlign="center"
      color={tokens.colors.neutral[60]}
      padding={`${tokens.space.large} 0 0 0`}
      level={5}
    >
      {isAdmin && "管理者"}
      ログイン
    </Heading>
  );
});

/**
 * サインインフッター
 */
const SignInFooter = memo(() => {
  const { toResetPassword } = useAuthenticator();

  return (
    <View textAlign="center" padding="1rem">
      <Button
        fontWeight="normal"
        onClick={toResetPassword}
        size="small"
        variation="link"
      >
        パスワードを忘れた場合
      </Button>
    </View>
  );
});

/**
 * パスワード変更ヘッダー
 */
const ResetPasswordHeader = memo(() => {
  const { tokens } = useTheme();
  return (
    <Heading
      textAlign="center"
      color={tokens.colors.neutral[60]}
      padding={`0 0 ${tokens.space.xl} 0`}
      level={5}
    >
      検証コードと新しいパスワードの入力
    </Heading>
  );
});

/**
 * 新パスワード入力ヘッダー
 */
const ConfirmResetPasswordHeader = memo(() => {
  const { tokens } = useTheme();
  return (
    <Heading
      textAlign="center"
      color={tokens.colors.neutral[60]}
      padding={`0 0 ${tokens.space.xl} 0`}
      level={5}
    >
      検証コードと新しいパスワードの入力
    </Heading>
  );
});

/**
 * ログイン画面レイアウト
 */
export const AuthenticatorComponents = {
  Header: MainHeader,
  Footer: MainFooter,

  SignIn: {
    Header: SignInHeader,
    Footer: SignInFooter,
  },

  ResetPassword: {
    Header: ResetPasswordHeader,
  },
  ConfirmResetPassword: {
    Header: ConfirmResetPasswordHeader,
  },
};
