import { API } from "aws-amplify";
import { API_NAME } from "../common/constants";
import { PostNewsData } from "../common/type";

/**
 * お知らせ登録APIコール
 * @param data
 * @returns
 */
export const postNews = async (data: PostNewsData) => {
  let postData;
  if (data.agency_code) {
    postData = data;
  } else {
    postData = {
      subject: data.subject,
      content: data.content,
      post_user_id: data.post_user_id,
    };
  }
  const path = "/news";
  const params = {
    body: postData,
  };
  let result: any;
  await API.post(API_NAME, path, params)
    .then((response) => {
      result = response;
    })
    .catch((e) => {
      console.error(e);
      result = e.response.data;
    });
  return result;
};
